import { createSvgIcon } from "@mui/material"


const CloseIcon = createSvgIcon(
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 7.63086L7.5 22.4M7.5 7.63086L22.5 22.4" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    , 'CloseIcon'
)

export default CloseIcon