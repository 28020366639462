import { Chip, styled } from "@mui/material";


export const StyledChip = styled(Chip)(({ theme }) => ({
    width: 55,
    height: 25,
    color: '#FFFFFF',
    backgroundColor: '#FF0000',
    fontSize: 14,
    fontWeight: 500,
}))