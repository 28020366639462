import { Button, Paper, darken, styled } from "@mui/material";
import React from "react";

export const StyledPaperDetail = styled(Paper)(({ theme }) => ({
    width: '666px',
    borderRadius: '32px',
    boxShadow: '0px 0px 48px 0px #00000026'
}))

export const StyledProceedButton = styled(Button)(({ theme }) => ({
    padding: "4px 1px",
    height: '50px',
    display: 'block',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 25,
    '&:hover': {
        backgroundColor: darken('#FACB57', 0.1),
    },
}))