import { useEffect, useState } from "react"
import { Box, Paper, Stack } from "@mui/material"
import { StyledPrimaryButton, StyledTitleText, StyledValueText } from "./styles"
import CheckIcon from "../../icons/CheckIcon"
import { SubscriptionPlan } from "../../../constants"
import ViewPlanDialog from "../../dialogs/ViewPlanDialog"
import UnsubscribeConfirmDialog from "../../dialogs/UnsubscribeConfirmDialog"
import UnsubscribeNotifyDialog from "../../dialogs/UnsubscribeNotifyDialog"
import PremiumPlanDialog from "../../dialogs/PremiumPlanDialog"
import { Credit, Plan, Subscription } from "../../../types"
import { getSubsription } from "../../../api/subscription"

interface Props {
    subscription: Subscription
    setSubsription: (subscription: Subscription) => void
    upgradeSubscriptionPlan: () => void
}

const MyPlanCard = (props: Props) => {
    const { subscription, setSubsription, upgradeSubscriptionPlan } = props

    const [openViewPlanDialog, setOpenViewPlanDialog] = useState(false)
    const [openUnsubscribeConfirmDialog, setOpenUnsubscribeConfirmDialog] = useState(false)
    const [openUnsubscribeNotifyDialog, setOpenUnsubscribeNotifyDialog] = useState(false)
    const [openPremiumPlanDialog, setOpenPremiumPlanDialog] = useState(false)

    const getPlanName = (plan: string): string => {
        if (plan === 'free') {
            return 'Free'
        } else if (plan === 'basic') {
            return 'Basic'
        } else if (plan === 'premium') {
            return 'Premium'
        }
        return plan
    }

    const isPremiumPlan = (plan: Plan): boolean => {
        return SubscriptionPlan.PREMIUM === plan.name
    }

    const isBasicPlan = (plan: Plan): boolean => {
        return SubscriptionPlan.BASIC === plan.name
    }

    const handleViewPlanDialogClose = (success: boolean) => {
        setOpenViewPlanDialog(false)
        console.log(success)
        if (success) {
            if (isPremiumPlan(subscription.plan)) {
                // TODO: unsubscribe
                setOpenUnsubscribeConfirmDialog(true)
            } else if (isBasicPlan(subscription.plan)) {
                // TODO: upgrade to premium plan
                console.log('basic plan')
                upgradeSubscriptionPlan()
            } else {
                // TODO: subscribe basic plan
            }
        }
    }

    const handleUnsubscribeConfirmClose = (success: boolean) => {
        setOpenUnsubscribeConfirmDialog(false)
        if (success) {
            getSubsription()
                .then((res) => {
                    setSubsription(res.data.data)
                })
            setOpenUnsubscribeNotifyDialog(true)
        }
    }

    const hanldeUnsubscribeNotifyClose = (success: boolean) => {
        setOpenUnsubscribeNotifyDialog(false)
        if (success) {
            setOpenPremiumPlanDialog(true)
        }
    }

    const hanldePremiumPlanDialogClose = (success: boolean) => {
        setOpenPremiumPlanDialog(false)
        console.log(success)
        if(success) {
            upgradeSubscriptionPlan()
        }
    }

    return (
        <>
            <Paper elevation={0} sx={{ height: 350, borderRadius: '32px', backgroundImage: `url(/img/my-plan-bg.png)` }} variant="elevation">
                <Box p={5} sx={{ height: '270px' }}>
                    <Stack height='100%' direction={'column'} justifyContent={'space-between'} sx={{ color: '#ffffff' }}>
                        <Box>
                            <StyledTitleText>My Plan</StyledTitleText>
                            <Stack direction={'row'} spacing={1} pt={1} sx={{ alignItems: 'center' }}>
                                <CheckIcon sx={{ path: { stroke: '#fff' } }} />
                                <StyledValueText>{getPlanName(subscription.plan.name)}</StyledValueText>
                            </Stack>
                        </Box>
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <StyledPrimaryButton
                                variant="contained"
                                disableElevation
                                onClick={() => setOpenViewPlanDialog(true)}>
                                View Plan
                            </StyledPrimaryButton>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
            <ViewPlanDialog plan={subscription.plan} open={openViewPlanDialog} onClose={handleViewPlanDialogClose} />
            <UnsubscribeConfirmDialog subscriptionId={subscription.id} open={openUnsubscribeConfirmDialog} onClose={handleUnsubscribeConfirmClose} />
            <UnsubscribeNotifyDialog open={openUnsubscribeNotifyDialog} onClose={hanldeUnsubscribeNotifyClose} />
            <PremiumPlanDialog open={openPremiumPlanDialog} onClose={hanldePremiumPlanDialogClose} />
        </>
    )
}

export default MyPlanCard