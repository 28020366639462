import React from "react";
import { StyledSignInGoogleButton } from "./styles";
import { Box, Stack, Typography } from "@mui/material";
import GoogleIcon from "../../icons/GoogleIcon";

const GoogleSignInButton = () => {
    return (
        <Box>
            <StyledSignInGoogleButton variant="outlined" disableElevation fullWidth>
                <Stack direction={'row'} justifyContent={'center'} spacing={2}>
                    <GoogleIcon />
                    <Typography fontSize={18} fontWeight={500}>Continue with Google</Typography>
                </Stack>
            </StyledSignInGoogleButton>
        </Box>
    )
}

export default GoogleSignInButton