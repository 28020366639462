import { Badge, Chip, MenuItem, styled } from "@mui/material"
import { Link } from "react-router-dom"

export const AvatarBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#ff0000',
        color: '#44b700',
        boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    }
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:hover': {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
        svg: {
            path: {
                stroke: theme.palette.background.paper
            }
        }
    },
    '&.Mui-disabled': {
        opacity: 1,
        pointerEvents: 'auto',
        '.MuiListItemIcon-root': {
            opacity: 0.4,
        },
        '.MuiListItemText-root' : {
            opacity: 0.4,
        }
    }
}))

export const StyledMenuLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#333333'
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
    width: 55,
    height: 25,
    color: '#FFFFFF',
    backgroundColor: '#FF0000',
    fontSize: 14,
    fontWeight: 500,
}))