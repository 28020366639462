import { useState } from "react"
import DownloadIcon from "../../icons/DownloadIcon"
import { StyledLoadingButton } from "./styles"


interface Props {
    onClick: () => Promise<void>
}

const DownloadAllButton = (props: Props) => {
    const { onClick } = props

    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        setLoading(true)
            onClick()
                .finally(() => setLoading(false))
    }

    return (
        <StyledLoadingButton
            loading={loading}
            onClick={handleClick}
            loadingPosition="start"
            variant="contained"
            startIcon={<DownloadIcon />}>
            Download All
        </StyledLoadingButton>
    )
}

export default DownloadAllButton