import React from "react";
import { AccordionSummary, Button, Paper, Tab, Tabs, ToggleButton, ToggleButtonGroup, darken, styled } from "@mui/material";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#635ee7',
    },
}))

interface StyledTapProps {
    label: string
}

export const StyledTab = styled((props: StyledTapProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
        color: '#000',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}))

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '&.MuiToggleButtonGroup-root': {
        display: 'flex',
        padding: theme.spacing(0.5),
        border: '1px solid #FACB57',
        borderRadius: '32px',
        backgroundColor: '#FFFFFF',
    },
}))

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: '32px',
    // '&.MuiToggleButtonGroup-grouped:not(:last-of-type)'
    '&.MuiToggleButton-root': {
        width: '219px',
        border: 'none',
        fontSize: '18px',
        color: '#000',
    },
    '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        borderRadius: '32px',
        marginRight: theme.spacing(0.25),
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderRadius: '32px',
        marginLeft: theme.spacing(0.25),
    },
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    }
}))

export const StyledSmallToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '&.MuiToggleButtonGroup-root': {
        display: 'flex',
        padding: '2px',
        border: '1px solid #FACB57',
        borderRadius: '25px',
        backgroundColor: '#FFFFFF',
    },
}))

export const StyledSmallToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: '25px',
    '&.MuiToggleButton-root': {
        width: '92px',
        border: 'none',
        fontSize: '14px',
        color: '#000',
        lineHeight: '21px',
        padding: '3px',
    },
    '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        borderRadius: '25px',
        marginRight: theme.spacing(0.1),
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderRadius: '25px',
        borderBottomLeftRadius: '25px',
        marginLeft: theme.spacing(0.1),
    },
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    }
}))

export const StyledPaperDetail = styled(Paper)(({ theme }) => ({
    width: '666px',
    borderRadius: '32px',
    boxShadow: '0px 0px 48px 0px #00000026'
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    '&.MuiAccordion-root': {
        boxShadow: 'none',
    }
}))

