interface Props {
    code: string
}

const CountryFlagImg = (props: Props) => {
    const {code} = props

    return (
        <img
            loading="lazy"
            width="20"
            height="auto"
            srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
            alt=""
        />
    )
}

export default CountryFlagImg