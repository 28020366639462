import React, { useEffect, useState } from "react";
import { Alert, Backdrop, Box, Snackbar, Stack } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { userPatchPassword, userPostResetPassword } from "../../api/users";
import UserLoginLayout from "../../components/elements/UserLoginLayout";
import PasswordTextInput from "../../components/elements/PasswordTextInput";
import UserSubmitButton from "../../components/elements/UserSubmitButton";
import ContentHeader from "../../components/elements/ContentHeader";
import UserTextInputWithLabel from "../../components/elements/UserTextInputWithLabel";
import { Cookies } from "react-cookie";

const cookies = new Cookies("secure_token", { path: '/' })

const RegetPasswordPage = () => {
    const navigate = useNavigate()
    const [seachParam] = useSearchParams()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [otpCode, setOtpCode] = useState('')

    const [passwordError, setPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')
    const [otpCodeError, setTopCodeError] = useState('')

    const [openSuccessStatus, setOpenSuccessStatus] = React.useState(false);

    useEffect(() => {
        const email = seachParam.get('email');
        if (email !== null) {
            setUsername(email)
        }
    }, [seachParam])

    const handleClose = () => {
        cookies.remove('secure_token')
        navigate("/login")
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (password !== '' && confirmPassword !== '' && otpCode !== '') {
            userPostResetPassword(username, password, otpCode)
                .then((_res) => {
                    setOpenSuccessStatus(true);
                })
                .catch((err) => {
                    console.error(err)
                })
        }
        if (password === '') {
            setPasswordError('This field is required.')
        }
        if (confirmPassword === '') {
            setConfirmPasswordError('This field is required.')
        }
        if (otpCode === '') {
            setTopCodeError('This field is required.')
        }
        if (password !== '' && confirmPassword !== '') {
            if (password !== confirmPassword) {
                setConfirmPasswordError('Password does not match.')
            }
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openSuccessStatus}
            >
                <Snackbar
                    open={openSuccessStatus}
                    autoHideDuration={1800}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{
                        width: "95%",
                        top: "80px !important",
                    }}
                >
                    <Alert
                        severity="info" variant="filled"
                        iconMapping={{
                            info: <CheckCircleOutlineIcon fontSize="inherit" />,
                        }}
                        sx={{ width: "100%", borderRadius: "40.5px" }}
                    >
                        Password has been successfully reset!
                    </Alert>
                </Snackbar>
            </Backdrop>
            <UserLoginLayout>
                <Box component={'form'} onSubmit={handleSubmit} height={'100%'}>
                    <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
                        <Box>
                            <ContentHeader >Reset Password</ContentHeader>
                            <Box py={5}>
                                <Stack spacing={2}>
                                    <Box>
                                        <PasswordTextInput
                                            label="New Password"
                                            id="origin-password-input"
                                            name="origin-password"
                                            placeholder="Password"
                                            error={passwordError}
                                            clearError={() => setPasswordError('')}
                                            value={password}
                                            setValue={setPassword}
                                        />
                                    </Box>
                                    <Box>
                                        <PasswordTextInput
                                            label="Confirmed Password"
                                            id="confirm-password-input"
                                            name="confirm-password"
                                            placeholder="Password"
                                            error={confirmPasswordError}
                                            clearError={() => setConfirmPasswordError('')}
                                            value={confirmPassword}
                                            setValue={setConfirmPassword}
                                        />
                                    </Box>
                                    <Box>
                                        <UserTextInputWithLabel
                                            label="OTP Code"
                                            id="one-time-password"
                                            name="otp"
                                            placeholder="OTP Code"
                                            error={otpCodeError}
                                            clearError={() => setTopCodeError('')}
                                            value={otpCode}
                                            setValue={setOtpCode}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                        <Box>
                            <UserSubmitButton>Reset</UserSubmitButton>
                        </Box>
                    </Stack>
                </Box>
            </UserLoginLayout>
        </>
    )
}

export default RegetPasswordPage