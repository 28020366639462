import { Paper, styled } from "@mui/material";
import React from "react";

export const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 32,
    borderColor: '#F1F1F1',
    padding: theme.spacing(5),
}))

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  