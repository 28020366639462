import { Menu, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { StyledButton, StyledInputBase, StyledOutlinedInput, StyledPaper, StyledSelect } from "./styles";
import SelectIcon from "../../icons/SelectIcon";
import { HtmlHTMLAttributes, MouseEventHandler, ReactEventHandler, useState } from "react";


interface Props {
    value: number
    setValue: (value: number) => void
}

const SearchLimitSelection = (props: Props) => {
    const {value, setValue} = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl)

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleValueClick = (e: any) => {
       console.log(e.target.innerText)
       setValue(e.target.innerText)
       setAnchorEl(null)
    }

    return (
        <>
            <StyledPaper elevation={0}>
                <StyledInputBase value={value}/>
                <StyledButton aria-label="directions"
                    onClick={handleClick}>
                    <SelectIcon />
                </StyledButton>
            </StyledPaper>
            <Menu
                id="search-limit-selection"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: "132px"
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuItem value={10} onClick={handleValueClick}>10</MenuItem>
                <MenuItem value={20} onClick={handleValueClick}>20</MenuItem>
                <MenuItem value={30} onClick={handleValueClick}>30</MenuItem>
            </Menu>
        </>
    )
}

export default SearchLimitSelection