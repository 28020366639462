import { createSvgIcon } from "@mui/material";


const ErrorAlertIcon = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0001 1.66699C14.6026 1.66699 18.3334 5.39783 18.3334 10.0003C18.3351 12.1882 17.4764 14.289 15.9425 15.8492C14.4086 17.4094 12.3227 18.3037 10.1351 18.3392C7.94749 18.3746 5.8337 17.5483 4.25008 16.0387C2.66646 14.529 1.74007 12.4571 1.67091 10.2703L1.66675 10.0003L1.67008 9.76699C1.79341 5.27283 5.47508 1.66699 10.0001 1.66699ZM10.0084 12.5003L9.90258 12.5062C9.70004 12.5303 9.51337 12.6278 9.37793 12.7803C9.24248 12.9328 9.16768 13.1297 9.16768 13.3337C9.16768 13.5376 9.24248 13.7345 9.37793 13.887C9.51337 14.0395 9.70004 14.1371 9.90258 14.1612L10.0001 14.167L10.1059 14.1612C10.3085 14.1371 10.4951 14.0395 10.6306 13.887C10.766 13.7345 10.8408 13.5376 10.8408 13.3337C10.8408 13.1297 10.766 12.9328 10.6306 12.7803C10.4951 12.6278 10.3085 12.5303 10.1059 12.5062L10.0084 12.5003ZM10.0001 5.83366C9.79597 5.83369 9.59897 5.90862 9.44644 6.04425C9.29391 6.17989 9.19646 6.36678 9.17258 6.56949L9.16675 6.66699V10.0003L9.17258 10.0978C9.19667 10.3004 9.29421 10.487 9.44672 10.6225C9.59923 10.7579 9.79611 10.8327 10.0001 10.8327C10.2041 10.8327 10.4009 10.7579 10.5534 10.6225C10.706 10.487 10.8035 10.3004 10.8276 10.0978L10.8334 10.0003V6.66699L10.8276 6.56949C10.8037 6.36678 10.7063 6.17989 10.5537 6.04425C10.4012 5.90862 10.2042 5.83369 10.0001 5.83366Z" fill="#FF0000" />
    </svg>
    ,
    'ErrorAlertIcon'
)

export default ErrorAlertIcon