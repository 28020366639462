import { ChangeEvent } from "react";
import { StyledInputBase, StyledInputLabel, StyledOutlinedInput } from "../UserTextInputWithLabel/styles";
import FormValidateError from "../FormValidateError";

interface Props {
    label: string
    id: string
    name: string
    placeholder: string
    rows: number
    error: string
    clearError: () => void
    value: string
    setValue: (value: string) => void
}

const TextAreaInputWithLabel = (props: Props) => {
    const { label, id, name, placeholder, rows, error, clearError, value, setValue } = props

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        clearError()
        setValue(e.target.value)
    }

    return (
        <StyledOutlinedInput variant="standard" fullWidth error={error !== ''}>
            <StyledInputLabel shrink >{label}</StyledInputLabel>
            <StyledInputBase
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                multiline rows={rows}
                sx={{
                    '& .MuiInputBase-input': {
                        padding: '0 8px',
                    }
                }}
            />
            <FormValidateError error={error} />
        </StyledOutlinedInput>
    )
}

export default TextAreaInputWithLabel