import { Table, TableBody, TableHead } from "@mui/material"
import { ResultPage, SanctionAlert } from "../../../types"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "../_common/styles"

interface Props {
    result: ResultPage<SanctionAlert> | undefined
}

const SanctionAlertResultContent = (props: Props) => {
    const { result } = props

    console.log(result)

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Last Processing Date</StyledTableCell>
                        <StyledTableCell>Listed On</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {(result !== undefined) && (result?.results.map((item, index) => (
                        <StyledTableRow>
                            <StyledTableCell>{index + result?.firstElement}</StyledTableCell>
                            <StyledTableCell>{item.type}</StyledTableCell>
                            <StyledTableCell>{item.name}</StyledTableCell>
                            <StyledTableCell>{item.lastProcessingDate}</StyledTableCell>
                            <StyledTableCell>{item.listedOn}</StyledTableCell>
                        </StyledTableRow>
                    )))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default SanctionAlertResultContent