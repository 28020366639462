import React, { useState } from "react";
import { StyledInputBase, StyledInputLabel, StyledOutlinedInput } from "../UserTextInputWithLabel/styles";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormValidateError from "../FormValidateError";

interface Props {
    label: string,
    id?: string,
    name?: string,
    placeholder: string
    error: string,
    clearError: () => void,
    value: string,
    setValue: (value: string) => void
}

const PasswordTextInput = (props: Props) => {
    const { label, id, name, placeholder, error, clearError, value, setValue } = props
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show)
    }

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearError()
        setValue(e.target.value)
    }

    return (
        <StyledOutlinedInput variant="standard" fullWidth error={error !== ''}>
            <StyledInputLabel shrink htmlFor={id}>{label}</StyledInputLabel>
            <StyledInputBase
                id={id}
                name={name}
                placeholder={placeholder}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={handleValueChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end" sx={{ color: '#BDBDBD' }}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormValidateError error={error} />
        </StyledOutlinedInput>
    )
}

export default PasswordTextInput