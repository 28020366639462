import React, { ChangeEvent, useState } from "react";
import { StyledButton, StyledInputBase, StyledOutlinedInput, StyledPaper } from "./styles";
import { applyPromoCode } from "../../../api/subscription";

interface Props {
    placeholder: string
    setDiscount: (value: number) => void
}

const PromoCodeTextInput = (props: Props) => {
    const { placeholder, setDiscount } = props


    const [promoCode, setPromoCode] = useState('FREE20')
    const [error, setError] = useState(false)

    const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError(false)
        setPromoCode(e.target.value)
    }

    const handleApplyPromoCode = () => {
        setError(false)
        applyPromoCode(promoCode)
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data.data
                    //console.log(data)
                    if (data.result) {
                        setDiscount(data.discount)
                    }
                    else {
                        setError(true)
                    }
                }
                console.log(res.data)
            })
            .catch((err) => {
                console.error(err)
                setError(true)
            })
    }

    return (
        <StyledOutlinedInput variant="standard" error={error} >
            <StyledInputBase
                placeholder={placeholder}
                value={promoCode}
                onChange={handlePromoCodeChange} />
            <StyledButton aria-label="directions" onClick={handleApplyPromoCode}>Apply</StyledButton>
        </StyledOutlinedInput>
    )
}

export default PromoCodeTextInput