import LoadingButton from "@mui/lab/LoadingButton";
import { darken, styled } from "@mui/material";

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    display: 'flex',
    padding: '4px 1px',
    width: '156px',
    height: '50px',
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 25,
    color: '#fff',
    backgroundColor: '#0C4992',
    //border: '1px solid #D9D9D9',
    '&:hover': {
        backgroundColor: darken('#0C4992', 0.2),
        textDecoration: 'underline',
    },
    '& .MuiLoadingButton-loadingIndicator': {
        left: '28px'
    }
}))