import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material"
import WaitingProgress from "../../elements/WaitingProgress"
import ApiKeyRefreshIcon from "../../icons/ApiKeyRefreshIcon"
import { StyledNoButton, StyledYesButton } from "../GenerateKeyDialog/styled"
import { StyledOkeyButton } from "./styles"
import { useState } from "react"
import CongratsIcon from "../../icons/CongratsIcon"

interface Props {
    open: boolean
    onClose: (success: boolean) => void
}

const RedeemSuccessDialog = (props: Props) => {
    const { open, onClose } = props

    const [waiting, setWaiting] = useState(false)

    const handleOkeyButtonClick = () => {
        onClose(true)
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '4336px',
                    maxWidth: '436px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5} >
                <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
                <DialogContent sx={{ m: 0, p: 0 }}>
                    <Stack spacing={1} >
                        <Box pb={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CongratsIcon sx={{ height: 51, width: 51 }} />
                        </Box>
                        <Stack alignItems={'center'}>
                            <Typography fontSize={18} fontWeight={700}>
                                Redeemed Successfully!
                            </Typography>
                            <Typography fontSize={18} fontWeight={500}>
                                Free 100 tokens for you!
                            </Typography>
                        </Stack>

                        <Box py={1}>
                            <Stack spacing={2}>
                            </Stack>
                        </Box>
                        <Stack direction={'row'} spacing={1}>
                            <StyledOkeyButton variant="contained" color="secondary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleOkeyButtonClick}
                            >
                                Okey
                            </StyledOkeyButton>
                        </Stack>

                    </Stack>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default RedeemSuccessDialog