import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { StyledPaperDetail, StyledToggleButton, StyledToggleButtonGroup } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import PlusIcon from "../../components/icons/PlusIcon";
import ExplandMoreIcon from "../../components/icons/ExplandMoreIcon";
import OrderSummaryDialog from "../../components/dialogs/OrderSummaryDialog";
import ContentWrapper from "../../components/elements/ContentWrapper";
import SignUpDialog from "../../components/dialogs/SignUpDialog";
import VerifyCodeDialog from "../../components/dialogs/VerifyCodeDialog";
import SetPasswordDialog from "../../components/dialogs/SetPasswordDialog";
import SetPasswordSuccessDialog from "../../components/dialogs/SetPasswordSuccessDialog";
import BasicPlanContent from "../../components/contents/BasicPlanContent";
import PremiumPlanContent from "../../components/contents/PremiumPlanContent";
import MonthlyYearlySwitch from "../../components/elements/MonthlyYearlySwitch";
import JoinNowButton from "../../components/elements/JoinNowButton";
import TermAndConditionLink from "../../components/elements/TermAndConditionLink";
import UpgradeYourAccountCard from "../../components/cards/UpgradeYourAccountCard";
import MyPlanCard from "../../components/cards/MyPlanCard";
import { SubscriptionPlan } from "../../constants";
import { Plan, Subscription, SubscriptionOrder } from "../../types";
import { getSubsriptionPlan, postSubscription } from "../../api/subscription";
import { cleanToken } from "../../api/oauth2";
import { Cookies } from "react-cookie";
import WaitingProgress from "../../components/elements/WaitingProgress";
import SubscriptionCreateDialog from "../../components/dialogs/SubscriptionCreateDialog";

interface PlanDetailPanelProps {
    children?: React.ReactNode;
    name: string;
    value: string;
}

const PlanDetailPanel = (props: PlanDetailPanelProps) => {
    const { children, name, value, ...other } = props
    return (
        <div
            role="tabPanel"
            hidden={value !== name}
            id={`tabpanel-${name}`}
            {...other}
        >
            {value === name && (
                <Box>
                    {children}
                </Box>
            )
            }
        </div>
    )
}

const cookies = new Cookies("secure_token", { path: '/' })

const PricingPage = () => {
    const basicPlanPrice = 999
    const premiumPlanMountlyPrice = 200
    const premiumPlanYearlyPrice = 2000

    const navigate = useNavigate()

    const [authenticated, setAuthenticated] = useState(false)

    const [basicPlan, setBasicPlan] = useState<Plan>({ name: 'basic' } as Plan)
    const [premiumPlan, setPremiumPlan] = useState<Plan>({ name: 'premium' } as Plan)

    const [selectedPlan, setSelectedPlan] = useState<Plan>({ name: 'basic' } as Plan)
    const [price, setPrice] = useState(0)
    const [premiumPlanPrice, setPremiummPlanPrice] = useState(premiumPlanMountlyPrice)
    const [premiumType, setPremiumType] = useState('monthly')
    const [discount, setDiscount] = useState(0)

    const [waiting, setWaiting] = useState(false)

    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [sessionId, setSessionId] = useState('');

    const [summaryDialogOpen, setSummaryDialogOpen] = useState(false)
    const [signUpDialogOpen, setSignUpDialogOpen] = useState(false)
    const [verifyCodeDialogOpen, setVerifyCodeDialogOpen] = useState(false)
    const [setPasswordDialogOpen, setSetPasswordDialogOpen] = useState(false)
    const [setPasswordSuccessDialogOpen, setSetPasswordSuccessDialogOpen] = useState(false)
    const [subscriptionCreateOpen, setSubscriptionCreateOpen] = useState(false)

    const [appliedDiscount, setAppliedDiscount] = useState(false)

    const [subscriptionOrder, setSubscriptionOrder] = useState<SubscriptionOrder>({ uid: userId, plan: basicPlan, price: price, discount: 0, total: price })

    const [subscriptionId, setSubscriptionId] = useState('')

    useEffect(() => {
        getSubsriptionPlan()
            .then((res) => {
                console.log('subscription plans')
                const data = res.data.data
                const basic = getSubscriptionPlan(data, SubscriptionPlan.BASIC)
                const premium = getSubscriptionPlan(data, SubscriptionPlan.PREMIUM)

                setBasicPlan(basic)
                setPremiumPlan(premium)
                // initial plan
                setSelectedPlan(basic)
                setSubscriptionOrder({
                    uid: userId,
                    plan: basic,
                    planId: basic?.id,
                    price: basic.price,
                    discount: discount,
                    total: basic.price - discount
                })
            })
    }, [])

    useEffect(() => {
        setAuthenticated(cookies.get("secure_token") !== undefined)
    }, [])

    useEffect(() => {
        if (selectedPlan?.name === SubscriptionPlan.BASIC) {
            setPrice(basicPlanPrice)
            setDiscount(0)
        } else if (selectedPlan?.name === SubscriptionPlan.PREMIUM) {
            setPrice(basicPlanPrice + premiumPlanPrice)
            console.log(premiumPlanPrice)
        }
    }, [selectedPlan, premiumPlanPrice])

    useEffect(() => {
        setSubscriptionOrder({
            uid: userId,
            plan: selectedPlan,
            planId: selectedPlan?.id,
            price: price,
            discount: discount,
            total: price - discount
        })
    }, [price, discount])

    useEffect(() => {
        if ('yearly' === premiumType) {
            //setDiscount(20)
            setPremiummPlanPrice(premiumPlanYearlyPrice)
        } else {
            //setDiscount(0)
            setPremiummPlanPrice(premiumPlanMountlyPrice)
        }
    }, [premiumType])

    const getSubscriptionPlan = (plans: Plan[], planName: SubscriptionPlan) => {
        let pp = plans.filter((plan) => plan.name === planName);
        return (pp.length > 0) ? pp[0] : {} as Plan;
    }

    const handleChange = (e: React.MouseEvent<HTMLElement>, newValue: SubscriptionPlan) => {
        console.log(newValue)
        if (newValue === SubscriptionPlan.BASIC) {
            setSelectedPlan(basicPlan)
        } else if (newValue === SubscriptionPlan.PREMIUM) {
            setSelectedPlan(premiumPlan)
        }
        //setSelectedPlan(newValue);
    };

    const handleJoinNowClick = () => {
        //console.log("Joint Now .....")
        //console.log(selectedPlan)
        setSummaryDialogOpen(true)
    }

    const handlePremiumTypeChange = (value: string) => {
        setPremiumType(value)
    }

    const handleDiscountChange = (value: number) => {
        console.log(appliedDiscount)
        setDiscount(value)
    }

    const handleSummaryDialogClose = (success: boolean) => {
        setSummaryDialogOpen(false)
        if (success) {
            setSignUpDialogOpen(true)
        } else {
            setDiscount(0)
            setAppliedDiscount(false)
        }
    }

    const handleSignUpDialogClose = (success: boolean) => {
        setSignUpDialogOpen(false)
        if (success) {
            setVerifyCodeDialogOpen(true);
        }
    }

    const handleVerifyCodeDialogClose = (success: boolean) => {
        setVerifyCodeDialogOpen(false)
        if (success) {
            setSetPasswordDialogOpen(true)
        }
    }

    const handleSetPasswordDialogClose = (success: boolean) => {
        setSetPasswordDialogOpen(false)
        if (success) {
            // postSubscription(subscriptionOrder)
            //     .then((res) => {
            //         cleanToken()
            //         if (res.data.status === 200) {
            //             setWaiting(false)
            //             setSetPasswordSuccessDialogOpen(true)
            //             setSubscriptionId(res.data.data.id)
            //             setSetPasswordDialogOpen(false)
            //         }
            //     })
            //     .catch((err) => {
            //         console.error(err)
            //     })
            setSubscriptionCreateOpen(true)
        }
    }

    const handleSubscriptionDialogClose = (success: boolean) => {
        setSubscriptionCreateOpen(false)
        if(success) {
            setSetPasswordSuccessDialogOpen(true)
        }
    }

    const handleSetPasswordSuccessDialogClose = (success: boolean) => {
        if (success) {
            navigate(`/payment?id=${subscriptionId}&status=pending`)
        }
    }

    const handleSetEmail = (email: string) => {
        setEmail(email)
    }

    return (
        <MainLayout>
            <WaitingProgress open={waiting} onClose={() => console.log('cannot flose')} />
            <OrderSummaryDialog order={subscriptionOrder} premiumType={premiumType} premiumPlanPrice={premiumPlanPrice} upgradeFlag={false} setDiscount={handleDiscountChange} open={summaryDialogOpen} onClose={handleSummaryDialogClose} />
            <SignUpDialog email={email} setEmail={handleSetEmail} setUserId={setUserId} open={signUpDialogOpen} onClose={handleSignUpDialogClose} />
            <VerifyCodeDialog email={email} userId={userId} setSessionId={setSessionId} open={verifyCodeDialogOpen} onClose={handleVerifyCodeDialogClose} />
            <SetPasswordDialog sessionId={sessionId} open={setPasswordDialogOpen} onClose={handleSetPasswordDialogClose} />
            <SetPasswordSuccessDialog open={setPasswordSuccessDialogOpen} onClose={handleSetPasswordSuccessDialogClose} />
            <SubscriptionCreateDialog subscriptionOrder={subscriptionOrder} setSubscriptionId={setSubscriptionId} open={subscriptionCreateOpen} onClose={handleSubscriptionDialogClose}/>

            <ContentWrapper>
                <Grid container spacing={5}>
                    <Grid item xs={8}>
                        <UpgradeYourAccountCard />
                    </Grid>
                    <Grid item xs={4}>
                        <MyPlanCard subscription={{ plan: { name: SubscriptionPlan.PREE } as Plan } as Subscription} setSubsription={() => console.log('no function')} upgradeSubscriptionPlan={() => console.log('not thing')} />
                    </Grid>
                </Grid>
            </ContentWrapper>
            <Box mt={4} height={1146} sx={{ backgroundColor: '#F9F9F9' }}>
                <ContentWrapper>
                    <Stack direction={'column'}>
                        <Box my={3} display={'block'} justifyContent={'center'} textAlign={'center'}>
                            <Typography sx={{ fontSize: '48px', fontWeight: '700' }}>Join Us Now</Typography>
                            <Typography sx={{ fontSize: '21px' }}>Cancel Anytime.</Typography>
                        </Box>
                        <Box my={3} display={'flex'} justifyContent={'center'} >
                            <Box width={442}>
                                <StyledToggleButtonGroup
                                    exclusive
                                    value={selectedPlan.name}
                                    onChange={handleChange}
                                >
                                    <StyledToggleButton value={SubscriptionPlan.BASIC}>Basic</StyledToggleButton>
                                    <StyledToggleButton value={SubscriptionPlan.PREMIUM}>Premium</StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Box>
                        </Box>
                        <Box my={3} display={'flex'} justifyContent={'center'}>
                            <PlanDetailPanel value={selectedPlan.name} name={SubscriptionPlan.BASIC}>
                                <StyledPaperDetail elevation={6}>
                                    <Stack p={5} spacing={2}>
                                        <Typography fontSize={'32px'} fontWeight={700}>Basic Plan</Typography>
                                        <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                            <Stack px={4} pt={2} pb={4} spacing={1}>
                                                <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                                                    <Typography fontSize={48} fontWeight={700} color={'#0C4992'}>USD {basicPlanPrice}</Typography>
                                                    <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>Joining Fee</Typography>
                                                </Stack>
                                                <BasicPlanContent />
                                            </Stack>
                                        </Paper>
                                        <Stack py={2} spacing={1} textAlign={'center'}>
                                            < JoinNowButton onClick={handleJoinNowClick} disabled={authenticated} />
                                            <Link to="/terms-and-conditions" >
                                                <Typography fontSize={12} color="#333333">Terms and conditions apply.</Typography>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                </StyledPaperDetail>
                            </PlanDetailPanel>
                            <PlanDetailPanel value={selectedPlan.name} name={SubscriptionPlan.PREMIUM}>
                                <StyledPaperDetail elevation={6}>
                                    <Stack p={5} spacing={2}>
                                        <Typography fontSize={'32px'} fontWeight={700}>Premium Plan</Typography>
                                        <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                            <Stack px={4} pt={2} pb={4} spacing={2}>
                                                <Accordion sx={{ boxShadow: 'none' }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExplandMoreIcon />}
                                                        aria-aria-controls="premium-panel-content"
                                                        id="premium-panel-content"
                                                        sx={{
                                                            padding: 0, margin: 0,
                                                            '.MuiAccordionSummary-content': {
                                                                margin: 0
                                                            }
                                                        }}
                                                    >
                                                        <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                                                            <Typography fontSize={48} fontWeight={700} color={'#0C4992'}>USD {basicPlanPrice}</Typography>
                                                            <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>Joining Fee</Typography>
                                                        </Stack>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <BasicPlanContent />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Stack>
                                        </Paper>
                                        <Box display={'flex'} justifyContent={'center'} >
                                            <PlusIcon />
                                        </Box>
                                        <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                            <Stack px={4} pt={2} pb={4} spacing={2}>
                                                <Grid container >
                                                    <Grid item xs={8}>
                                                        <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                                                            <Typography fontSize={48} fontWeight={700} color={'#0C4992'}>USD {premiumPlanPrice}</Typography>
                                                            <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>{premiumType === 'monthly' ? 'Monthly' : 'Yearly'}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <MonthlyYearlySwitch value={premiumType} setValue={handlePremiumTypeChange} />
                                                    </Grid>
                                                </Grid>
                                                <PremiumPlanContent />
                                            </Stack>
                                        </Paper>
                                        <Stack py={2} spacing={1} textAlign={'center'}>
                                            <JoinNowButton onClick={handleJoinNowClick} disabled={authenticated} />
                                            <TermAndConditionLink />
                                        </Stack>
                                    </Stack>
                                </StyledPaperDetail>
                            </PlanDetailPanel>
                        </Box>
                    </Stack>
                </ContentWrapper>
            </Box>
        </MainLayout>
    )
}

export default PricingPage