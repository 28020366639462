import { Table, TableBody, TableHead } from "@mui/material"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "../_common/styles"
import { MyIndividual, ResultPage } from "../../../types"
import { rowClassOf } from "../_common"
import { Link } from "react-router-dom"

interface Props {
    result: ResultPage<MyIndividual> | undefined
}

interface ContentRowProps {
    item: MyIndividual
    rowNumber: number
    firstElement: number
}

const ContentRow = (props: ContentRowProps) => {
    const { item, rowNumber, firstElement } = props

    const rowCount = Math.max(item.aliases.length, item.dateOfBirth.length)

    console.log(`rowCount = ${rowCount}`)
    console.log(item)

    const rowNumberOf = (index: number, number: number) => {
        return (index === 0) ? number + firstElement : ''
    }

    const nameOf = (index: number, item: MyIndividual) => {
        return (index === 0) ? item.name : ''
    }

    const dateOfBirthOf = (index: number, item: MyIndividual) => {
        return (item.dateOfBirth.length > index) ? item.dateOfBirth[index] : ''
    }

    const aliasOf = (index: number, item: MyIndividual) => {
        return (item.aliases.length > index) ? item.aliases[index] : ''
    }

    const viewOf = (index: number, item: MyIndividual) => {
        return (index === 0) ? <Link to={`/sanctions/malaysia/individuals/${item.id}`}>View</Link> : ''
    }

    return (
        <>
            {[...Array(rowCount)].map((_x, i) => (
                <StyledTableRow className={rowClassOf(i, rowCount, rowNumber)}>
                    <StyledTableCell align="center">{rowNumberOf(i, rowNumber)}</StyledTableCell>
                    <StyledTableCell>{nameOf(i, item)}</StyledTableCell>
                    {(item.dateOfBirth !== null && item.dateOfBirth.length > 0) && (
                        <StyledTableCell>{dateOfBirthOf(i, item)}</StyledTableCell>
                    )}
                    {(item.aliases !== null && item.aliases.length > 0) && (
                        <StyledTableCell>{aliasOf(i, item)}</StyledTableCell>
                    )}
                    <StyledTableCell align="center">{viewOf(i, item)}</StyledTableCell>
                </StyledTableRow>
            ))}
        </>
    )
}

const MyIndividualResultContent = (props: Props) => {
    const { result } = props

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell width={'10%'} align="center">No</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell width={'15%'}>Date Of Birth</StyledTableCell>
                        <StyledTableCell width={'15%'}>Alias</StyledTableCell>
                        <StyledTableCell width={'10%'}></StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {(result?.results.map((item, index) => (
                        <ContentRow firstElement={result?.firstElement} item={item} rowNumber={index} />
                    )))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default MyIndividualResultContent