import { createSvgIcon } from "@mui/material";

const FaqIcon = createSvgIcon(
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.25 26.25L18.75 18.75M12.5 16.25V16.2625M12.5001 12.5C12.8148 12.5002 13.1244 12.4211 13.4004 12.2702C13.6765 12.1193 13.9102 11.9013 14.0799 11.6364C14.2496 11.3715 14.3499 11.0681 14.3716 10.7542C14.3933 10.4404 14.3357 10.1261 14.204 9.84031C14.0723 9.55456 13.8709 9.30654 13.6182 9.11909C13.3655 8.93164 13.0697 8.8108 12.758 8.76769C12.4463 8.72457 12.1288 8.76058 11.8348 8.87239C11.5407 8.98421 11.2794 9.16822 11.0751 9.4075M3.75 12.5C3.75 13.6491 3.97633 14.7869 4.41605 15.8485C4.85578 16.9101 5.5003 17.8747 6.31282 18.6872C7.12533 19.4997 8.08992 20.1442 9.15152 20.5839C10.2131 21.0237 11.3509 21.25 12.5 21.25C13.6491 21.25 14.7869 21.0237 15.8485 20.5839C16.9101 20.1442 17.8747 19.4997 18.6872 18.6872C19.4997 17.8747 20.1442 16.9101 20.5839 15.8485C21.0237 14.7869 21.25 13.6491 21.25 12.5C21.25 11.3509 21.0237 10.2131 20.5839 9.15152C20.1442 8.08992 19.4997 7.12533 18.6872 6.31282C17.8747 5.5003 16.9101 4.85578 15.8485 4.41605C14.7869 3.97633 13.6491 3.75 12.5 3.75C11.3509 3.75 10.2131 3.97633 9.15152 4.41605C8.08992 4.85578 7.12533 5.5003 6.31282 6.31282C5.5003 7.12533 4.85578 8.08992 4.41605 9.15152C3.97633 10.2131 3.75 11.3509 3.75 12.5Z" stroke="#333333" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    , 'FAQ Icon'
)

export default FaqIcon