import { createSvgIcon } from "@mui/material";


const CoinStackIcon = createSvgIcon(
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 17.5C11.25 19.5712 14.6075 21.25 18.75 21.25C22.8925 21.25 26.25 19.5712 26.25 17.5M11.25 17.5C11.25 15.4288 14.6075 13.75 18.75 13.75C22.8925 13.75 26.25 15.4288 26.25 17.5M11.25 17.5V22.5C11.25 24.57 14.6075 26.25 18.75 26.25C22.8925 26.25 26.25 24.57 26.25 22.5V17.5M3.75 7.5C3.75 8.84 5.18 10.0775 7.5 10.7475C9.82 11.4175 12.68 11.4175 15 10.7475C17.32 10.0775 18.75 8.84 18.75 7.5C18.75 6.16 17.32 4.9225 15 4.2525C12.68 3.5825 9.82 3.5825 7.5 4.2525C5.18 4.9225 3.75 6.16 3.75 7.5ZM3.75 7.5V20C3.75 21.11 4.715 21.8125 6.25 22.5M3.75 13.75C3.75 14.86 4.715 15.5625 6.25 16.25" stroke="#333333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    , 'CoinStackIcon'
)

export default CoinStackIcon