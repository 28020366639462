import { Box, Typography, styled } from "@mui/material"
import { Link } from "react-router-dom"

export const StyledLabel = styled(Typography)(() => ({
    fontSize: 18,
    fontWeight: 500,
}))

export const StyledSmallLabel = styled(Typography)(() => ({
    fontSize: 14,
    fontWeight: 400,
}))

export const StyledBox = styled(Box)(({ theme }) => ({
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    borderBottom: `5px solid ${theme.palette.primary.main}`
}))

export const StyledWrapper = styled(Box)(({ theme }) => ({
    paddingBottom: theme.spacing(2)
}))

export const StyledForgetPasswordLink = styled(Link)(() => ({
    textDecoration: 'none',
    color: '#0066FF'
}))