import axios, { AxiosResponse } from "axios";
import { oauth2GetTokenByRefreshToken } from "./oauth2";

const baseUrlContents = `${process.env.REACT_APP_API_BASE_URL}/v1/content`

const getIndividualSanctions = (source: string, page: number, limit: number, keyword: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/${source}/individuals`, {
        params: {
            page: page,
            limit: limit,
            keyword: keyword
        }
    })
        .catch((err) => {
            if (err.response.status === 401) {
                return oauth2GetTokenByRefreshToken()
                    .then(() => {
                        // recovery
                        return getIndividualSanctions(source, page, limit, keyword)
                    })
            }
            throw err
        })
}

const getEntitySanctions = (source: string, page: number, limit: number, keyword: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/${source}/entities`, {
        params: {
            page: page,
            limit: limit,
            keyword: keyword
        }
    })
        .catch((err) => {
            if (err.response.status === 401) {
                return oauth2GetTokenByRefreshToken()
                    .then(() => {
                        return getEntitySanctions(source, page, limit, keyword)
                    })
            }
            throw err
        })
}

const getUnIndividualSanctions = (page: number, limit: number, keyword: string, type: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/united-nation/individuals`, {
        params: {
            page: page,
            limit: limit,
            keyword: keyword,
            type: type,
        }
    })
        .catch((err) => {
            console.error(err)
            throw err
        })
}

const getUnIndividualSanctionDetail = (id: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/united-nation/individuals/${id}`)
        .catch((err) => {
            console.error(err)
            throw err
        })
}


const getUnEntitySanctions = (page: number, limit: number, keyword: string, quality: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/united-nation/entities`, {
        params: {
            page: page,
            limit: limit,
            keyword: keyword,
            quality: quality,
        }
    })
        .catch((err) => {
            console.error(err)
            throw err
        })
}

const getUnEntitySanctionDetail = (id: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/united-nation/entities/${id}`)
        .catch((err) => {
            console.log(err)
            throw err
        })
}

const getMyIndividualSanctions = (page: number, limit: number, keyword: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/malaysia/individuals`, {
        params: {
            page: page,
            limit: limit,
            keyword: keyword
        }
    })
        .catch((err) => {
            console.error(err)
            throw err
        })
}

const getMyIndividualSanctionDetail = (id: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/malaysia/individuals/${id}`)
        .catch((err) => {
            console.log(err)
            throw err
        })
}


const getMyEntitySanctions = (page: number, limit: number, keyword: string, quality: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/malaysia/entities`, {
        params: {
            page: page,
            limit: limit,
            keyword: keyword,
            quality: quality,
        }
    })
        .catch((err) => {
            console.error(err)
            throw err
        })
}

const getMyEntitySanctionDetail = (id: string): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/sanctions/malaysia/entities/${id}`)
        .catch((err) => {
            console.log(err)
            throw err
        })
}

const getSanctionAlerts = (): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/alerts`);
}

const getTransactions = (): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlContents}/transactions`)
}


export {
    getIndividualSanctions,
    getEntitySanctions,
    getUnIndividualSanctions,
    getUnIndividualSanctionDetail,
    getUnEntitySanctions,
    getUnEntitySanctionDetail,
    getMyIndividualSanctions,
    getMyIndividualSanctionDetail,
    getMyEntitySanctions,
    getMyEntitySanctionDetail,
    getSanctionAlerts,
    getTransactions,
}