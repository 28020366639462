import { Grid, Paper, Stack, Typography } from "@mui/material"
import MonthlyYearlySwitch from "../../elements/MonthlyYearlySwitch"
import PremiumPlanContent from "../../contents/PremiumPlanContent"

interface Props {
    value: string
    setValue: (value: string) => void
}

const PremiumPlanDetailCard = (props: Props) => {
    const { value, setValue } = props

    const handleSetValue = (value: string) => {
        setValue(value)
    }

    return (
        <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
            <Stack px={4} pt={2} pb={4} spacing={1}>
                <Grid container >
                    <Grid item xs={8}>
                        <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                            <Typography fontSize={48} fontWeight={700} color={'#0C4992'}>USD 200</Typography>
                            <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>Monthly</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <MonthlyYearlySwitch value={value} setValue={handleSetValue} />
                    </Grid>
                </Grid>
                <PremiumPlanContent />
            </Stack>
        </Paper>
    )
}

export default PremiumPlanDetailCard