import axios, { AxiosResponse } from "axios"
import { Cookies } from "react-cookie";

const OAUTH_TOKEN = 'secure_token'
const cookies = new Cookies(OAUTH_TOKEN, { path: '/' })

const baseUrlOauth2Token = `${process.env.REACT_APP_API_BASE_URL}/oauth2/token`

const oauth2GetTokenByPassword = (username: string, password: string): Promise<AxiosResponse> => {
    return axios.post(baseUrlOauth2Token, {
        'grant_type': 'password',
        'username': username,
        'password': password,
    }, {
        headers: {
            'Authorization': 'Basic c2FuY3Rpb24tY2xpZW50OnNlY3JldA==',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then((res) => {
            cookies.set(OAUTH_TOKEN, res.data, {
                path: "/"
            })
            return res
        })
        .catch((err) => {
            console.log(err)
            throw err
        })
}

const oauth2GetTokenByRefreshToken = (): Promise<AxiosResponse> => {
    const refreshToken = cookies.get(OAUTH_TOKEN).refresh_token
    return axios.post(baseUrlOauth2Token, {
        'grant_type': 'refresh_token',
        'refresh_token': refreshToken
    }, {
        headers: {
            'Authorization': 'Basic c2FuY3Rpb24tY2xpZW50OnNlY3JldA==',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then((res) => {
            cookies.set(OAUTH_TOKEN, res.data, {
                path: "/"
            })
            return res
        })
        .catch((err) => {
            console.log(err)
            throw err
        })
}

const oauth2GetTokenByVerifyCode = (email: string, verifyCode: string): Promise<AxiosResponse> => {
    return axios.post(baseUrlOauth2Token, {
        'grant_type': 'verify_code',
        'verify_code': verifyCode,
        'email': email,
    }, {
        headers: {
            'Authorization': 'Basic c2FuY3Rpb24tY2xpZW50OnNlY3JldA==',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then((res) => {
            cookies.set(OAUTH_TOKEN, res.data, {
                path: "/"
            })
            return res
        })
        .catch((err) => {
            console.log(err)
            throw err;
        })
}

const cleanToken = () => {
    cookies.remove(OAUTH_TOKEN)
}

export {
    oauth2GetTokenByPassword,
    oauth2GetTokenByRefreshToken,
    oauth2GetTokenByVerifyCode,
    cleanToken
}