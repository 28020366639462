import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material"
import ContentWrapper from "../../components/elements/ContentWrapper"
import MainLayout from "../../components/layouts/MainLayout"
import FaqIcon from "../../components/icons/FqaIcon"
import { StyledAccordion, StyledAccordionSummary, StyledDetailText, StyledTitleText } from "./styles"
import { ExpandMore } from "@mui/icons-material"
import ExplandMoreIcon from "../../components/icons/ExplandMoreIcon"
import { ExpandPlusIcon } from "../../components/icons/ExpandPlusIcon"


const FaqPage = () => {
    return (
        <MainLayout>
            <ContentWrapper>
                <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                    <FaqIcon sx={{ height: 30, width: 30 }} />
                    <Typography sx={{ lineHeight: '48px', fontSize: 32, fontWeight: 700 }} >Frequently Asked Questions</Typography>
                </Stack>
                <Box sx={{ mt: 4 }}>
                    <Stack direction={'column'} spacing={2}>
                        <StyledAccordion elevation={0}>
                            <StyledAccordionSummary expandIcon={<ExpandPlusIcon />}>
                                <StyledTitleText>What is Sanctions?</StyledTitleText>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <StyledDetailText>
                                    Sanctions refer to a variety of measures imposed by one country or a group of countries against another country, organization, or individual to encourage
                                    a change in behaviour, punish non-compliance with international norms or laws, or achieve soecific policy objectives.
                                </StyledDetailText>
                            </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion elevation={0}>
                            <StyledAccordionSummary expandIcon={<ExpandPlusIcon />}>
                                <StyledTitleText>How does auto termination works?</StyledTitleText>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                TBA
                            </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion elevation={0}>
                            <StyledAccordionSummary expandIcon={<ExpandPlusIcon />}>
                                <StyledTitleText>What is API Key?</StyledTitleText>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                TBA
                            </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion elevation={0}>
                            <StyledAccordionSummary expandIcon={<ExpandPlusIcon />}>
                                <StyledTitleText>How to download previous report if you’ve unsubscribed?</StyledTitleText>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                TBA
                            </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion elevation={0}>
                            <StyledAccordionSummary expandIcon={<ExpandPlusIcon />}>
                                <StyledTitleText>How to unsubscribe?</StyledTitleText>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                TBA
                            </AccordionDetails>
                        </StyledAccordion>
                        <StyledAccordion elevation={0}>
                            <StyledAccordionSummary expandIcon={<ExpandPlusIcon />}>
                                <StyledTitleText>What is token and how does it works?</StyledTitleText>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                TBA
                            </AccordionDetails>
                        </StyledAccordion>
                    </Stack>
                </Box>

            </ContentWrapper>
        </MainLayout>
    )
}

export default FaqPage