import { Pagination, TableCell, TableContainer, TableRow, styled } from "@mui/material"

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    border: '1px solid #d9d9d9',
    borderRadius: '32px',
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    
    '&.MuiTableRow-head': {
        backgroundColor: '#f1f1f1',
    },
    '& .MuiTableCell-root:not(:last-child)': {
        borderRight: '1px solid #d9d9d9'
    },
    '& .MuiTableCell-root:last-child': {
        borderLeft: '1px solid #d9d9d9'
    },
    '&.first-sub-row > td': {
        borderBottom: 'none',
        paddingBottom: theme.spacing(1),
    },
    '&.last-sub-row > td': {
        borderTop: 'none',
        paddingTop: theme.spacing(1),
    },
    '&.middle-sub-row > td': {
        borderTop: 'none',
        borderBottom: 'none',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    "&.odd": {
        backgroundColor: '#FAFAFA'
    }
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({ 
    // border: '1px solid #d9d9d9',
    '&.MuiTableCell-root': {
        verticalAlign: 'top'
    },
    '&.MuiTableCell-head': {
        fontSize: '16px',
        fontWeight: 700,
    }

}))

export const StyledPagination = styled(Pagination)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    '.MuiPagination-ul': {
        borderRadius: '32px',
        border: '1px solid #D9D9D9',
        padding: theme.spacing(1)
    },
    '.MuiPaginationItem-root': {
        margin: `0 ${theme.spacing(1)}`
    }
}))