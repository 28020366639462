import { stat } from "fs"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { patchInvoiceStatus } from "../../api/billing"


const TopupPage = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const invoiceId = searchParams.get('invoiceId')
    const status = searchParams.get('status')

    useEffect(() => {
        console.log(status)
        if (invoiceId !== null) {
            //if (status === 'completed') {
                console.log('xxxx')
                patchInvoiceStatus(invoiceId, 'completed')
                .then((_res) => {
                    console.log("topup success")
                    navigate("/my-plan")
                })
            //}
        }
    }, [])

    return (
        <>
        </>
    )
}

export default TopupPage