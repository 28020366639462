import React from "react";
import AppHeader from "../../elements/AppHeader";
import { Container, ThemeProvider } from "@mui/material";
import AppTheme from "../../../Theme";

interface Props {
    children: any
}

const MainLayout: React.FC<Props> = ({ children }) => {
    return (
        <ThemeProvider theme={AppTheme}>
            <AppHeader />
            {children}
        </ThemeProvider>
    )
}

export default MainLayout;