import React from "react";
import MainLayout from "../../components/layouts/MainLayout";
import ContentWrapper from "../../components/elements/ContentWrapper";

const ApiSpecPage = () => {
    return (
        <MainLayout>
            <ContentWrapper>
               <iframe width='1340px' height='1140px' frameBorder={0} allowFullScreen src="https://docs.altecflex.com/s/5hPYn0FP0"></iframe>
            </ContentWrapper>
        </MainLayout>
    )
}

export default ApiSpecPage