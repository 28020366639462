import { Box, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Paper, Radio, RadioGroup, Stack, Typography } from "@mui/material"
import CloseIcon from "../../icons/CloseIcon"
import { StyledLabel, StyledTopUpButton } from "./styled"
import CoinStackIcon from "../../icons/CoinStackIcon"
import WaitingProgress from "../../elements/WaitingProgress"
import { useState } from "react"

interface Props {
    onTopUp: (amount: number) => Promise<any>
    open: boolean
    onClose: (success: boolean) => void
}

const TopUpCreditDialog = (props: Props) => {
    const { onTopUp, open, onClose } = props

    const [value, setValue] = useState(50)
    const [waiting, setWaiting] = useState(false)

    const handleClose = () => {
        onClose(false)
    }

    const handleTopUpNowClick = () => {
        setWaiting(true)
        onTopUp(value)
            .then(() => {
                setValue(50)
                setWaiting(false)
                onClose(true)
            })
    }

    const handleTopUpSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(+event.target.value)
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '4336px',
                    maxWidth: '436px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5} >
                <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
                <DialogTitle component={Box} sx={{ m: 0, p: 0 }}>
                    <Stack direction={"row"} justifyContent={'space-between'} paddingBottom={1}>
                        <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                            <CoinStackIcon sx={{ height: 30, width: 30 }} />
                            <Typography fontSize={32} fontWeight={700}>Top Up</Typography>
                        </Stack>
                        <IconButton onClick={handleClose} sx={{ width: 50, height: 50 }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ padding: 0, marginTop: 1 }}>
                    <Stack spacing={2}>
                        <Paper variant="outlined" sx={{ borderRadius: '32px', borderColor: '#F1F1F1' }}>
                            <Stack px={2} pt={2} pb={2} spacing={1}>
                                <FormControl>
                                    <RadioGroup
                                        name="top-up-selection"
                                        defaultValue={50}
                                        onChange={handleTopUpSelect}>
                                        <FormControlLabel value={50} control={<Radio size="small" />} label={<StyledLabel>USD 50</StyledLabel>} />
                                        <FormControlLabel value={500} control={<Radio size="small" />} label={<StyledLabel>USD 500</StyledLabel>} />
                                        <FormControlLabel value={1000} control={<Radio size="small" />} label={<StyledLabel>USD 1,000</StyledLabel>} />
                                        <FormControlLabel value={10000} control={<Radio size="small" />} label={<StyledLabel>USD 10,000</StyledLabel>} />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </Paper>
                        <StyledTopUpButton variant="contained" color="secondary"
                            disableElevation fullWidth
                            onClick={handleTopUpNowClick}>
                            Top Up Now
                        </StyledTopUpButton>
                    </Stack>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default TopUpCreditDialog