import { useEffect, useState } from "react"
import { Box, Grid, Stack } from "@mui/material"
import ContentWrapper from "../../components/elements/ContentWrapper"
import MainLayout from "../../components/layouts/MainLayout"
import SanctionDetailCard from "../../components/cards/SanctionDetailCard"
import { StyledBox, StyledTitle, StyledValue } from "./styles"
import { MyEntityDetail } from "../../types"
import { useParams } from "react-router-dom"
import { getMyEntitySanctionDetail } from "../../api/sanctions"
import LoadingPlaceHolder from "../../components/elements/LoadingPlaceHolder"

const MyEntityDetailPage = () => {
    const { id } = useParams()

    console.log(id)

    const [value, setValue] = useState<MyEntityDetail>()

    useEffect(() => {
        if (id !== undefined) {
            getMyEntitySanctionDetail(id)
                .then((res) => {
                    console.log(res.data.data)
                    setValue(res.data.data)
                })
        }
    }, [id])

    if (value === undefined) {
        return (
            <MainLayout>
                <LoadingPlaceHolder />
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <ContentWrapper>
                <Stack spacing={5}>
                    <SanctionDetailCard title="i. Individual Particulars">
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Reference Number</StyledTitle>
                                    <StyledValue>{value?.reference}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Name</StyledTitle>
                                    <StyledValue>{value?.name}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                    </SanctionDetailCard>
                    <SanctionDetailCard title="ii. Details">
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Entity Address</StyledTitle>
                                    <StyledValue>{'N/A'}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Listed On</StyledTitle>
                                    <StyledValue>{value?.listedOn}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                    </SanctionDetailCard>
                    <SanctionDetailCard title="iii. Alias">
                        <StyledTitle>Entity Alias</StyledTitle>
                        <Grid container sx={{ paddingLeft: 0 }}>
                            {value?.aliases.map((v, i) => (
                                <>
                                    {(v !== '') && (
                                        <Grid item xs={6} key={i} sx={{ paddingLeft: '0px' }}>
                                            <Box>
                                                <Grid container alignItems={'flex-start'} sx={{ paddingBottom: 2 }}>
                                                    <Grid item sm={0.5}>{i + 1}.</Grid>
                                                    <Grid item sm={11.5}>
                                                        <Box>
                                                            <Grid container >
                                                                <Grid item sm={4}>Alias</Grid>
                                                                <Grid item sm={0.5}>:</Grid>
                                                                <Grid item sm={7.5}>{v}</Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>

                                                </Grid>
                                            </Box>
                                        </Grid>)}
                                </>
                            ))}
                        </Grid>
                    </SanctionDetailCard>
                </Stack>
            </ContentWrapper>
        </MainLayout>
    )
}

export default MyEntityDetailPage