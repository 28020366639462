import React from "react";
import { Paper, Typography, styled } from "@mui/material";


export const StyledContentPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: 32
}))

export const StyledContentHeader = styled(Typography)(({ theme }) => ({
    lineHeight: '27px',
    fontSize: 18,
    fontWeight: 700,
    marginBottom: theme.spacing(2),
}))

export const StyledContentDetail = styled(Typography)(({ theme }) => ({
    paddingLeft: theme.spacing(2.5),
}))