import { useState } from "react";
import { Stack, Typography } from "@mui/material"
import { StyledSmallToggleButton, StyledSmallToggleButtonGroup } from "./styles"

interface Props {
    value: string
    setValue: (value: string) => void
}

const MonthlyYearlySwitch = (props: Props) => {
    const { value, setValue } = props

    const [discountShow, setDiscountShow] = useState(true);

    const handlePremiumTypeChange = (e: React.MouseEvent<HTMLElement>, newValue: string) => {
        if (newValue !== null) {
            setValue(newValue)
            setDiscountShow(newValue === "monthly")
        }
    }

    return (
        <Stack spacing={0.5} sx={{ textAlign: 'end' }}>
            <StyledSmallToggleButtonGroup
                exclusive
                value={value}
                onChange={handlePremiumTypeChange}
            >
                <StyledSmallToggleButton value="monthly">Monthly</StyledSmallToggleButton>
                <StyledSmallToggleButton value="yearly">Yearly</StyledSmallToggleButton>
            </StyledSmallToggleButtonGroup>
            <Typography display={discountShow ? 'block' : 'none'} sx={{ fontSize: '14px', fontWeight: 500, color: '#0066FF' }}>Save 20% on Yearly!</Typography>
        </Stack>
    )
}

export default MonthlyYearlySwitch