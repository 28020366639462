import { Box,  Dialog } from "@mui/material"

interface Props {
    children: any
    open: boolean
    onClose: (success: boolean) => void
}

const DialogLayout: React.FC<Props> = (props: Props) => {
    const {children, open, onClose} = props

    const handleClose = () => {
        onClose(false)
    }

    return (
        <Dialog onClose={handleClose} open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box sx={{ width: '100%', overflowY: 'auto' }}>
                <Box p={5}>
                    {children}
                </Box>
            </Box>
        </Dialog>
    )
}

export default DialogLayout