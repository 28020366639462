import { Box, CircularProgress } from "@mui/material"


const LoadingPlaceHolder = () => {
    return (
        <Box sx={{ width: 1, height: 500}} >
            <Box sx={{ width: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
            </Box>
        </Box>
    )
}

export default LoadingPlaceHolder