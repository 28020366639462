import { createSvgIcon } from "@mui/material";
import React from "react";

const ExplandMoreIcon = createSvgIcon(
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.75 11.25L15 20L6.25 11.25" stroke="#333333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>,
    "ExplandMoreIcon"
)

export default ExplandMoreIcon