import { Button, styled } from "@mui/material";

interface StyledButton {
    component: any;
    to: string,
}

export const StyledButton = styled(Button)<StyledButton>(({ theme }) => ({
    fontSize: '20px',
    fontWeight: '500',
    margin: theme.spacing(2),
    color: '#ffff',
    display: 'block'
}))