import React from "react"
import { StyledFormControl, StyledInputLabel, StyledOutlinedInput } from "./styles"
import { FormControl, FormLabel, Menu, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import FormValidateError from "../FormValidateError"

interface Props {
    label: string
    id?: string
    name?: string
    placeholder: string
    error: string
    clearError: () => void
    value: string
    setValue: (value: string) => void
}

const EnquiryTypeInputWithLabel = (props: Props) => {
    const { id, label, name, placeholder, error, clearError, value, setValue } = props

    const types = [
        'Subscription',
        'Email Verification',
        'Tokens',
        'API Keys'
    ]

    //console.log(value === '')

    const handleSelection = (e: SelectChangeEvent) => {
        clearError()
        setValue(e.target.value)
    }

    return (
        <StyledOutlinedInput variant="standard" fullWidth error={error !== ''}>
            <StyledInputLabel shrink >{label}</StyledInputLabel>
            <StyledFormControl error={error !== ''}>
                <Select
                    id={id}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleSelection}
                    displayEmpty
                    renderValue={(value) => {
                        return value !== "" ? value : placeholder
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: '24px',
                                '& .Mui-selected': {
                                    color: '#fff',
                                    backgroundColor: '#0C4992',
                                }
                            }
                        }
                    }}
                >
                    {types.map((val, idx) => (
                        <MenuItem key={idx} value={val}>{val}</MenuItem>
                    ))}
                </Select>
            </StyledFormControl>
            <FormValidateError error={error} />
        </StyledOutlinedInput>
    )
}

export default EnquiryTypeInputWithLabel