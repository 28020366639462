import { Button, Typography, darken, styled } from "@mui/material";

export const StyledTopUpButton = styled(Button)(({ theme }) => ({
    padding: "4px 1px",
    height: '50px',
    display: 'block',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 25,
    '&:hover': {
        backgroundColor: darken('#FACB57', 0.1),
    },
}))

export const StyledLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 500
}))