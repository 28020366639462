import { jwtDecode } from "jwt-decode"
import { Cookies } from "react-cookie"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { oauth2GetTokenByRefreshToken } from "../../api/oauth2"
import { decode } from "punycode"

const OAUTH_TOKEN = 'secure_token'
const cookies = new Cookies(OAUTH_TOKEN, { path: '/' })


const isTokenExist = () => {
    return cookies.get(OAUTH_TOKEN) !== undefined
}

const isUnauthorized = (): boolean => {
    const token = cookies.get(OAUTH_TOKEN)
    //console.log(token)
    if (token !== undefined && token.access_token !== undefined) {
        const decodedToken = jwtDecode(token.access_token)
        console.log(decodedToken)
        if (decodedToken.exp !== undefined) {
            if (Date.now() > decodedToken.exp * 1000) {
                console.log('expired')
                return true
            }
            return false
        } else {
            return true
        }
    }
    return true
}

const RedirectToLogin = () => {
    let location = useLocation()
    return (
        <Navigate to={`/login?redirect=${location.pathname}`} state={{ from: location }} replace />
    )
}

const RequireAuth = () => {
    console.log('check authenticate')
    if (isUnauthorized()) {
        console.log("unauthorized.")
        if (isTokenExist()) {
            console.log('refresh token')
            oauth2GetTokenByRefreshToken()
                .then(() => {
                    console.log('refresh token success')
                    //return (<Outlet />)
                })
                .catch((err) => {
                    console.log('refresh token error')
                    if (err.response.status === 400) {
                        cookies.remove(OAUTH_TOKEN)
                        return (<RedirectToLogin />)
                    }
                })
        } else {
            return (<RedirectToLogin />)
        }
    } else {
        console.log('authorized')
    }

    return (<Outlet />)
}

export default RequireAuth