import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from "@mui/material";
import CloseIcon from "../../icons/CloseIcon";
import PlusIcon from "../../icons/PlusIcon";
import { Link } from "react-router-dom";
import StackIcon from "../../icons/StackIcon";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";
import FreePlanContent from "../../contents/FreePlanContent";
import BasicPlanWithPriceContent from "../../contents/BasicPlanWithPriceContent";
import PremiumPlanWithPriceContent from "../../contents/PremiumPlanWithPriceContent";
import { Plan } from "../../../types";

interface Props {
    open: boolean;
    plan: Plan;
    onClose: (success: boolean) => void;
}

const ViewPlanDialog = (props: Props) => {
    const { open, plan, onClose } = props

    const handleClose = () => {
        onClose(false)
    }

    const handleProceedClick = () => {
        onClose(true)
    }

    return (
        <Dialog onClose={handleClose} open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box sx={{ width: '100%', overflowY: 'auto' }}>
                <Box p={5}>
                    <DialogTitle component={Box} sx={{ m: 0, p: 0 }}>
                        <Stack direction={"row"} justifyContent={'space-between'} paddingBottom={1}>
                            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                                <StackIcon />
                                {plan.name === 'free' && (
                                    <Typography fontSize={32} fontWeight={700} color={'#333333'}>
                                        Free Plan
                                    </Typography>
                                )}
                                {plan.name === 'basic' && (
                                    <Typography fontSize={32} fontWeight={700} color={'#333333'}>
                                        Basic Plan
                                    </Typography>
                                )}
                                {plan.name === 'premium' && (
                                    <Typography fontSize={32} fontWeight={700} color={'#333333'}>
                                        Premium Plan
                                    </Typography>
                                )}
                            </Stack>
                            <IconButton onClick={handleClose} sx={{ width: 50, height: 50 }}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    <DialogContent sx={{ padding: 0, marginTop: 1 }}>
                        <Stack spacing={2}>
                            {(plan.name === 'free') && (
                                <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                    <Stack px={2} pt={2} pb={2} spacing={1}>
                                        <FreePlanContent />
                                    </Stack>
                                </Paper>
                            )}
                            {(plan.name === 'basic' || plan.name === 'premium') && (
                                <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                    <Stack px={2} pt={2} pb={2} spacing={1}>
                                        <BasicPlanWithPriceContent />
                                    </Stack>
                                </Paper>
                            )}
                            {(plan.name === 'premium') && (
                                <>
                                    <Box px={1} display={'flex'} justifyContent={'center'} >
                                        <PlusIcon sx={{ width: 30, height: 30 }} />
                                    </Box>
                                    <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                        <Stack px={2} pt={2} pb={2} spacing={1}>
                                            <PremiumPlanWithPriceContent />
                                        </Stack>
                                    </Paper>
                                </>
                            )}
                            <Stack py={2} spacing={1} textAlign={'center'}>
                                {plan.name === 'free' && (
                                    <StyledSubmitButton variant="contained" color="secondary"
                                        disableElevation fullWidth
                                        onClick={handleProceedClick}>
                                        Join Now
                                    </StyledSubmitButton>
                                )}
                                {plan.name === 'basic' && (
                                    <StyledSubmitButton variant="contained" color="secondary"
                                        disableElevation fullWidth
                                        onClick={handleProceedClick}>
                                        Upgrade to Premium
                                    </StyledSubmitButton>
                                )}
                                {plan.name === 'premium' && (
                                    <StyledSubmitButton variant="contained" color="secondary"
                                        disableElevation fullWidth
                                        onClick={handleProceedClick}>
                                        Unsubscribe
                                    </StyledSubmitButton>
                                )}
                                {(plan.name === 'basic' || plan.name === 'premium') && (
                                    <Link to="/terms-and-conditions" >
                                        <Typography fontSize={12} color="#333333">Terms and conditions apply.</Typography>
                                    </Link>
                                )}
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )
}

export default ViewPlanDialog