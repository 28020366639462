import { VisibilityOff, Visibility } from '@mui/icons-material'
import { Container, Paper, Box, Stack, InputAdornment, IconButton, FormControlLabel, Checkbox, Typography } from '@mui/material'
import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import ContentWrapper from '../ContentWrapper'

interface Props {
    children: any
}

const UserLoginLayout: React.FC<Props> = ({ children }) => {
    return (
        <MainLayout>
            <ContentWrapper>
                <Box sx={{ my: 3, display: 'flex', justifyContent: 'center' }}>
                    <Paper elevation={0}
                        sx={{
                            p: 0, borderRadius: "32px",
                            width: "1000px", height: "685px",
                            backgroundColor: "#FAFAFA",
                            display: 'flex',
                        }}>
                        <Box sx={{
                            width: "489px", height: "685px",
                            borderRadius: "32px 0 0 32px",
                            backgroundImage: `url(/img/login-placeholder.png)`
                        }} />
                        <Box sx={{ p: 5, width: '45%' }}>
                            {children}
                        </Box>
                    </Paper>
                </Box>
            </ContentWrapper>
        </MainLayout>
    )
}

export default UserLoginLayout