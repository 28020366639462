import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material"
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";
import { useState } from "react";
import VerifyCodeInput from "../../elements/VerifyCodeInput";
import WaitingProgress from "../../elements/WaitingProgress";
import { oauth2GetTokenByVerifyCode } from "../../../api/oauth2";

interface Props {
    email: string
    userId: string
    setSessionId: (sid: string) => void
    open: boolean
    onClose: (success: boolean) => void
}

const VerifyCodeDialog = (props: Props) => {
    const { open, email, userId, setSessionId, onClose } = props
    const [otp, setOtp] = useState('')
    const [error, setError] = useState('')
    const [waiting, setWaiting] = useState(false)

    const handleVerifyCodeConfirmClick = () => {
        setError('')
        setWaiting(true)
        oauth2GetTokenByVerifyCode(email, otp)
            .then((res) => {
                setWaiting(false)
                console.log('veriy_code success')
                onClose(true)
            })
            .catch((err) => {
                setWaiting(false)
                console.log(err)
                setError('Invalid OTP')
                setWaiting(false)
            })
    }

    const handleSetValue = (value: string) => {
        setError('')
        setOtp(value)
    }

    const handleResetError = () => {
        setError('')
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5} sx={{ width: '91%', overflowY: 'scroll', boxSizing: 'content-box' }}>
                <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
                <DialogContent sx={{ m: 0, p: 0 }}>
                    <Stack spacing={2}>
                        <Typography fontSize={32} fontWeight={700}>
                            We need your email
                        </Typography>
                        <Typography fontSize={18} fontWeight={500}>
                            To proceed payment, we need to verify your email.
                        </Typography>
                        <Typography fontSize={18} fontWeight={700}>
                            {email}
                        </Typography>
                        <Box pb={2}>
                            <VerifyCodeInput error={error} resetError={handleResetError} setValue={handleSetValue} />
                        </Box>
                        <Stack direction={'column'} spacing={1} alignItems={'center'} justifyItems={'center'}>
                            <StyledSubmitButton variant="contained" color="secondary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleVerifyCodeConfirmClick}
                            >
                                Okey
                            </StyledSubmitButton>
                            <Typography>If you did not receive verification code, Resend</Typography>
                        </Stack>

                    </Stack>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default VerifyCodeDialog