import React from "react";
import { StyledInputBase, StyledInputLabel, StyledOutlinedInput } from "./styles";
import { FormControl, Menu, MenuItem, Select } from "@mui/material";
import FormValidateError from "../FormValidateError";

interface Props {
    label: string
    id?: string
    name?: string
    placeholder: string
    error?: string
    clearError?: () => void,
    value: string
    setValue: (value: string) => void
    disable?: boolean
}

const UserTextInputWithLabel = (props: Props) => {
    const { label, id, name, placeholder, error, clearError, value, setValue, disable } = props

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (clearError !== undefined && error !== '') {
            clearError()
        }
        setValue(e.target.value)
    }

    return (
        <StyledOutlinedInput variant="standard" fullWidth error={error !== ''}>
            <StyledInputLabel shrink htmlFor={id}>{label}</StyledInputLabel>
            <StyledInputBase
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleValueChange}
                disabled={disable}
            />
            <FormValidateError error={error !== undefined ? error : ''} />
        </StyledOutlinedInput>
    )
}

export default UserTextInputWithLabel