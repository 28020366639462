import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material"
import AlertIcon from "../../icons/AlertIcon"
import CloseIcon from "../../icons/CloseIcon"
import { StyledChip } from "./styles"
import PaginationDetail from "../../elements/PaginationDetail"
import PaginationWithSearchLimit from "../../elements/SearchLimitAndPagination"
import { useEffect, useState } from "react"
import { ResultPage } from "../../../types"
import SanctionAlertResultContent from "../../contents/SanctionAlertResultContent"
import { getSanctionAlerts } from "../../../api/sanctions"

interface Props {
    open: boolean,
    onClose: (success: boolean) => void
}

const SanctionAlertDialog = (props: Props) => {
    const { open, onClose } = props

    const [result, setResult] = useState<ResultPage<any>>()
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)

    useEffect(() => {
        getSanctionAlerts()
            .then((res) => {
                setResult(res.data.data)
            })
    }, [open])

    const handleClose = () => {
        onClose(false)
    }

    const handleLimitChange = (limit: number) => {
        setLimit(limit)
    }

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '1141px',
                    height: '593px',
                    maxWidth: '1141px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box sx={{ width: '100%', height: '90%' }}>
                <Box p={5} height={'100%'}>
                    <DialogTitle>
                        <Stack direction={"row"} justifyContent={'space-between'} paddingBottom={1}>
                            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                                <AlertIcon />
                                <Typography fontSize={32} fontWeight={700}>Sanction Alerts</Typography>
                                <StyledChip label="New" />
                            </Stack>
                            <IconButton onClick={handleClose} sx={{ width: 50, height: 50 }}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    <DialogContent sx={{ m: 0, p: 0, height: '80%', overflow: 'auto' }}>
                        {(result !== undefined) && (
                            <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
                                <Stack spacing={2}>
                                    <PaginationDetail result={result} />
                                    <Box sx={{width: '99%'}}>
                                        <SanctionAlertResultContent result={result} />
                                    </Box>
                                </Stack>
                                <Box ml={0}>
                                    <PaginationWithSearchLimit
                                        limit={limit} page={page} totalPage={result.totalPage}
                                        onLimitChange={handleLimitChange}
                                        onPageChange={handlePageChange}
                                    />
                                </Box>
                            </Stack>
                        )}
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )
}

export default SanctionAlertDialog