import { Button, darken } from "@mui/material"
import { Link } from "react-router-dom"
import DownloadIcon from "../../icons/DownloadIcon"

const DownloadReportButton = () => {
    return (
        <Button component={Link} to="/reports"
        sx={{
            display: 'flex',
            padding: '4px 1px',
            width: '250px',
            height: '50px',
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 25,
            color: '#333',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            border: '1px solid #D9D9D9',
            '&:hover': {
                backgroundColor: darken('#fff', 0.1),
            },
        }}
        startIcon={<DownloadIcon />}>
             Download Reports
        </Button>
    )
}

export default DownloadReportButton