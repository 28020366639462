import MainLayout from "../../components/layouts/MainLayout";
import ContentWrapper from "../../components/elements/ContentWrapper";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { StyledTab, StyledTabs } from "./styles";
import TabPanel from "../../components/elements/TabPanel";
import { useEffect, useState } from "react";
import InvoiceResultContent from "../../components/contents/InvoiceResultContent";
import TransactionResultContent from "../../components/contents/TransctionResultContent";
import { Invoice, Plan, ResultPage, Subscription, DuePayment, SubscriptionOrder, TransactionHistory } from "../../types";
import { useNavigate } from "react-router-dom";
import TransactionListAndSearch from "../../components/layouts/TransactionListAndSearch";
import InvoiceListAndSearch from "../../components/layouts/InvoiceListAndSearch";
import { SubscriptionPlan } from "../../constants";
import DuePaymentCard from "../../components/cards/DuePaymentCard";
import MyCreditCard from "../../components/cards/MyCreditCard";
import MyPlanCard from "../../components/cards/MyPlanCard";
import { getInvoices, getInvoicesByParams } from "../../api/billing";
import { getTransactions } from "../../api/sanctions";
import { getSanctionReports, getSubscriptionDue, getSubsription, getSubsriptionPlan } from "../../api/subscription";
import PremiumPlanDialog from "../../components/dialogs/PremiumPlanDialog";
import OrderSummaryDialog from "../../components/dialogs/OrderSummaryDialog";
import SubscriptionUpgradeDialog from "../../components/dialogs/SubscriptionUpgradeDialog";
import LoadingPlaceHolder from "../../components/elements/LoadingPlaceHolder";


const MyPlanPage = () => {
    const navigate = useNavigate()

    const premiumPlanMountlyPrice = 200
    const [premiumPlan, setPremiumPlan] = useState<Plan>({ name: 'premium' } as Plan)

    const [tabValue, setTabValue] = useState(0);

    const [subscription, setSubscription] = useState<Subscription>()
    const [invoiceResult, setInvoiceResult] = useState<ResultPage<Invoice>>()
    const [transactionResult, setTransactionResult] = useState<ResultPage<TransactionHistory>>()

    const [plan, setPlan] = useState<Plan>({ name: SubscriptionPlan.PREE } as Plan)

    const [premiumPlanOpen, setPremiumPlanOpen] = useState(false)
    const [summaryDialogOpen, setSummaryDialogOpen] = useState(false)
    const [subscriptionUpgradeOpen, setSubscriptionUpgradeOpen] = useState(false)

    const [price, setPrice] = useState(0)
    const [premiumPlanPrice, setPremiummPlanPrice] = useState(premiumPlanMountlyPrice)
    const [premiumType, setPremiumType] = useState('monthly')
    const [discount, setDiscount] = useState(0)

    const [subscriptionOrder, setSubscriptionOrder] = useState<SubscriptionOrder>({ uid: '', plan: premiumPlan, price: premiumPlanPrice, discount: 0, total: premiumPlanPrice })

    const [subscriptionId, setSubscriptionId] = useState('')
    const [duePayment, setDuePayment] = useState<DuePayment>()

    useEffect(() => {
        getSubsriptionPlan()
            .then((res) => {
                console.log('subscription plans')
                const data = res.data.data
                const premium = getSubscriptionPlan(data, SubscriptionPlan.PREMIUM)

                setPremiumPlan(premium)
                // initial plan
                setSubscriptionOrder({
                    uid: subscription?.userId,
                    plan: premium,
                    planId: premium?.id,
                    price: premiumPlanPrice,
                    discount: discount,
                    total: premiumPlanPrice - discount
                })
            })
    }, [])

    useEffect(() => {
        getSubsription()
            .then((res) => {
                setSubscription(res.data.data)
                getInvoices()
                    .then((res) => {
                        console.log(res.data.data)
                        setInvoiceResult(res.data.data)
                    })

                getSanctionReports()
                    .then((res) => {
                        console.log(res.data.data)
                        setTransactionResult(res.data.data)
                    })
            })
            .catch((err) => {
                console.log(err)
                navigate("/pricing")
            })
    }, [])

    useEffect(() => {
        if (subscription?.plan.name === 'premium') {
            getSubscriptionDue(subscription.id)
                .then((res) => setDuePayment(res.data.data))
        }

    }, [subscription])


    useEffect(() => {
        if (subscription !== undefined) {
            setPlan(subscription?.plan)
            setSubscriptionId(subscription.id)
        }
    }, [subscription])


    const getSubscriptionPlan = (plans: Plan[], planName: SubscriptionPlan) => {
        let pp = plans.filter((plan) => plan.name === planName);
        return (pp.length > 0) ? pp[0] : {} as Plan;
    }

    const handleTabChange = (e: React.SyntheticEvent, value: number) => {
        setTabValue(value)
    }

    const handleInvoiceSearch = (keyword: string, page: number, limit: number) => {
        getInvoicesByParams(keyword, page, limit)
            .then((res) => {
                setInvoiceResult(res.data.data)
            })
    }

    const handleTransactionSearch = () => {
        getTransactions()
            .then((res) => {
                console.log(res.data.data)
                setTransactionResult(res.data.Data)
            })
    }

    const handleUpdateSubscription = () => {
        getSubsription()
            .then((res) => {
                setSubscription(res.data.data)
            })
    }

    const handleUpgradeSubscription = () => {
        setPremiumPlanOpen(true)
    }

    const handleUpgradeSubscriptionClose = (success: boolean) => {
        setPremiumPlanOpen(false)
        if (success) {
            setSummaryDialogOpen(true)
        }
    }

    const handleSummaryDialogClose = (success: boolean) => {
        setSummaryDialogOpen(false)
        if (success) {
            setSubscriptionUpgradeOpen(true)
        }
    }

    const handleSubscriptionUpgradeDialogClose = (success: boolean) => {
        setSubscriptionUpgradeOpen(false)
        if (success) {
            navigate(`/payment?id=${subscriptionId}&status=pending`)
        }
    }

    if (subscription === undefined) {
        return (
            <MainLayout>
                <LoadingPlaceHolder />
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <PremiumPlanDialog open={premiumPlanOpen} onClose={handleUpgradeSubscriptionClose} />
            <OrderSummaryDialog order={subscriptionOrder} premiumType={premiumType} premiumPlanPrice={premiumPlanMountlyPrice} upgradeFlag={true} setDiscount={setDiscount} open={summaryDialogOpen} onClose={handleSummaryDialogClose} />
            <SubscriptionUpgradeDialog subscriptionId={subscriptionId} subscriptionOrder={subscriptionOrder} setSubscriptionId={setSubscriptionId} open={subscriptionUpgradeOpen} onClose={handleSubscriptionUpgradeDialogClose} />
            <ContentWrapper>
                <Stack>
                    <Grid container spacing={4}>
                        <Grid item xs={4.3}>
                            <DuePaymentCard plan={plan} subscription={subscription} duePayment={duePayment}/>
                        </Grid>
                        <Grid item xs={4.5}>
                            <MyCreditCard
                                subscription={subscription}
                                setSubscription={setSubscription} />
                        </Grid>
                        <Grid item xs={3.2}>
                            <MyPlanCard subscription={subscription} setSubsription={setSubscription} upgradeSubscriptionPlan={handleUpgradeSubscription} />
                        </Grid>
                    </Grid>
                    <Box mt={5}>
                        <StyledTabs value={tabValue} onChange={handleTabChange}>
                            <StyledTab value={0} label="Invoice" sx={{ width: '120px' }} />
                            <StyledTab value={1} label="Historical Processed Transactions" sx={{ width: '350px' }} />
                        </StyledTabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <InvoiceListAndSearch
                            result={invoiceResult}
                            submitSearch={handleInvoiceSearch} >
                            {invoiceResult !== undefined && invoiceResult.results.length > 0 &&
                                <InvoiceResultContent result={invoiceResult} />
                            }
                        </InvoiceListAndSearch>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <TransactionListAndSearch
                            plan={plan}
                            result={transactionResult}
                            submitSearch={handleTransactionSearch}
                            upgradePremiumPlan={handleUpgradeSubscription} >
                            {transactionResult !== undefined && transactionResult.results.length > 0 &&
                                <TransactionResultContent result={transactionResult} />
                            }
                        </TransactionListAndSearch>
                    </TabPanel>
                </Stack>
            </ContentWrapper>
        </MainLayout>
    )
}

export default MyPlanPage