import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import WaitingProgress from "../../elements/WaitingProgress";
import { DialogProps } from "../_common";
import { useEffect, useState } from "react";
import { SubscriptionOrder } from "../../../types";
import { patchSubscription, postSubscription } from "../../../api/subscription";
import { cleanToken } from "../../../api/oauth2";


interface Props {
    subscriptionId: string
    subscriptionOrder: SubscriptionOrder
    setSubscriptionId: (id: string) => void
    open: boolean
    onClose: (success: boolean) => void
}

const SubscriptionUpgradeDialog = (props: Props) => {
    const { subscriptionId, subscriptionOrder, setSubscriptionId, open, onClose } = props

    const [waiting, setWaiting] = useState(false)

    useEffect(() => {
        if (open) {
            setWaiting(true)
            //onClose(true)
            patchSubscription(subscriptionId, subscriptionOrder)
                .then((res) => {
                    cleanToken()
                    if (res.data.status === 200) {
                        setWaiting(false)
                        window.location.replace(res.data.data.paymentUrl)
                    }
                })
            // postSubscription(subscriptionOrder)
            //     .then((res) => {
            //         cleanToken()
            //         if (res.data.status === 200) {
            //             setWaiting(false)
            //             setSubscriptionId(res.data.data.id)
            //             onClose(true)
            //             //setSetPasswordSuccessDialogOpen(true)
            //             //setSubscriptionId(res.data.data.id)
            //             //setSetPasswordDialogOpen(false)
            //         }
            //     })
            //     .catch((err) => {
            //         console.error(err)
            //     })
        }
    }, [open])

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5}>
                <Box sx={{ width: '100%', overflowY: 'scroll', boxSizing: 'content-box' }}>
                    <WaitingProgress open={waiting} onClose={() => onClose(true)} />
                    <DialogContent sx={{ m: 0, p: 0 }}>
                        <Stack spacing={2}>
                            <Typography fontSize={32} fontWeight={700}>
                                Upgrade Subscription
                            </Typography>
                            <Typography fontSize={18} fontWeight={500}>
                                We are upgrading subscription for you.
                            </Typography>
                        </Stack>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )
}

export default SubscriptionUpgradeDialog