import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";

export const StyledSmallToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '&.MuiToggleButtonGroup-root': {
        display: 'flex',
        padding: '2px',
        border: '1px solid #FACB57',
        borderRadius: '25px',
        backgroundColor: '#FFFFFF',
    },
}))

export const StyledSmallToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: '25px',
    '&.MuiToggleButton-root': {
        width: '92px',
        border: 'none',
        fontSize: '14px',
        color: '#000',
        lineHeight: '21px',
        padding: '3px',
    },
    '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        borderRadius: '25px',
        marginRight: theme.spacing(0.1),
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderRadius: '25px',
        borderBottomLeftRadius: '25px',
        marginLeft: theme.spacing(0.1),
    },
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    }
}))