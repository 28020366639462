import { MenuItem, styled } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    paddingTop: "13px",
    paddingBottom: "13px",
    '&:hover': {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
        svg: {
            path: {
                stroke: theme.palette.background.paper
            }
        }
    }
}))