import axios, { AxiosResponse } from "axios"
import { error } from "console";
import { Cookies } from "react-cookie";

const OAUTH_TOKEN = 'secure_token'
const cookies = new Cookies(OAUTH_TOKEN, { path: '/' })

const baseUrlUsers = `${process.env.REACT_APP_API_BASE_URL}/v1/users`

const userGetMyInfo = (): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlUsers}/me`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch((err) => {
            if (err.response.status === 401) {
                cookies.remove(OAUTH_TOKEN)
            }
            return err
        })
}

const userPostCreateUser = (email: string): Promise<AxiosResponse> => {
    return axios.post(baseUrlUsers, {
        'email': email,
    })
        .catch((err) => {
            console.error(err)
            throw err
        })
}

const userPostForgetPassword = (email: string): Promise<AxiosResponse> => {
    return axios.post(baseUrlUsers + "/forget-password", {
        'email': email,
    }).catch((err) => {
        console.error(err)
        throw err
    })
}

const userPostResetPassword = (email: string, password: string, otpCode: string): Promise<AxiosResponse> => {
    return axios.post(`${baseUrlUsers}/reset-password`, {
        username: email,
        password: password,
        otp: otpCode,
    }).catch((err) => {
        console.log(err)
        throw err
    })
}

const userPatchPassword = (password: string): Promise<AxiosResponse> => {
    return axios.patch(baseUrlUsers + "/password", {
        'password': password,
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch((err) => {
            console.error(err)
            throw err
        })
}

const userGetUserProfileImage = (): Promise<AxiosResponse> => {
    return axios.get(`${baseUrlUsers}/me/profile-image`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'multipart/form-data'
        }
    })
        .catch((err) => {
            console.error(err)
            return err
        })
}

const userPatchProfileImage = (formData: FormData): Promise<AxiosResponse> => {
    return axios.patch(`${baseUrlUsers}/me/profile-image`, formData, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'multipart/form-data'
        }
    })
        .catch((err) => {
            console.error(err)
            return err
        })
}

const userDeleteProfileImage = (): Promise<AxiosResponse> => {
    return axios.delete(`${baseUrlUsers}/me/profile-image`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch((err) => {
            console.error(err)
            return err
        })
}

export {
    userGetMyInfo,
    userPostCreateUser,
    userPostForgetPassword,
    userPostResetPassword,
    userPatchPassword,
    userGetUserProfileImage,
    userPatchProfileImage,
    userDeleteProfileImage
}
