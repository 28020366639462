import React from "react";
import { StyledSubmitButton } from "./styles";
import { Box } from "@mui/material";

interface Props {
    children: any
}

const UserSubmitButton: React.FC<Props> = ({ children }) => {
    return (
        <Box>
            <StyledSubmitButton variant="contained" color="secondary"
                disableElevation fullWidth
                type="submit"
            >
                {children}
            </StyledSubmitButton>
        </Box>
    )
}

export default UserSubmitButton