import { Button, styled } from "@mui/material";

export const StyledSignInGoogleButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: "4px 1px",
    height: '50px',
    display: 'block',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 25
}))