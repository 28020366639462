import { Table, TableHead, TableBody } from "@mui/material";
import { ResultPage, UnEntity } from "../../../types"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "../_common/styles";
import { EntityName } from "typescript";
import { dataOf, rowClassOf } from "../_common";
import { Link } from "react-router-dom";

interface Props {
    result: ResultPage<UnEntity> | undefined
}

interface ContentRowProps {
    item: UnEntity
    rowNumber: number
    firstElement: number
}

const ContentRow = (props: ContentRowProps) => {
    const { item, rowNumber, firstElement } = props

    const rowNumberOf = (index: number, number: number) => {
        return (index === 0) ? number + firstElement : ''
    }

    const rowCount = item.aliases.length

    const dataIdOf = (index: number, item: UnEntity) => {
        return (index === 0) ? item.dataId : ''
    }

    const nameOf = (index: number, item: UnEntity) => {
        return (index === 0) ? item.name : ''
    }

    const aliasQualityOf = (index: number, item: UnEntity) => {
        return (item.aliases.length > index) ? dataOf(item.aliases[index].quality) : ''
    }

    const individualAliasOf = (index: number, item: UnEntity) => {
        return (item.aliases.length > index) ? dataOf(item.aliases[index].individualAlias) : ''
    }

    const viewOf = (index: number, item: UnEntity) => {
        return (index === 0) ? <Link to={`/sanctions/united-nation/entities/${item.id}`}>View</Link> : ''
    }

    return (
        <>
            {[...Array(rowCount)].map((_x, i) => (
                <StyledTableRow className={rowClassOf(i, rowCount, rowNumber)}>
                    <StyledTableCell align="center">{rowNumberOf(i, rowNumber)}</StyledTableCell>
                    <StyledTableCell>{dataIdOf(i, item)}</StyledTableCell>
                    <StyledTableCell>{nameOf(i, item)}</StyledTableCell>
                    {(item.aliases.length) > 0 && (
                        <>
                            <StyledTableCell>{aliasQualityOf(i, item)}</StyledTableCell>
                            <StyledTableCell>{individualAliasOf(i, item)}</StyledTableCell>
                        </>
                    )}
                    <StyledTableCell align="center">{viewOf(i, item)}</StyledTableCell>

                </StyledTableRow>
            ))}
        </>
    )
}

const UnEntityResultContent = (props: Props) => {
    const { result } = props;

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell rowSpan={2} width={'6%'} align="center">No</StyledTableCell>
                        <StyledTableCell rowSpan={2} width={'8%'}>Data ID</StyledTableCell>
                        <StyledTableCell rowSpan={2} width={'20%'}>Name</StyledTableCell>
                        <StyledTableCell colSpan={2} width={'20%'}>Alias</StyledTableCell>
                        <StyledTableCell rowSpan={2} width={'6%'}></StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell>Quality</StyledTableCell>
                        <StyledTableCell>Alias</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {(result?.results.map((item, index) => (
                        <ContentRow firstElement={result?.firstElement} item={item} rowNumber={index} />
                    )))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default UnEntityResultContent