import { StyledJoinNowButton } from "./styles";

interface JoinNowButtonProps {
    disabled?: boolean
    onClick: () => void
}

const JoinNowButton = (props: JoinNowButtonProps) => {
    const { disabled, onClick } = props
    return (
        <StyledJoinNowButton variant="contained" color="secondary"
            disableElevation fullWidth
            onClick={onClick} disabled={disabled}>
            Join Now
        </StyledJoinNowButton>
    )
}

export default JoinNowButton