import { ChangeEvent,FocusEvent, useEffect, useState } from "react";
import { Box, FormGroup, FormHelperText, Stack, Typography } from "@mui/material"
import { StyledTextField } from "./styles"
import ErrorAlertIcon from "../../icons/ErrorAlertIcon";
import FormValidateError from "../FormValidateError";


interface Props {
    error: string,
    resetError: () => void
    setValue: (value: string) => void
}

const VerifyCodeInput = (props: Props) => {
    const { error, resetError, setValue } = props;

    const defaultValues = ['', '', '', '', '', '']

    const [offset, setOffset] = useState(0)
    const [values, setValues] = useState(defaultValues);

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        var newValues = values;
        // if(error !== '') {
        //     console.log('xxx')
        //     newValues = defaultValues
        // }
        newValues[+e.target.name] = e.target.value
        setValues(newValues)
        setValue(values.join(""))
        setOffset((+e.target.name) + 1)
    }

    const handleInputFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        if(error != '') {
            resetError()
            setValues(defaultValues)
        }
        e.target.select()
    }

    return (
        <FormGroup>
            <Box pb={1}>
                <Stack direction={'row'} spacing={2}>
                    {values.map((_val, idx) => {
                        return (
                            <StyledTextField
                                id={idx.toString()}
                                name={idx.toString()}
                                value={values[idx]}
                                inputProps={{ maxLength: 1 }}
                                onFocus={handleInputFocus}
                                onChange={handleTextChange}
                                error={error !== ''}
                                inputRef={(input) => {
                                    if (input !== null && (+input.id) === offset) {
                                        input.focus()
                                    }
                                }}
                            />
                        )
                    })}
                </Stack>
            </Box>
            <FormValidateError error={error} />
        </FormGroup>
    )
}

export default VerifyCodeInput