import axios, { AxiosResponse } from "axios";
import { ApiResponse, PromoCodeResult, SubscriptionOrder } from "../types";
import { Cookies } from "react-cookie";

const cookies = new Cookies("secure_token", { path: '/' })

const baseSubscriptionUrl = `${process.env.REACT_APP_API_BASE_URL}/v1/subscription`


const getSubsriptionPlan = (): Promise<AxiosResponse<ApiResponse<any>>> => {
    return axios.get(`${baseSubscriptionUrl}/plans`)
}

const applyPromoCode = (code: string): Promise<AxiosResponse<ApiResponse<PromoCodeResult>, any>> => {
    return axios.post(`${baseSubscriptionUrl}/promo-codes/apply`, {
        'code': code
    })
}

const postOrder = (order: SubscriptionOrder): Promise<ApiResponse<any>> => {
    return axios.post(`${baseSubscriptionUrl}/orders`, {
        'userId': order.uid,
        'plan': order.plan,
        'price': order.price,
        'discount': order.discount,
        'total': order.total,
    })
}

const postSubscription = (subscription: SubscriptionOrder): Promise<ApiResponse<any>> => {
    console.log(subscription)
    return axios.post(`${baseSubscriptionUrl}/subscriptions`, {
        'userId': subscription.uid,
        'planId': subscription.plan?.id,
        'plan': subscription.plan,
        'price': subscription.price,
        'discount': subscription.discount,
        'total': subscription.total,
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const patchSubscription = (subscriptionId: string, subscription: SubscriptionOrder): Promise<ApiResponse<any>> => {
    console.log(subscription)
    return axios.patch(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}`, {
        'userId': subscription.uid,
        'planId': subscription.plan?.id,
        'plan': subscription.plan,
        'price': subscription.price,
        'discount': subscription.discount,
        'total': subscription.total,
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const getSubsription = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseSubscriptionUrl}/subscriptions`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const getSubscriptionDue = (subscriptionId: string): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/payment-due`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const postSubscriptionDue = (subscriptionId: string, subscription: SubscriptionOrder): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/payment-due`, {
        'userId': subscription.uid,
        'planId': subscription.plan?.id,
        'plan': subscription.plan,
        'price': subscription.price,
        'discount': subscription.discount,
        'total': subscription.total,
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const unsubscribe = (subscriptionId: string): Promise<AxiosResponse<any>> => {
    return axios.delete(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const topupCredit = (subscriptionId: string, amount: number): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/topup`, {
        'amount': amount
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const redeemToken = (subscriptionId: string, code: string): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/token`, {
        'code': code
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}


// const getCredits = (subscriptionId: string): Promise<AxiosResponse<any>> => {
//     return axios.get(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/credit`, {
//         headers: {
//             'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
//             'Content-Type': 'application/json'
//         }
//     })
// }

// const postCredit = (subscriptionId: string, topUpAmount: number): Promise<AxiosResponse<any>> => {
//     return axios.post(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/credit`, {
//         'amount': topUpAmount
//     }, {
//         headers: {
//             'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
//             'Content-Type': 'application/json'
//         }
//     })
// }

const getApiKey = (subscriptionId: string): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/api-key`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const postApiKey = (subscriptionId: string): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseSubscriptionUrl}/subscriptions/${subscriptionId}/token`, {}, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const getSanctionReports = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseSubscriptionUrl}/sanctions/reports`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const getSanctionHitsReport = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseSubscriptionUrl}/sanctions/reports?type=hits`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const getSanctionHistoricalReport = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseSubscriptionUrl}/sanctions/reports?type=historical`, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const postSanctionHitsMonthlyReport = (year: string, month: string): Promise<AxiosResponse> => {
    return axios.post(`${baseSubscriptionUrl}/sanctions/reports/monthly`, {
        'type': 'hits',
        'year': year,
        'month': month
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    })
}

const postSanctionHistoricalMonthlyReport = (year: string, month: string): Promise<AxiosResponse> => {
    return axios.post(`${baseSubscriptionUrl}/sanctions/reports/monthly`, {
        'type': 'historical',
        'year': year,
        'month': month
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    })
}

const postSanctionHitsYearlyReport = (year: string): Promise<AxiosResponse> => {
    return axios.post(`${baseSubscriptionUrl}/sanctions/reports/yearly`, {
        'type': 'hits',
        'year': year
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    })
}

const postSanctionHistoricalYearlyReport = (year: string): Promise<AxiosResponse> => {
    return axios.post(`${baseSubscriptionUrl}/sanctions/reports/yearly`, {
        'type': 'historical',
        'year': year
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    })
}

export {
    getSubsriptionPlan,
    getSubsription,
    getSubscriptionDue,
    postSubscriptionDue,
    postSubscription,
    patchSubscription,
    unsubscribe,
    applyPromoCode,
    postOrder,
    topupCredit,
    redeemToken,
    getApiKey,
    postApiKey,
    getSanctionReports,
    getSanctionHitsReport,
    getSanctionHistoricalReport,
    postSanctionHitsMonthlyReport,
    postSanctionHistoricalMonthlyReport,
    postSanctionHitsYearlyReport,
    postSanctionHistoricalYearlyReport
}