import { createTheme } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const AppTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1340,
            xl: 1440,
        }
    },
    typography: {
        fontFamily: [
            "Roboto",
            'Arial',
        ].join(","),
        button: {
            textTransform: "none"
        }
    },
    palette: {
        primary: {
            main: "#0C4992"
        },
        secondary: {
            main: "#FACB57",
            contrastText: '#0C4992',
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: '1440px'
            }
        }
    }
})

export default AppTheme;