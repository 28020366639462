import { useEffect } from "react"
import { Box, Stack, Table, TableBody, TableHead } from "@mui/material"
import { HeaderLabel } from "./styles"
import { ResultPage, UnIndividual } from "../../../types"
import IndividualDocumentFilter from "../../elements/IndividualDocumentFilter"
import { Link, useSearchParams } from "react-router-dom"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "../_common/styles"
import { dataOf, rowClassOf } from "../_common"

interface Props {
    filterByType: (type: string) => void
    result: ResultPage<UnIndividual> | undefined
}

interface ContentRowProps {
    item: UnIndividual
    rowNumber: number
    firstElement: number
}

const ContentRow = (props: ContentRowProps) => {
    const { item, rowNumber, firstElement } = props

    const maxRow = () => {
        const documentRow = item.document.length
        const aliasRow = item.aliases.length

        return Math.max(1, documentRow, aliasRow)
    }

    const rowCount = maxRow()

    const rowNumberOf = (index: number, number: number) => {
        return (index === 0) ? number + firstElement : ''
    }

    const dataIdOf = (index: number, item: UnIndividual) => {
        return (index === 0) ? item.dataId : ''
    }

    const birthTypeOf = (index: number, item: UnIndividual) => {
        return (index === 0) ? dataOf(item.dateOfBirth.typeOfDate) : ''
    }

    const birthDateOf = (index: number, item: UnIndividual) => {
        return (index === 0) ? dataOf(item.dateOfBirth.date) : ''
    }

    const nameOf = (index: number, item: UnIndividual) => {
        return (index === 0) ? item.name : ''
    }

    const documentTypeOf = (index: number, item: UnIndividual) => {
        return (item.document.length > index) ? item.document[index].typeOfDocument : ''
    }

    const documentNumberOf = (index: number, item: UnIndividual) => {
        return (item.document.length > index) ? item.document[index].number : ''
    }

    const aliasQualityOf = (index: number, item: UnIndividual) => {
        return (item.aliases.length > index) ? item.aliases[index].quality : ''
    }

    const aliasAliasOf = (index: number, item: UnIndividual) => {
        return (item.aliases.length > index) ? item.aliases[index].individualAlias : ''
    }

    const viewOf = (index: number, item: UnIndividual) => {
        return (index === 0) ? <Link to={`/sanctions/united-nation/individuals/${item.id}`}>View</Link> : ''
    }

    return (
        <>
            {[...Array(rowCount)].map((_x, i) => (
                <StyledTableRow className={rowClassOf(i, rowCount, rowNumber)}>
                    <StyledTableCell align="center">{rowNumberOf(i, rowNumber)}</StyledTableCell>
                    <StyledTableCell>{dataIdOf(i, item)}</StyledTableCell>
                    <StyledTableCell>{nameOf(i, item)}</StyledTableCell>
                    {(item.dateOfBirth !== null) && (
                        <>
                            <StyledTableCell>{birthTypeOf(i, item)}</StyledTableCell>
                            <StyledTableCell>{birthDateOf(i, item)}</StyledTableCell>
                        </>
                    )}
                    {(item.dateOfBirth === null) && (
                        <>
                            <StyledTableCell>-</StyledTableCell>
                            <StyledTableCell>-</StyledTableCell>
                        </>
                    )}
                    <StyledTableCell>{documentTypeOf(i, item)}</StyledTableCell>
                    <StyledTableCell>{documentNumberOf(i, item)}</StyledTableCell>
                    <StyledTableCell>{aliasQualityOf(i, item)}</StyledTableCell>
                    <StyledTableCell>{aliasAliasOf(i, item)}</StyledTableCell>
                    <StyledTableCell align="center">{viewOf(i, item)}</StyledTableCell>

                </StyledTableRow>
            ))}
        </>
    )
}

const UnIndividualResultContent = (props: Props) => {
    const { filterByType, result } = props;

    const [searchParam] = useSearchParams()

    useEffect(() => {
        const type = searchParam.get('type')
        const quality = searchParam.get('quality')
        console.log(`type: ${type}, quality: ${quality}`)
    }, [searchParam])

    const dataOf = (value: string): string => {
        return value != null ? value : '-'
    }

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell rowSpan={2} width={'6%'} align="center">No</StyledTableCell>
                        <StyledTableCell rowSpan={2} width={'8%'}>Data ID</StyledTableCell>
                        <StyledTableCell rowSpan={2}>Name</StyledTableCell>
                        <StyledTableCell colSpan={2} width={'15%'}>Date Of Birth</StyledTableCell>
                        <StyledTableCell colSpan={2} width={'20%'}>Individual Document</StyledTableCell>
                        <StyledTableCell colSpan={2} width={'18%'}>Alias</StyledTableCell>
                        <StyledTableCell rowSpan={2} width={'6%'}></StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>
                            <Box alignContent='center'>
                                Date
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Stack direction='row' spacing={1}>
                                <HeaderLabel>Type</HeaderLabel>
                                <IndividualDocumentFilter filterChange={filterByType} />
                            </Stack>
                        </StyledTableCell>
                        <StyledTableCell>Number</StyledTableCell>
                        <StyledTableCell>
                            <Stack direction='row' spacing={1}>
                                <HeaderLabel>Quality</HeaderLabel>
                            </Stack>
                        </StyledTableCell>
                        <StyledTableCell>Alias</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {(result?.results.map((item, index) => (
                        <ContentRow firstElement={result?.firstElement} item={item} rowNumber={index} />
                    )))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default UnIndividualResultContent