import { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import ContentWrapper from "../../components/elements/ContentWrapper";
import { Box, Grid, Stack } from "@mui/material";
import { StyledBox, StyledTitle, StyledValue } from "./styles";
import SanctionDetailCard from "../../components/cards/SanctionDetailCard";
import { useParams } from "react-router-dom";
import { getUnIndividualSanctionDetail } from "../../api/sanctions";
import { UnIndividualDetail } from "../../types";
import LoadingPlaceHolder from "../../components/elements/LoadingPlaceHolder";

const UnIndividualDetailPage = () => {
    const { id } = useParams()

    console.log(id)

    const [value, setValue] = useState<UnIndividualDetail>();

    useEffect(() => {
        if (id !== undefined) {
            getUnIndividualSanctionDetail(id)
                .then((res) => {
                    console.log(res.data.data)
                    setValue(res.data.data)
                })
        }
    }, [id])

    if (value === undefined) {
        return (
            <MainLayout>
                <LoadingPlaceHolder />
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <ContentWrapper>
                <Stack spacing={5}>
                    <SanctionDetailCard title="i. Individual Particulars">
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Data ID</StyledTitle>
                                    <StyledValue>{value?.dataId}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Name</StyledTitle>
                                    <StyledValue>{value?.name}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Name in original script</StyledTitle>
                                    <StyledValue>{value?.nameOriginalScript}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Nationality</StyledTitle>
                                    <StyledValue>{value?.nationality}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Place of Birth</StyledTitle>
                                    {((value?.placeOfBirth !== null) && (
                                        <>
                                            <Stack spacing={1}>
                                                <Stack direction={'row'}>
                                                    <Box width={'30%'}>
                                                        <StyledValue>State/Province</StyledValue>
                                                    </Box>
                                                    <Box width={'70%'}>
                                                        <StyledValue>: {value?.placeOfBirth?.stateProvince}</StyledValue>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                            <Stack spacing={1}>
                                                <Stack direction={'row'}>
                                                    <Box width={'30%'}>
                                                        <StyledValue>Country</StyledValue>
                                                    </Box>
                                                    <Box width={'70%'}>
                                                        <StyledValue>: {value?.placeOfBirth?.country}</StyledValue>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </>
                                    ))}
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Date of Birth</StyledTitle>
                                    <Stack spacing={1}>
                                        <Stack direction={'row'}>
                                            <Box width={'30%'}>
                                                <StyledValue>Type of Date</StyledValue>
                                            </Box>
                                            <Box width={'70%'}>
                                                <StyledValue>: {value?.dateOfBirth.typeOfDate}</StyledValue>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <Stack direction={'row'}>
                                            <Box width={'30%'}>
                                                <StyledValue>Date</StyledValue>
                                            </Box>
                                            <Box width={'70%'}>
                                                <StyledValue>: {value?.dateOfBirth.date}</StyledValue>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </StyledBox>
                        </Stack>
                    </SanctionDetailCard>
                    <SanctionDetailCard title="ii. Details">
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Individual Documents</StyledTitle>
                                    <StyledValue></StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Address</StyledTitle>
                                    {value?.addresses.map((v, i) => (
                                        <StyledValue>{v.country}</StyledValue>
                                    ))}

                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Title</StyledTitle>
                                    <StyledValue>{value?.title}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>List Type</StyledTitle>
                                    <StyledValue>{value?.unListType}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Listed On</StyledTitle>
                                    <StyledValue>{value?.listedOn}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Last Updated Day</StyledTitle>
                                    <StyledValue>{value?.lastDayUpdated}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Reference Number</StyledTitle>
                                    <StyledValue>{value?.referenceNumber}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Designation</StyledTitle>
                                    <StyledValue>{value?.designation}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <Box>
                                <Stack spacing={1}>
                                    <StyledTitle>Comments</StyledTitle>
                                    <StyledValue>{value?.comments1}</StyledValue>
                                </Stack>
                            </Box>
                        </Stack>
                    </SanctionDetailCard>
                    <SanctionDetailCard title="iii. Alias">
                        <StyledTitle>Individual Alias</StyledTitle>
                        <Grid container sx={{ paddingLeft: 0 }}>
                            {value?.aliases.map((v, i) => (
                                <Grid item xs={6} key={i} sx={{ paddingLeft: '0px' }}>
                                    <Box>
                                        <Grid container alignItems={'flex-start'} sx={{ paddingBottom: 2 }}>
                                            <Grid item sm={0.5}>{i + 1}.</Grid>
                                            <Grid item sm={11.5}>
                                                <Box>
                                                    <Grid container >
                                                        <Grid item sm={4}>Alias</Grid>
                                                        <Grid item sm={0.5}>:</Grid>
                                                        <Grid item sm={7.5}>{v.individualAlias}</Grid>
                                                    </Grid>
                                                    <Grid container >
                                                        <Grid item sm={4}>Quality</Grid>
                                                        <Grid item sm={0.5}>:</Grid>
                                                        <Grid item sm={7.5}>{v.quality}</Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </SanctionDetailCard>
                </Stack>
            </ContentWrapper>
        </MainLayout>
    )
}

export default UnIndividualDetailPage