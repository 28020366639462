import axios, { AxiosResponse } from "axios"
import { Cookies } from "react-cookie"

const cookies = new Cookies("secure_token", { path: '/' })

const baseBillingUrl = `${process.env.REACT_APP_API_BASE_URL}/v1/billing`

const getInvoices = (): Promise<AxiosResponse> => {
    return axios.get(`${baseBillingUrl}/invoices`,
        {
            headers: {
                'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
                'Content-Type': 'application/json'
            }
        })
}

const getInvoicesByParams = (keyword: string, page: number, limit: number): Promise<AxiosResponse> => {
    return axios.get(`${baseBillingUrl}/invoices?keyword=${keyword}&page=${page}&limit=${limit}`,
        {
            headers: {
                'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
                'Content-Type': 'application/json'
            }
        })
}

const getInvoice = (id: string): Promise<AxiosResponse> => {
    return axios.get(`${baseBillingUrl}/invoices/${id}`,
        {
            headers: {
                'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
                'Content-Type': 'application/json'
            }
        })
}

const getPayment = (paymentId: string): Promise<AxiosResponse> => {
    return axios.get(`${baseBillingUrl}/payments?subscriptionId=${paymentId}`)
}

const patchPaymentStatus = (paymentId: string, status: string): Promise<AxiosResponse> => {
    return axios.patch(`${baseBillingUrl}/payments/${paymentId}?status=${status}`)
}

const patchInvoiceStatus = (invoiceId: string, status: string): Promise<AxiosResponse> => {
    return axios.patch(`${baseBillingUrl}/invoices/${invoiceId}`, {
        'status': status
    }, {
        headers: {
            'Authorization': 'Bearer ' + cookies.get("secure_token").access_token,
            'Content-Type': 'application/json'
        }
    })
}

const postInvoiceDocument = (invoiceId: string): Promise<AxiosResponse> => {
    return axios.post(`${baseBillingUrl}/invoices/${invoiceId}`, {}, { responseType: 'blob' })
}

export {
    getInvoices,
    getInvoicesByParams,
    getInvoice,
    getPayment,
    patchPaymentStatus,
    patchInvoiceStatus,
    postInvoiceDocument
}