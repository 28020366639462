import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";


interface Props {
    open: boolean
    onClose: (success: boolean) => void
}

const SetPasswordSuccessDialog = (props: Props) => {
    const { open, onClose } = props

    const handleEmailConfirmClick = () => {
        onClose(true)
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5}>
                <Box sx={{ width: '100%', overflowY: 'scroll', boxSizing: 'content-box' }}>
                    <DialogContent sx={{ m: 0, p: 0 }}>
                        <Stack spacing={2}>
                            <Typography fontSize={32} fontWeight={700}>
                                Password Set!
                            </Typography>
                            <Typography fontSize={18} fontWeight={500}>
                                You may now proceed to payment.
                            </Typography>
                            <Box py={1}></Box>
                            <StyledSubmitButton variant="contained" color="secondary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleEmailConfirmClick}
                            >
                                Proceed to Payment
                            </StyledSubmitButton>
                        </Stack>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )

}

export default SetPasswordSuccessDialog