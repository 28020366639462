import React, { useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import ContentWrapper from "../../components/elements/ContentWrapper";
import { Alert, Backdrop, Box, Paper, Snackbar, Stack } from "@mui/material";
import { StyledHeader, StyledContent, StyledTitle } from "./styles";
import PhoneIcon from "../../components/icons/PhoneIcon";
import MailIcon from "../../components/icons/MailIcon";
import LocationIcon from "../../components/icons/LocationIcon";
import UserSubmitButton from "../../components/elements/UserSubmitButton";
import PhoneNumberInputWithLabel from "../../components/elements/PhoneNumberInputWithLabel";
import TextAreaInputWithLabel from "../../components/elements/TextAreaInputWithLabel";
import EnquiryTypeInputWithLabel from "../../components/elements/EnquiryTypeInputWithLabel";
import TextInputWithLabel from "../../components/elements/TextInputWithLabel";
import { postContactUs } from "../../api/contact";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ContactUsPage = () => {
    const [backdropOpen, setBackdropOpen] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    const [phoneNumber, setPhoneNumber] = useState('')

    const [inquiryType, setInquiryType] = useState('')
    const [message, setMessage] = useState('')

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [inquiryTypeError, setInquiryTypeError] = useState('')
    const [messageError, setMessageError] = useState('')

    const FIELD_REQUIRE_ERROR = 'This field is required.'

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (firstName !== '' && lastName !== '' && email !== '' && phoneNumber !== '' && inquiryType !== '' && message !== '') {
            setBackdropOpen(true)
            postContactUs(
                firstName,
                lastName,
                email,
                phoneNumber,
                inquiryType,
                message,
            )
            .then(() => {
                setAlertOpen(true)
            })
        }

        if (firstName === '') {
            setFirstNameError(FIELD_REQUIRE_ERROR)
        }
        if (lastName === '') {
            setLastNameError(FIELD_REQUIRE_ERROR)
        }
        if (email === '') {
            setEmailError(FIELD_REQUIRE_ERROR)
        }
        if (phoneNumber === '') {
            setPhoneNumberError(FIELD_REQUIRE_ERROR)
        }
        if (inquiryType === '') {
            setInquiryTypeError(FIELD_REQUIRE_ERROR)
        }
        if (message === '') {
            setMessageError(FIELD_REQUIRE_ERROR)
        }
    }

    const handleAlertClose = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhoneNumber('')
        setInquiryType('')
        setMessage('')
        setAlertOpen(false)
        setBackdropOpen(false)
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={1800}
                    onClose={handleAlertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{
                        width: "95%",
                        top: "80px !important",
                    }}
                >
                    <Alert
                        severity="info" variant="filled"
                        iconMapping={{
                            info: <CheckCircleOutlineIcon fontSize="inherit" />,
                        }}
                        sx={{ width: "100%", borderRadius: "40.5px" }}
                    >
                     <b>Your enquiry</b> has been successfully sent!
                    </Alert>
                </Snackbar>
            </Backdrop>
            <MainLayout>
                <ContentWrapper >
                    <Box sx={{ my: 3, display: 'flex', justifyContent: 'center' }}>
                        <Paper elevation={0}
                            sx={{
                                p: 0, borderRadius: '32px',
                                width: '1000px', height: '887px',
                                backgroundColor: '#FAFAFA',
                                display: 'flex',
                            }}>
                            <Box sx={{
                                width: "489px", height: "887px",
                                borderRadius: "32px 0 0 32px",
                                backgroundImage: `url(/img/contact-us-planceholder.png)`
                            }}>
                                <Box sx={{ p: 6, mr: 10 }}>
                                    <Stack direction={'column'} spacing={2} sx={{ color: '#FFF' }}>
                                        <StyledHeader>Contact Information</StyledHeader>
                                        <StyledTitle>
                                            Fill up the form and our team will get back to you within 24 hours.
                                        </StyledTitle>
                                        <Stack spacing={3} sx={{ pt: 5 }}>
                                            <Stack direction={'row'} spacing={1}>
                                                <PhoneIcon />
                                                <StyledContent>+60 12 738 9831</StyledContent>
                                            </Stack>
                                            <Stack direction={'row'} spacing={1}>
                                                <MailIcon />
                                                <StyledContent>support@altecflex.com</StyledContent>
                                            </Stack>
                                            <Stack direction={'row'} spacing={1}>
                                                <LocationIcon />
                                                <StyledContent>Puchong Financial Corporate Center</StyledContent>
                                            </Stack>

                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box component={'form'} sx={{ py: 3, px: 6, width: '40%' }} onSubmit={handleSubmit}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    <Stack>
                                        <TextInputWithLabel
                                            id="first-name-input"
                                            name="first-name"
                                            label="First Name"
                                            placeholder="John"
                                            value={firstName}
                                            setValue={setFirstName}
                                            error={firstNameError}
                                            clearError={() => setFirstNameError('')}
                                        />
                                        <TextInputWithLabel
                                            id="last-name-input"
                                            name="last-name"
                                            label="Last Name"
                                            placeholder="Doe"
                                            value={lastName}
                                            setValue={setLastName}
                                            error={lastNameError}
                                            clearError={() => setLastNameError('')}
                                        />
                                        <TextInputWithLabel
                                            id="email-name-input"
                                            name="email-name"
                                            label="Email"
                                            placeholder="john.dow@gmail.com"
                                            value={email}
                                            setValue={setEmail}
                                            error={emailError}
                                            clearError={() => setEmailError('')}
                                        />
                                        <PhoneNumberInputWithLabel
                                            id="phone-number-input"
                                            name="phone-number"
                                            label="Phone"
                                            placeholder="168289192"
                                            value={phoneNumber}
                                            setValue={setPhoneNumber}
                                            error={phoneNumberError}
                                            clearError={() => setPhoneNumberError('')}
                                        />
                                        <EnquiryTypeInputWithLabel
                                            id="enquiry-type-input"
                                            name="enquiry-type"
                                            label="Enquiry"
                                            placeholder="Pelase select one"
                                            value={inquiryType}
                                            setValue={setInquiryType}
                                            error={inquiryTypeError}
                                            clearError={() => setInquiryTypeError('')}
                                        />
                                        <TextAreaInputWithLabel
                                            id="message-input"
                                            name="message"
                                            label="Message"
                                            placeholder="Write message here..."
                                            rows={5}
                                            value={message}
                                            setValue={setMessage}
                                            error={messageError}
                                            clearError={() => setMessageError('')}
                                        />
                                    </Stack>
                                    <Box py={2}>
                                        <UserSubmitButton>
                                            Send Enquiry
                                        </UserSubmitButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </ContentWrapper>
            </MainLayout>
        </>

    )
}

export default ContactUsPage
