import { Box, Stack, Typography } from "@mui/material"
import CheckedCircleListItem from "../../elements/CheckCircleListItem"


const PremiumPlanContent = () => {
    return (
        <Stack direction={'column'}>
            <CheckedCircleListItem>Monthly report to historical processed transactions</CheckedCircleListItem>
            <CheckedCircleListItem>Monthly report to Sanction hit transactions</CheckedCircleListItem>
            <CheckedCircleListItem>Retrieval of historical monthly reports</CheckedCircleListItem>
            <CheckedCircleListItem>Sanction Alerts</CheckedCircleListItem>
            <Box pl={4}>
                <Typography fontSize={16} fontWeight={400} color={'#333333'}>
                    (Based on recent new updated data vs historical processed data)
                </Typography>
            </Box>
        </Stack>
    )
}

export default PremiumPlanContent