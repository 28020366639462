import { Box, Grid, Stack, Typography } from "@mui/material"
import SearchLimitSelection from "../SearchLimitSelection/indext"
import { StyledPagination } from "../../contents/_common/styles"


interface Props {
    limit: number
    page: number
    totalPage: number
    onLimitChange: (limit: number) => void
    onPageChange: (page: number) => void
}


const PaginationWithSearchLimit = (props: Props) => {
    const {limit, page, totalPage, onLimitChange, onPageChange} = props

    return (
        <Grid container>
            <Grid xs={3}>
                <Stack direction={'row'} spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Show</Typography>
                    <SearchLimitSelection value={limit} setValue={(limit) => onLimitChange(limit)} />
                    <Typography>Entries</Typography>
                </Stack>
            </Grid>
            <Grid xs={6} >
                <Box my={0.5}>
                    <StyledPagination
                        count={totalPage}
                        boundaryCount={2} size="small" color="primary"
                        page={page} onChange={(_p, page) => onPageChange(page)} />
                </Box>
            </Grid>
            <Grid xs={3}></Grid>
        </Grid>
    )
}

export default PaginationWithSearchLimit