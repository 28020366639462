import { Table, TableBody, TableHead } from "@mui/material"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "../_common/styles"
import { Invoice, ResultPage } from "../../../types"
import { StyledLink } from "./styles"

interface Props {
    result: ResultPage<Invoice> | undefined
}

const InvoiceResultContent = (props: Props) => {
    const { result } = props

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell width={'5%'} align="center">No</StyledTableCell>
                        <StyledTableCell width={'12%'}>Date</StyledTableCell>
                        <StyledTableCell >Description</StyledTableCell>
                        <StyledTableCell width={'15%'}>Invoice Total</StyledTableCell>
                        <StyledTableCell width={'15%'}>Status</StyledTableCell>
                        <StyledTableCell width={'10%'}></StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {(result?.results.map((item, index) => (
                        <StyledTableRow>
                            <StyledTableCell align="center">{index + result?.firstElement}</StyledTableCell>
                            <StyledTableCell>{item.date}</StyledTableCell>
                            <StyledTableCell>{item.description}</StyledTableCell>
                            <StyledTableCell>{item.currency} {item.total}</StyledTableCell>
                            <StyledTableCell>{item.status}</StyledTableCell>
                            <StyledTableCell align="center">
                                <StyledLink to={`/invoices/${item.id}`}>View Invoice</StyledLink>
                            </StyledTableCell>
                        </StyledTableRow>
                    )))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default InvoiceResultContent