import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import VerificationCodePage from './pages/VerificationCodePage';
import { CookiesProvider } from 'react-cookie';
import MyProfilePage from './pages/MyProfilePage';
import MyPlanPage from './pages/MyPlanPage';
import SanctionAlertPage from './pages/SanctionAlertPage';
import ViewReportPage from './pages/ViewReportPage';
import TermAndConditionPage from './pages/TermAndConditionPage';
import PricingPage from './pages/PricingPage';
import ContactUsPage from './pages/ContactUsPage';
import UnIndividualSearchPage from './pages/UnIndividualSearchPage';
import UnEntitySearchPage from './pages/UnEntitySearchPage';
import MyIndividualSearchPage from './pages/MyIndividualSearchPage';
import MyEntitySeaarchPage from './pages/MyEntitySearchPage';
import ApiSpecPage from './pages/ApiSpecPaage';
import RequireAuth from './components/RequireAuth';
import InvoiceDetailPage from './pages/InvoiceDetailPage';
import ReportPage from './pages/ReportPage';
import LogoutPage from './pages/LogoutPage';
import UnIndividualDetailPage from './pages/UnIndividualDetailPage';
import UnEntityDetailPage from './pages/UnEntityDetailPage';
import MyIndividualDetailPage from './pages/MyIndividualDetailPage';
import MyEntityDetailPage from './pages/MyEntityDetailPage';
import PaymentPage from './pages/PaymentPage';
import FaqPage from './pages/FaqPage';
import TopupPage from './pages/TopupPage';

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<UnIndividualSearchPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/logout' element={<LogoutPage />} />
          <Route path='/forget-password' element={<ForgetPasswordPage />} />
          <Route path="/verify-code" element={<VerificationCodePage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path='/topup' element={<TopupPage />} />
          <Route element={<RequireAuth />}>
            <Route path='/my-profile' element={<MyProfilePage />} />
            <Route path='/my-plan' element={<MyPlanPage />} />
            <Route path='/invoices/:id' element={<InvoiceDetailPage />} />
            <Route path='/reports' element={<ReportPage />} />
            <Route path='/sanction-alerts' element={<SanctionAlertPage />} />
            <Route path='/view-reports' element={<ViewReportPage />} />
          </Route>
          <Route path='/terms-and-conditions' element={<TermAndConditionPage />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='/pricing' element={<PricingPage />} />
          <Route path='/api/specification' element={<ApiSpecPage />} />
          <Route path='/contact-us' element={<ContactUsPage />} />
          <Route path='/sanctions' >
            <Route path='united-nation'>
              <Route path='individuals' element={<UnIndividualSearchPage />} />
              <Route path="individuals/:id" element={<UnIndividualDetailPage />} />
              <Route path='entities' element={<UnEntitySearchPage />} />
              <Route path='entities/:id' element={<UnEntityDetailPage />} />
            </Route>
            <Route path='malaysia'>
              <Route path='individuals' element={<MyIndividualSearchPage />} />
              <Route path="individuals/:id" element={<MyIndividualDetailPage />} />
              <Route path='entities' element={<MyEntitySeaarchPage />} />
              <Route path='entities/:id' element={<MyEntityDetailPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
