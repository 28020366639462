import { Pagination, TableCell, TableContainer, TableRow, Typography, styled } from "@mui/material"
import ArrowDownIcon from "../../icons/ArrowDownIcon"

export const AdditionFilterIcon = styled(ArrowDownIcon)(({ theme }) => ({
    width: '20px',
    height: '20px',
    path: {
        fill: '#000'
    }
}))

export const HeaderLabel = styled(Typography)(({theme}) => ({
    fontSize: '16px',
    fontWeight: 700,
}))

export const StyledResultLabel = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 400,
}))

