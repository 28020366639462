import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import CheckDocIcon from "../../icons/CheckDocIcon";
import DollarIcon from "../../icons/DollarIcon";
import CloseIcon from "../../icons/CloseIcon";
import PlusIcon from "../../icons/PlusIcon";
import PromoCodeIcon from "../../icons/PromoCodeIcon";
import { StyledProceedButton } from "./styles";
import PromoCodeTextInput from "../../elements/PromoCodeTextInput";
import BasicPlanContent from "../../contents/BasicPlanContent";
import PremiumPlanContent from "../../contents/PremiumPlanContent";
import { SubscriptionOrder } from "../../../types";
import { amountOf } from "../../../utils";
import { SubscriptionPlan } from "../../../constants";

interface Props {
    open: boolean
    order: SubscriptionOrder
    premiumType: string
    premiumPlanPrice: number
    upgradeFlag: boolean
    setDiscount: (discount: number) => void
    onClose: (success: boolean) => void
}

const OrderSummaryDialog = (props: Props) => {
    const { order, setDiscount, premiumType, premiumPlanPrice, upgradeFlag, open, onClose } = props

    const handleClose = () => {
        onClose(false)
    }

    const handleProceedClick = () => {
        onClose(true)
    }

    const isPremiumOrder = (order: SubscriptionOrder): boolean => {
        return order.plan?.name === SubscriptionPlan.PREMIUM
    }

    const isBasicOrder = (order: SubscriptionOrder): boolean => {
        return order.plan?.name === SubscriptionPlan.BASIC
    }

    return (
        <Dialog onClose={handleClose} open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'scroll',
                }
            }}>
            <Box p={5}>
                <Box sx={{ width: '100%', overflowY: 'scroll', boxSizing: 'content-box' }}>
                    <DialogTitle component={Box} sx={{ m: 0, p: 0 }}>
                        <Stack direction={"row"} justifyContent={'space-between'} paddingBottom={1}>
                            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                                <DollarIcon />
                                <Typography fontSize={32} fontWeight={700}>Summary</Typography>
                            </Stack>
                            <IconButton onClick={handleClose} sx={{ width: 50, height: 50 }}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    <Divider light />
                    <DialogContent sx={{ padding: 0, marginTop: 1 }}>
                        <Stack spacing={2}>
                            <Stack direction={'row'} spacing={1}>
                                <CheckDocIcon />
                                {isBasicOrder(order) && (
                                    <Typography fontSize={18} fontWeight={700} color={'#333333'}>
                                        Basic Plan
                                    </Typography>
                                )}
                                {isPremiumOrder(order) && (
                                    <Typography fontSize={18} fontWeight={700} color={'#333333'}>
                                        Premium Plan
                                    </Typography>
                                )}
                            </Stack>
                            {!upgradeFlag &&
                                <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                    <Stack px={2} pt={2} pb={2} spacing={1}>
                                        <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>USD 999 Joining Fee</Typography>
                                        <BasicPlanContent />
                                    </Stack>
                                </Paper>
                            }
                            {isPremiumOrder(order) && (
                                <>
                                    {!upgradeFlag &&
                                        <Box px={1} display={'flex'} justifyContent={'center'} >
                                            <PlusIcon sx={{ width: 30, height: 30 }} />
                                        </Box>
                                    }
                                    <Paper variant="outlined" sx={{ borderRadius: '32px' }}>
                                        <Stack px={2} pt={2} pb={2} spacing={1}>
                                            <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>USD {premiumPlanPrice} {premiumType === 'monthly' ? 'Monthly' : 'Yearly'}</Typography>
                                            <PremiumPlanContent />
                                        </Stack>
                                    </Paper>
                                </>
                            )}
                            {!upgradeFlag && 
                                <Stack spacing={1}>
                                    <Stack direction={'row'} spacing={2}>
                                        <PromoCodeIcon />
                                        <Typography fontSize={18} fontWeight={500}>Promo Code</Typography>
                                    </Stack>
                                    <PromoCodeTextInput placeholder="Pomo Code" setDiscount={setDiscount} />
                                </Stack>
                            }
                            <Paper variant="outlined" sx={{ borderRadius: '32px', padding: 0.5 }}>
                                <Stack p={2} spacing={2}>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography fontSize={18} fontWeight={500}>Subtotal</Typography>
                                        <Typography fontSize={18} fontWeight={500} color={'#0C4992'}>USD {amountOf(order.price)}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography fontSize={18} fontWeight={500}>Discount</Typography>
                                        <Typography fontSize={18} fontWeight={500} color={'#0C4992'}>-USD {amountOf(order.discount)}</Typography>
                                    </Stack>
                                    <Divider />
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography fontSize={18} fontWeight={700}>Total</Typography>
                                        <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>USD {amountOf(order.total)}</Typography>
                                    </Stack>
                                </Stack>
                            </Paper>
                            <Stack py={2} spacing={1}>
                                <StyledProceedButton variant="contained" color="secondary"
                                    disableElevation fullWidth
                                    onClick={handleProceedClick}>
                                    Process
                                </StyledProceedButton>
                                <Typography fontSize={16}>By clicking the ‘Proceed to Payment’ button, you confirm that you have read, understand, and accept our Terms and Conditions and Privacy Policy.</Typography>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )
}

export default OrderSummaryDialog