import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { userPostForgetPassword } from "../../api/users";
import UserLoginLayout from "../../components/elements/UserLoginLayout";
import UserTextInputWithLabel from "../../components/elements/UserTextInputWithLabel";
import UserSubmitButton from "../../components/elements/UserSubmitButton";
import ContentHeader from "../../components/elements/ContentHeader";
import { StyledContent } from "./styles";
import WaitingProgress from "../../components/elements/WaitingProgress";

const ForgetPasswordPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');

    const [usernameError, setUsernameError] = useState('');

    const [waiting, setWaiting] = useState(false)

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (username !== '') {
            setWaiting(true)
            userPostForgetPassword(username)
                .then((res) => {
                    console.log(res)
                    console.log("redirect....")
                    navigate(`/reset-password?email=${username}`)
                })
                .catch((err) => {
                    setUsernameError(err.response.data.message)
                    setWaiting(false)
                })
        } else {
            setUsernameError('This field is required.')
        }
    }

    return (
        <UserLoginLayout>
            <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
            <Box component={'form'} onSubmit={handleSubmit} height={'100%'} >
                <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
                    <Box>
                        <ContentHeader >Forget Password</ContentHeader>
                        <Box sx={{ marginTop: 1 }}>
                            <StyledContent>We will send a verification code to your<br></br> email address.</StyledContent>
                        </Box>
                        <Box py={5}>
                            <UserTextInputWithLabel
                                label="Email"
                                id="username-input"
                                name="username"
                                placeholder="johndoe@gmail.com"
                                error={usernameError}
                                clearError={() => setUsernameError('')}
                                value={username}
                                setValue={setUsername}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <UserSubmitButton>
                            Reset Now
                        </UserSubmitButton>
                    </Box>
                </Stack>
            </Box>
        </UserLoginLayout>
    )
}

export default ForgetPasswordPage