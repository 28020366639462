import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material"
import ApiKeyRefreshIcon from "../../icons/ApiKeyRefreshIcon"
import { StyledNoButton, StyledYesButton } from "./styled"
import WaitingProgress from "../../elements/WaitingProgress"
import { useState } from "react"
import { postApiKey } from "../../../api/subscription"
import { Subscription } from "../../../types"

interface Props {
    subscription: Subscription
    open: boolean
    onClose: (success: boolean) => void
}

const GenerateKeyDialog = (props: Props) => {
    const { subscription, open, onClose } = props

    const [waiting, setWaiting] = useState(false)

    const handleNoButtonClick = () => {
        onClose(false)
    }

    const handleYesButtonClick = () => {
        setWaiting(true)
        postApiKey(subscription.id)
            .then((res) => {
                //setApiKey(res.data.data.key)
                setWaiting(false)
                onClose(true)
            })
        // setTimeout(() => {
        //     setWaiting(false)
        //     onClose(true)
        // }, 1000)
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '4336px',
                    maxWidth: '436px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5} >
                <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
                <DialogContent sx={{ m: 0, p: 0 }}>
                    <Stack spacing={1} >
                        <Box pb={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ApiKeyRefreshIcon sx={{ height: 48, width: 48 }} />
                        </Box>
                        <Stack alignItems={'center'}>
                            <Typography fontSize={18} fontWeight={700}>
                                Confirm to generate your API Key?
                            </Typography>
                            <Typography fontSize={18} fontWeight={500}>
                                Your existing informations will be changed.
                            </Typography>
                        </Stack>

                        <Box py={1}>
                            <Stack spacing={2}>
                            </Stack>
                        </Box>
                        <Stack direction={'row'} spacing={1.5}>
                            <StyledNoButton variant="outlined" color="primary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleNoButtonClick}
                            >
                                No
                            </StyledNoButton>
                            <StyledYesButton variant="contained" color="secondary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleYesButtonClick}
                            >
                                Yes
                            </StyledYesButton>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default GenerateKeyDialog