import { Button, darken, styled } from "@mui/material";

export const StyledJoinNowButton = styled(Button)(({ theme }) => ({
    padding: "4px 1px",
    height: '50px',
    display: 'block',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 25,
    '&:hover': {
        backgroundColor: darken('#FACB57', 0.1),
    },
}))