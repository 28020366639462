import { MenuItem, styled } from "@mui/material";


interface StyledMenuItemProps {
    component: any;
    to: string,
}

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ theme }) => ({
    paddingTop: "18px",
    paddingBottom: "18px",
    '&:hover': {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
        svg: {
            path: {
                stroke: theme.palette.background.paper
            }
        }
    }
}))