import { Typography } from "@mui/material"
import { Link } from "react-router-dom"

const TermAndConditionLink = () => {
    return (
        <Link to="/terms-and-conditions" >
            <Typography fontSize={12} color="#333333">Terms and conditions apply.</Typography>
        </Link>
    )
}

export default TermAndConditionLink