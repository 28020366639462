import { Box, Dialog, DialogContent, DialogTitle, FormControl, IconButton, Paper, Stack, Typography } from "@mui/material"
import { StyledTopUpButton } from "../TopUpCreditDialog/styled"
import CloseIcon from "../../icons/CloseIcon"
import GiftIcon from "../../icons/GiftIcon"
import UserTextInputWithLabel from "../../elements/UserTextInputWithLabel"
import WaitingProgress from "../../elements/WaitingProgress"
import { useState } from "react"

interface Props {
    onSubmit: (code: string) => Promise<any>
    open: boolean
    onClose: (success: boolean) => void
}

const RedeemDialog = (props: Props) => {
    const { onSubmit, open, onClose } = props

    const [value, setValue] = useState('')
    const [waiting, setWaiting] = useState(false)

    const handleClose = () => {
        onClose(false)
    }

    const handleTopUpNowClick = () => {
        setWaiting(true)
        onSubmit(value)
            .then(() => {
                setValue('')
                setWaiting(false)
                onClose(true)
            })
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '4336px',
                    maxWidth: '436px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5} >
                <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
                <DialogTitle component={Box} sx={{ m: 0, p: 0 }}>
                    <Stack direction={"row"} justifyContent={'space-between'} paddingBottom={1}>
                        <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                            <GiftIcon sx={{ height: 30, width: 30 }} />
                            <Typography fontSize={32} fontWeight={700}>Redeem</Typography>
                        </Stack>
                        <IconButton onClick={handleClose} sx={{ width: 50, height: 50 }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ padding: 0, marginTop: 1 }}>
                    <Stack spacing={2}>
                        <UserTextInputWithLabel
                            label="Enter Code"
                            id="redeem-input"
                            name="redeem-input"
                            placeholder="FREE100"
                            value={value}
                            setValue={setValue}
                        />
                        <StyledTopUpButton variant="contained" color="secondary"
                            disableElevation fullWidth
                            onClick={handleTopUpNowClick}>
                            Redeem Now
                        </StyledTopUpButton>
                    </Stack>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default RedeemDialog