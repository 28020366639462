import { Box, Grid, Stack, Typography } from "@mui/material"
import ContentWrapper from "../../components/elements/ContentWrapper"
import MainLayout from "../../components/layouts/MainLayout"
import InvoiceIcon from "../../components/icons/InvoiceIcon"
import { StyledDivider, StyledPaper, StyledSubTitle, StyledText, StyledTitle, StyledTotalValue, StyledValue } from "./styled"
import PremiumPlanWithPriceContent from "../../components/contents/PremiumPlanWithPriceContent"
import BasicPlanWithPriceContent from "../../components/contents/BasicPlanWithPriceContent"
import PlusIcon from "../../components/icons/PlusIcon"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { InvoiceDetail } from "../../types"
import { getInvoice, postInvoiceDocument } from "../../api/billing"
import DownloadButton from "../../components/elements/DownloadButton"
import LoadingPlaceHolder from "../../components/elements/LoadingPlaceHolder"


const InvoiceDetailPage = () => {
    const { id } = useParams()

    console.log(id)

    const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetail>()

    useEffect(() => {
        if (id !== undefined) {
            getInvoice(id)
                .then((res) => {
                    setInvoiceDetail(res.data.data)
                })
        }
    }, [])

    const handleDownloadInvoice = () => {
        if (id !== undefined) {
            return postInvoiceDocument(id)
                .then((res) => {
                    let data = new Blob([res.data], { type: res.headers['content-type'] })
                    let url = window.URL.createObjectURL(data)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = "Invoice_" + id + ".pdf"
                    link.click()
                })
        }
        return Promise.resolve();
    }

    if (invoiceDetail === undefined) {
        return (
            <MainLayout>
                <LoadingPlaceHolder />
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <ContentWrapper>
                <Box py={2}>
                    <Stack>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <InvoiceIcon sx={{ height: 30, width: 30 }} />
                                <Typography fontSize={32} fontWeight={700}>Invoice</Typography>
                            </Stack>
                            <DownloadButton onClick={() => handleDownloadInvoice()} />
                        </Stack>
                        <Grid container spacing={2} pt={5}>
                            <Grid item xs={6}>
                                <StyledPaper variant='outlined'>
                                    <Box p={3}>
                                        <Stack spacing={1.5}>
                                            <StyledTitle>Invoice Number</StyledTitle>
                                            <StyledText>MDh1029349</StyledText>
                                            <StyledText>Issued Date: {invoiceDetail?.date}</StyledText>
                                        </Stack>
                                    </Box>
                                </StyledPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledPaper variant='outlined'>
                                    <Box p={3}>
                                        <Stack spacing={1.5}>
                                            <StyledTitle>Billing Details</StyledTitle>
                                            <StyledText>{invoiceDetail?.billingName}</StyledText>
                                            <StyledText>{invoiceDetail?.billingEmail}</StyledText>
                                        </Stack>
                                    </Box>
                                </StyledPaper>
                            </Grid>
                        </Grid>
                        <Box my={5}>
                            <StyledPaper variant='outlined'>
                                <Box p={3}>
                                    <StyledTitle>Item Details</StyledTitle>
                                    <StyledText>{invoiceDetail?.plan !== null && invoiceDetail?.plan.description}</StyledText>
                                    <Grid container py={2}>
                                        {invoiceDetail?.plan !== null && invoiceDetail?.plan.name === 'basic' &&
                                            <>
                                                <Grid item xs={5.5}>
                                                    <BasicPlanWithPriceContent />
                                                </Grid>
                                            </>
                                        }
                                        {invoiceDetail?.plan !== null && invoiceDetail?.plan.name === 'premium' &&
                                            <>
                                                <Grid item xs={5.5}>
                                                    <BasicPlanWithPriceContent />
                                                </Grid>
                                                <Grid item xs={1} sx={{ display: 'block', margin: 'auto', textAlign: 'center' }}>
                                                    <PlusIcon />
                                                </Grid>
                                                <Grid item xs={5.5}>
                                                    <PremiumPlanWithPriceContent />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    <Stack spacing={1}>
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <StyledSubTitle>Subtotal</StyledSubTitle>
                                            <StyledValue>{invoiceDetail?.currency} {invoiceDetail?.subtotal}</StyledValue>
                                        </Stack>
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <StyledSubTitle>Discount</StyledSubTitle>
                                            <StyledValue>-{invoiceDetail?.currency} {invoiceDetail?.discount}</StyledValue>
                                        </Stack>
                                        <StyledDivider />
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <StyledTitle>Total</StyledTitle>
                                            <StyledTotalValue>{invoiceDetail?.currency} {invoiceDetail?.total}</StyledTotalValue>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </StyledPaper>
                        </Box>
                    </Stack>
                </Box>
            </ContentWrapper>
        </MainLayout>
    )
}

export default InvoiceDetailPage