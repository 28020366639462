import { ChangeEvent, useEffect, useState } from "react";
import { StyledOutlinedInput } from "../UserTextInputWithLabel/styles";
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { countries } from "./countries";
import { StyledInputBase, StyledInputLabel } from "./styles";
import CountryFlagImg from "../CountryFlag";
import FormValidateError from "../FormValidateError";

interface Props {
    label: string,
    id: string,
    name: string,
    placeholder: string
    error: string,
    clearError: () => void,
    value: string,
    setValue: (value: string) => void
}

const PhoneNumberInputWithLabel = (props: Props) => {
    const { label, id, name, placeholder, error, clearError, value, setValue } = props

    const [code, setCode] = useState('MY')
    const [phoneNumber, setPhoneNumber] = useState('')

    useEffect(() => {
        setValue(`+${phoneCodeOf(code)}${phoneNumber}`)
    }, [phoneNumber])

    useEffect(() => {
        if (value === '') {
            setCode('MY')
            setPhoneNumber('')
        }
    }, [value])

    const handleChange = (e: SelectChangeEvent) => {
        clearError()
        setCode(e.target.value)
    }

    const phoneCodeOf = (code: string): string | undefined => {
        return countries.find((it) => it.code === code)?.phone
    }

    const handleSetValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        clearError()
        setPhoneNumber(e.target.value)
    }

    return (
        <StyledOutlinedInput variant="standard" fullWidth error={error !== ''}>
            <StyledInputLabel shrink >{label}</StyledInputLabel>
            <Box>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '125px' }} error={error !== ''}>
                        <Select
                            id="country code select"
                            value={code}
                            autoWidth
                            onChange={handleChange}
                            renderValue={(value) => (
                                <Stack direction={'row'} spacing={1}>
                                    <CountryFlagImg code={value} /> <Typography>+{phoneCodeOf(value)}</Typography>
                                </Stack>
                            )}
                        >
                            {countries.map((val, idx) => (
                                <MenuItem key={idx} value={val.code}>
                                    <CountryFlagImg code={val.code} />&nbsp;&nbsp;+{val.phone} ({val.label})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <StyledInputBase
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={phoneNumber}
                        onChange={handleSetValue}
                        sx={{ width: '66%' }} />
                </Stack>
            </Box>
            <FormValidateError error={error} />
        </StyledOutlinedInput>
    )
}

export default PhoneNumberInputWithLabel