import { Typography } from "@mui/material"
import React, { useEffect } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { getPayment, patchPaymentStatus } from "../../api/billing"


const PaymentPage = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const status = searchParams.get('status')
    const paymentId = searchParams.get('id')

    useEffect(() => {
        if (paymentId !== null && status !== null)
            if (status === 'pending') {
                getPayment(paymentId)
                    .then((res) => {
                        window.location.replace(res.data.data.paymentUrl)
                    })
            } else if (status === 'completed') {
                patchPaymentStatus(paymentId, status)
                    .then((_res) => {
                        navigate('/login')
                    })
            }
    }, [])

    return (
        <>
            <Typography>Payment Page  {paymentId}</Typography>
            <Link to="/login" >Login</Link>
        </>
    )
}

export default PaymentPage