import { Button, Divider, Paper, Typography, styled } from "@mui/material";
import { DownloadIcon } from "../../components/icons/DownloadIcon";

export const StyledDownloadButton = styled(Button)(({ theme }) => ({
    width: 156,
    height: 50,
    borderRadius: 32,
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 32,
    borderColor: '#F1F1F1'
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '27px',
}))

export const StyledSubTitle = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '27px',
}))

export const StyledValue = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '27px',
    color: '#0C4992',
}))

export const StyledText = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
    borderColor: '#F1F1F1',
}))

export const StyledTotalValue = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '24px',
    color: '#0C4992',
}))

export const StyledDownloadIcon = styled(DownloadIcon)(({ theme }) => ({
    path: {
        stroke: 'white'
    }
}))