import { LoadingButton } from "@mui/lab";
import { darken, styled } from "@mui/material";

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    display: 'flex',
    padding: '4px 1px',
    width: '183px',
    height: '50px',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 25,
    color: '#333',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    border: '1px solid #333',
    '&:hover': {
        backgroundColor: darken('#fff', 0.1),
    },
    '& .MuiLoadingButton-loadingIndicator': {
        left: '22px'
    }
}))