import React from "react";
import MainLayout from "../../components/layouts/MainLayout";

const SanctionAlertPage = () => {
    return (
        <MainLayout>
            Sanction Alerts
        </MainLayout>
    )
}

export default SanctionAlertPage