import { Typography } from "@mui/material"
import PremiumPlanContent from "../PremiumPlanContent"

const PremiumPlanWithPriceContent = () => {
    return (
        <>
            <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>USD 200 Monthly</Typography>
            <PremiumPlanContent />
        </>
    )
}

export default PremiumPlanWithPriceContent