import { Accordion, AccordionSummary, Typography, styled } from "@mui/material";

export const StyledAccordion = styled(Accordion)(({theme}) => ({
    '&.MuiPaper-root': {
        borderRadius: 32,
        padding: theme.spacing(2),
        border: '1px solid #F1F1F1',
    },
    '&.MuiAccordion-root::before': {
        display: 'none',
      }
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    '& .MuiAccordionSummary-expandIconWrapper': {
        '&.Mui-expanded': {
            transform: 'rotate(225deg)',
        }
    }
}))

export const StyledTitleText = styled(Typography)(({theme}) => ({
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '27px'
}))

export const StyledDetailText = styled(Typography)(({theme}) => ({
    fontsize: 16,
    fontWeight: 400,
    lineHeight: '24px'
}))