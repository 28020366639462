import { Box, Paper, Stack } from "@mui/material"
import { StyledDiableButton, StyledPrimaryButton, StyledSubTitleText, StyledTitleText, StyledValueText } from "./styles"
import { SubscriptionPlan } from "../../../constants"
import { useEffect, useState } from "react"
import { DuePayment, Plan, Subscription, SubscriptionOrder } from "../../../types"
import { postSubscriptionDue } from "../../../api/subscription"

interface Props {
    plan: Plan
    subscription: Subscription
    duePayment: DuePayment | undefined
}

const DuePaymentCard = (props: Props) => {
    const { plan, subscription, duePayment } = props

    const [premiumPlan, setPremiumPlan] = useState(false)

    useEffect(() => {
        if (plan.name === SubscriptionPlan.PREMIUM) {
            setPremiumPlan(true)
        }
    }, [plan])


    const handlePayNow = () => {
        const subscriptionOrder = {
            uid: subscription.userId,
            plan: subscription.plan,
            planId: subscription.plan.id,
            price: duePayment?.dueAmount,
            discount: 0.0,
            total: duePayment?.dueAmount,
        } as SubscriptionOrder
        postSubscriptionDue(subscription.id, subscriptionOrder)
            .then((res) => {
                window.location.replace(res.data.data.paymentUrl)
            })

    }

    return (
        <Paper elevation={0} sx={{ height: 350, borderRadius: '32px', backgroundImage: `url(/img/total-amount-due-bg.png)` }} variant="elevation">
            <Box p={5} sx={{ height: '270px' }}>
                <Stack height='100%' direction={'column'} justifyContent={'space-between'} sx={{ color: '#ffffff' }}>
                    <Stack>
                        <StyledTitleText>Total Amount Due</StyledTitleText>
                        {(!premiumPlan) && (
                            <StyledValueText>-</StyledValueText>
                        )}
                        {premiumPlan && duePayment !== undefined && (
                            <Stack spacing={1}>
                                <StyledValueText>{duePayment.currency} {duePayment.dueAmount.toFixed(2)}</StyledValueText>
                                <StyledSubTitleText>Due by {duePayment.dueDate}</StyledSubTitleText>
                            </Stack>
                        )}
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={0.2} >
                            <StyledSubTitleText>Overdue</StyledSubTitleText>
                            <StyledValueText>{(plan.name === 'premium' && duePayment?.isOverDue) ? `${duePayment.currency} ${duePayment.dueAmount.toFixed(2)}` : '-'}</StyledValueText>
                        </Stack>
                        {(!premiumPlan) && (
                            <StyledDiableButton variant="contained">Pay Now</StyledDiableButton>
                        )}
                        {(premiumPlan) && (
                            <StyledPrimaryButton variant="contained" onClick={handlePayNow}>Pay Now</StyledPrimaryButton>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Paper>
    )
}

export default DuePaymentCard