import React, { useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { oauth2GetTokenByPassword } from "../../api/oauth2";
import UserLoginLayout from "../../components/elements/UserLoginLayout";
import { StyledWrapper, StyledBox, StyledLabel, StyledSmallLabel, StyledForgetPasswordLink } from "./styles";
import PasswordTextInput from "../../components/elements/PasswordTextInput";
import UserSubmitButton from "../../components/elements/UserSubmitButton";
import GoogleSignInButton from "../../components/elements/GoogleSignInButton";
import UsernameTextInput from "../../components/elements/UsernameTextInput";

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { postCustomerAuthenticate } from "../../api/customer";

const LoginPage = () => {
    const navigate = useNavigate()

    const [searchParam] = useSearchParams()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [usernameError, setUsernameError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    console.log(process.env.NODE_ENV)

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (username !== '' && password !== '') {
            oauth2GetTokenByPassword(username, password)
                .then(() => {
                    console.log("redirect....")
                    const redirect = searchParam.get('redirect')
                    if (redirect == undefined) {
                        navigate('/')
                    } else {
                        navigate(redirect)
                    }
                })
                .catch((err) => {
                    const resp = err.response
                    console.error(resp)
                    if (resp.status === 401) {
                        setPasswordError('Incorrect password. Click forgot password to reset.')
                    }
                })
        }
        if (username === '') {
            setUsernameError('This field is required.')
        }
        if (password === '') {
            setPasswordError('This field is required.')
        }
    }

    const handleClearUsernameError = () => {
        setUsernameError('')
    }

    const handleClearPasswordError = () => {
        setPasswordError('')
    }

    return (
        <UserLoginLayout>
            <Stack component={'form'} onSubmit={handleSubmit}>
                <StyledWrapper>
                    <StyledBox>
                        <StyledLabel>Sign In</StyledLabel>
                    </StyledBox>
                </StyledWrapper>
                <Stack spacing={2}>
                    <UsernameTextInput
                        label="Email"
                        placeholder="johndoe@gmail.com"
                        error={usernameError}
                        clearError={handleClearUsernameError}
                        value={username}
                        setValue={setUsername}
                    />
                    <PasswordTextInput
                        label="Password"
                        placeholder="Password"
                        error={passwordError}
                        clearError={handleClearPasswordError}
                        value={password}
                        setValue={setPassword}
                    />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <FormControlLabel
                            label={<StyledSmallLabel>Remember Me</StyledSmallLabel>}
                            control={<Checkbox size='small'
                                icon={<CircleOutlinedIcon sx={{ color: '#0C4992' }} />}
                                checkedIcon={<CheckCircleOutlinedIcon />}
                            />}
                        />
                        <StyledSmallLabel>
                            <StyledForgetPasswordLink to="/forget-password">Forget Password</StyledForgetPasswordLink>
                        </StyledSmallLabel>
                    </Box>
                    <Box py={2}>
                        <UserSubmitButton>Sign In</UserSubmitButton>
                        <Box py={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography fontWeight={500} color={'#BDBDBD'}>Or</Typography>
                        </Box>
                        <GoogleSignInButton />
                    </Box>
                </Stack>
            </Stack>
        </UserLoginLayout>
    )
}

export default LoginPage