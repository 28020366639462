import React from "react";
import { Button, FormControl, InputBase, Paper, darken, styled } from "@mui/material";

export const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '99.8%',
    borderRadius: "32px",
    border: '1px solid #D9D9D9',
}))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    flex: 1,
    height: '50px',
    fontSize: 18,
    fontWeight: 500,
    //paddingLeft: theme.spacing(3),
    //paddingRight: theme.spacing(3),
    borderRadius: '32px 0',
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: 'none',
        fontSize: 16,
        width: '100%',
        padding: '12.7px 24px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    }
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    height: '50px',
    width: '139px',
    color: '#ffffff',
    backgroundColor: '#0C4992',
    borderRadius: '0  32px 32px 0',
    fontSize: 18,
    fontWeight: 500,
    '&:hover': {
        backgroundColor: darken('#0C4992', 0.1),
    },
}))

export const StyledOutlinedInput = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: "99.8%",
    //paddingTop: theme.spacing(1),
    //borderColor: theme.palette.background.paper,
    borderRadius: "32px",
    border: '1px solid #D9D9D9',
    ".MuiInputBase-root": {
        borderRadius: '32px 0 0 32px',
        backgroundColor: '#ffffff'
    },
    ".MuiInputBase-input": {
        paddingLeft: theme.spacing(3)
    },
    ".MuiInputAdornment-root": {
        paddingRight: theme.spacing(1)
    },
    ".Mui-error": {
        color: 'red',
        border: '1px solid red',
    }
}))