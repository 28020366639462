import React, { useState } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { oauth2GetTokenByVerifyCode } from "../../api/oauth2";
import UserLoginLayout from "../../components/elements/UserLoginLayout";
import UserSubmitButton from "../../components/elements/UserSubmitButton";
import ContentHeader from "../../components/elements/ContentHeader";
import { StyledContent, StyledTextField } from "./styles";

const VerificationCodePage = () => {
    const nevigate = useNavigate();
    const [values, setValues] = useState([0, 0, 0, 0, 0]);

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newValues = values;
        newValues[+e.target.name] = +e.target.value
        setValues(newValues)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        oauth2GetTokenByVerifyCode('', values.join(""))
            .then(() => {
                console.log("redirect....")
                nevigate("/reset-password")
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <UserLoginLayout>
            <Box component={'form'} onSubmit={handleSubmit} height={'100%'}>
                <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
                    <Box>
                        <ContentHeader >Verification Code</ContentHeader>
                        <Box sx={{ marginTop: 1 }}>
                            <StyledContent>We have send a verification code to your email.</StyledContent>
                        </Box>
                        <Box py={5}>
                            <Stack direction={'row'} spacing={2}>
                                <StyledTextField
                                    name="0"
                                    inputProps={{ maxLength: 1 }}
                                    onChange={handleTextChange} />
                                <StyledTextField
                                    name="1"
                                    inputProps={{ maxLength: 1 }}
                                    onChange={handleTextChange} />
                                <StyledTextField
                                    name="2"
                                    inputProps={{ maxLength: 1 }}
                                    onChange={handleTextChange} />
                                <StyledTextField
                                    name="3"
                                    inputProps={{ maxLength: 1 }}
                                    onChange={handleTextChange} />
                                <StyledTextField
                                    name="4"
                                    inputProps={{ maxLength: 1 }}
                                    onChange={handleTextChange} />
                                <StyledTextField
                                    name="5"
                                    inputProps={{ maxLength: 1 }}
                                    onChange={handleTextChange} />
                            </Stack>
                        </Box>
                        <Stack direction={'row'} spacing={1}>
                            <Typography color={'#818181'}>If you didn’t receive a code, </Typography>
                            <Link href="#" underline="none" color={'#0066FF'} > click resend.</Link>
                        </Stack>
                    </Box>
                    <Box>
                        <UserSubmitButton>
                            Send
                        </UserSubmitButton>
                    </Box>
                </Stack>
            </Box>
        </UserLoginLayout>
    )
}

export default VerificationCodePage