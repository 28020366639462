import { Stack, Typography, styled } from "@mui/material";

export const StyledHeader = styled(Typography)(({ theme }) => ({
    fontSize: 30,
    fontWeight: 700
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
    fontsize: 20,
    fontWeight: 500
}))

export const StyledContent = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 400
}))

