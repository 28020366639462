import { Container } from "@mui/material";
import React from "react";

interface Props {
    children: any
}

const ContentWrapper: React.FC<Props> = ({ children }) => {
    return (
        <Container maxWidth={'lg'} sx={{ my: 5 }}>
            {children}
        </Container>
    )
}

export default ContentWrapper