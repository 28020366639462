
import { Box, Stack } from "@mui/material"
import { DialogProps } from "../_common"
import WarningIcon from "../../icons/WarningIcon"
import { StyledNoButton, StyledPaper, StyledYesButton } from "./styles"
import DialogHeaderLayout from "../../layouts/DialogHeaderLayout"
import WraningItem from "../../elements/WraningItem"
import WaitingProgress from "../../elements/WaitingProgress"
import { useState } from "react"
import { Subscription } from "../../../types"
import { unsubscribe } from "../../../api/subscription"

export interface Props {
    subscriptionId: string
    open: boolean
    onClose: (success: boolean) => void
}

const UnsubscribeConfirmDialog = (props: Props) => {
    const { subscriptionId, open, onClose } = props

    const [waiting, setWaiting] = useState(false)

    const handleYesButtonClick = () => {
        setWaiting(true)
        unsubscribe(subscriptionId)
            .then((res) => {
                console.log('unsubscribed')
                setWaiting(false)
                onClose(true)
            })

        // setTimeout(() => {
        //     setWaiting(false)
        //     onClose(true)
        // }, 1000)
    }

    const handleNoButtonClick = () => {
        onClose(false)
    }

    return (
        <DialogHeaderLayout icon={<WarningIcon />} title='Confirm to Unsubscribe?' open={open} onClose={onClose} >
            <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
            <Stack spacing={3}>
                <StyledPaper variant='outlined'>
                    <Box p={3}>
                        <Stack spacing={2}>
                            <WraningItem>
                                If you end the subscription now, your access features
                                will end by this month.
                            </WraningItem>
                            <WraningItem>
                                You will not be able to download the report if you unsubscribe
                            </WraningItem>
                            <WraningItem>
                                One time fee of USD 5,000 will be charged for downloading
                                yearly report or USD 500 per month if you have unsubscribed
                            </WraningItem>
                        </Stack>
                    </Box>
                </StyledPaper>
                <Stack direction={'row'} spacing={1.5}>
                    <StyledYesButton variant="contained" color="secondary"
                        disableElevation fullWidth
                        type="submit"
                        onClick={handleYesButtonClick}>Yes
                    </StyledYesButton>
                    <StyledNoButton variant="outlined" color="primary"
                        disableElevation fullWidth
                        type="submit"
                        onClick={handleNoButtonClick}>No
                    </StyledNoButton>
                </Stack>
            </Stack>
        </DialogHeaderLayout>
    )
}

export default UnsubscribeConfirmDialog