import { StyledButton, StyledInputBase, StyledPaper } from "../SearchLimitSelection/styles";
import SearchIcon from "../../icons/SearchIcon";

interface Props {
    keyword: string,
    placeholder: string,
    onKeywordChange: (keyword: string) => void,
    onSubmit: () => void
}

const HistorySearchBar = (props: Props) => {
    const { keyword, placeholder, onKeywordChange, onSubmit } = props;

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onKeywordChange(e.target.value)
    }

    return (
        <StyledPaper elevation={0} sx={{ width: '524px' }}>
            <StyledInputBase placeholder={placeholder}
                value={keyword}
                onChange={handleKeywordChange} />
            <StyledButton aria-label="directions" disableElevation
                onClick={() => onSubmit()}
            >
                <SearchIcon />
            </StyledButton>
        </StyledPaper>
    )
}

export default HistorySearchBar