import { Box, Typography, styled } from "@mui/material";

export const StyledTitle = styled(Typography)(({theme}) => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
}))

export const StyledValue = styled(Typography)(({theme}) => ({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px'
}))

export const StyledBox = styled(Box)(({theme}) => ({
    width: '50%'
}))