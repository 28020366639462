import React from "react";
import { StyledInputBase, StyledOutlinedInput } from "../UserTextInputWithLabel/styles";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import ErrorAlertIcon from "../../icons/ErrorAlertIcon";
import FormValidateError from "../FormValidateError";

interface Props {
    id: string,
    name: string,
    placeholder: string
    error: string,
    value: string,
    setValue: (value: string) => void
}

const UserTextInput = (props: Props) => {
    const { id, name, error, placeholder, value, setValue } = props
    
    return (
        <StyledOutlinedInput variant="standard" fullWidth error={error !== ''}>
            <StyledInputBase
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                sx={{borderColor: 'red'}} />
            <FormValidateError error={error} />
        </StyledOutlinedInput>
    )
}

export default UserTextInput