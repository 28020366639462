import { Box, Stack, Typography } from "@mui/material"

const CelebrateSanctionNotFound = () => {
    return (
        <Stack py={2} spacing={1} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
            <Box component='img' src='/img/celebration-sanction-not-found.png' />
            <Typography fontSize={24} fontWeight={700}>Congratulations!</Typography>
            <Typography fontSize={16} fontWeight={400}>The sanction you’re looking for is not found.</Typography>
        </Stack>
    )
}

export default CelebrateSanctionNotFound