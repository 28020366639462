import { FormControl, InputLabel, OutlinedInput, styled } from "@mui/material"

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    fontWeight: 500,
    fontSize: 18,
    color: '#000000',
    position: 'relative',
    transform: 'translate(0, -1.5px) scale(1)',
    marginBottom: theme.spacing(0.5),
    '&.MuiInputLabel-root.Mui-error': {
        color: '#FF0000'
    }
}))

export const StyledInputBase = styled(OutlinedInput)(({ theme }) => ({
    width: '100%',
    'label + &': {
        //marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-root": {
        borderRadius: 32,
        backgroundColor: '#ffffff',
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        width: '100%',
        padding: '12.7px 24px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    }
}))

export const StyledOutlinedInput = styled(FormControl)(({ theme }) => ({
    width: "100%",
    paddingTop: theme.spacing(1),
    borderColor: theme.palette.background.paper,
    ".MuiInputBase-root": {
        borderRadius: '32px',
        backgroundColor: '#ffffff'
    },
    ".MuiInputBase-input": {
        paddingLeft: theme.spacing(3)
    },
    ".MuiInputAdornment-root": {
        paddingRight: theme.spacing(1)
    }
}))