import { Paper, Typography, darken, styled } from "@mui/material";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";

export const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 32,
    borderColor: '#F1F1F1'
}))

export const StyledWraningText = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '27px',
}))

export const StyledYesButton = styled(StyledSubmitButton)(({ theme }) => ({

}))

export const StyledNoButton = styled(StyledSubmitButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: darken('#FFF', 0.1),
    },
}))