import React from "react";
import { Button, MenuItem, Typography, darken } from "@mui/material";
import ArrowDownIcon from "../../icons/ArrowDownIcon";
import { StyledMenu } from "./styles";

interface Props {
    year: string,
    years: string[]
    setYear: (year: string) => void,
}

const DownloadYearlySelection = (props: Props) => {
    const { year, years, setYear } = props

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectYear = (year: string) => {
        handleClose()
        setYear(year)
    }


    return (
        <>
            <Button
                sx={{
                    display: 'flex',
                    padding: '4px 1px',
                    width: '153px',
                    height: '50px',
                    borderRadius: 25,
                    color: '#333',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    border: '1px solid #333',
                    '&:hover': {
                        backgroundColor: darken('#fff', 0.1),
                    },
                }}
                endIcon={<ArrowDownIcon sx={{ path: { fill: '#333' } }} />}
                onClick={handleClick}>
                <Typography fontSize={18} fontWeight={500} px={2}>{year}</Typography>
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {years.map((value, _index) => (
                    <MenuItem onClick={() => handleSelectYear(value)} disableRipple>
                        {value}
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    )
}

export default DownloadYearlySelection