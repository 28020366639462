import { Box, DialogContent, Link, Stack } from "@mui/material"
import { DialogProps } from "../_common"
import { StyledCheckFlowerIcon, StyledPaper, StyledText, StyledTitle, StyledOkeyButton } from "./styles"
import DialogHeaderLayout from "../../layouts/DialogHeaderLayout"
import WraningItem from "../../elements/WraningItem"


const UnsubscribeNotifyDialog = (props: DialogProps) => {
    const { open, onClose } = props

    const handleOkeyButtonClick = () => {
        onClose(false)
    }

    const hanldeSubscribeAgainClick = () => {
        onClose(true)
    }

    return (
        <DialogHeaderLayout icon={<StyledCheckFlowerIcon />} title='Unsubscribed' open={open} onClose={onClose} >
            <DialogContent sx={{ padding: 0, marginTop: 1 }}>
                <Stack spacing={3}>
                    <StyledPaper variant='outlined'>
                        <Stack p={3} spacing={2}>
                            <Box>
                                <StyledTitle>Upon unsubscribing, you will no longer access</StyledTitle>
                                <StyledTitle>to the features below.</StyledTitle>
                            </Box>
                            <Stack spacing={2}>
                                <WraningItem>Monthly report to historical processed transactions</WraningItem>
                                <WraningItem>Monthly report to Sanction hit transactions</WraningItem>
                                <WraningItem>Retrieval of historical monthly reports</WraningItem>
                                <WraningItem>Sanction Alerts </WraningItem>
                            </Stack>
                        </Stack>
                    </StyledPaper>
                    <Stack direction={'row'} spacing={1}>
                        <StyledOkeyButton variant="contained" color="secondary"
                            disableElevation fullWidth
                            onClick={handleOkeyButtonClick}>Okey
                        </StyledOkeyButton>
                    </Stack>
                    <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'} >
                        <StyledText>Accidentally Unsubscribed?</StyledText>
                        <Link sx={{ fontSize: 18, fontWeight: 500 }} component={'button'}
                            onClick={hanldeSubscribeAgainClick}>Subscribe Again</Link>
                    </Stack>

                </Stack>

            </DialogContent>
        </DialogHeaderLayout>
    )
}

export default UnsubscribeNotifyDialog