import { useEffect, useState } from "react"
import { Box, Grid, Stack } from "@mui/material"
import ContentWrapper from "../../components/elements/ContentWrapper"
import MainLayout from "../../components/layouts/MainLayout"
import SanctionDetailCard from "../../components/cards/SanctionDetailCard"
import { StyledBox, StyledTitle, StyledValue } from "./styles"
import { MyIndividualDetail } from "../../types"
import { useParams } from "react-router-dom"
import { getMyIndividualSanctionDetail } from "../../api/sanctions"
import LoadingPlaceHolder from "../../components/elements/LoadingPlaceHolder"


const MyIndividualDetailPage = () => {
    const { id } = useParams()

    const [value, setValue] = useState<MyIndividualDetail>()

    useEffect(() => {
        if (id !== undefined) {
            getMyIndividualSanctionDetail(id)
                .then((res) => {
                    console.log(res.data.data)
                    setValue(res.data.data)
                })
        }
    }, [id])

    if (value === undefined) {
        return (
            <MainLayout>
                <LoadingPlaceHolder />
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <ContentWrapper>
                <Stack spacing={5}>
                    <SanctionDetailCard title="i. Individual Particulars">
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Reference</StyledTitle>
                                    <StyledValue>{value?.reference}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Name</StyledTitle>
                                    <StyledValue>{value?.name}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Nationality</StyledTitle>
                                    <StyledValue>{value?.nationality}</StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                            </StyledBox>
                        </Stack>
                        {/* <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Place of Birth</StyledTitle>
                                    {((value?.placeOfBirth !== null) && (
                                        <>
                                            <Stack spacing={1}>
                                                <Stack direction={'row'}>
                                                    <Box width={'30%'}>
                                                        <StyledValue>State/Province</StyledValue>
                                                    </Box>
                                                    <Box width={'70%'}>
                                                        <StyledValue>: {value?.placeOfBirth?.stateProvince}</StyledValue>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                            <Stack spacing={1}>
                                                <Stack direction={'row'}>
                                                    <Box width={'30%'}>
                                                        <StyledValue>Country</StyledValue>
                                                    </Box>
                                                    <Box width={'70%'}>
                                                        <StyledValue>: {value?.placeOfBirth?.country}</StyledValue>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </>
                                    ))}
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Date of Birth</StyledTitle>
                                    <Stack spacing={1}>
                                        <Stack direction={'row'}>
                                            <Box width={'30%'}>
                                                <StyledValue>Type of Date</StyledValue>
                                            </Box>
                                            <Box width={'70%'}>
                                                <StyledValue>: {value?.dateOfBirth.typeOfDate}</StyledValue>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={1}>
                                        <Stack direction={'row'}>
                                            <Box width={'30%'}>
                                                <StyledValue>Date</StyledValue>
                                            </Box>
                                            <Box width={'70%'}>
                                                <StyledValue>: {value?.dateOfBirth.date}</StyledValue>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </StyledBox>
                        </Stack> */}
                    </SanctionDetailCard>
                    <SanctionDetailCard title="ii. Details">
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Individual Documents</StyledTitle>
                                    <StyledValue></StyledValue>
                                </Stack>
                            </StyledBox>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Address</StyledTitle>
                                    {value?.addresses !== null && value?.addresses.map((v, i) => (
                                        <StyledValue>{v}</StyledValue>
                                    ))}

                                </Stack>
                            </StyledBox>
                        </Stack>
                        <Stack direction={'row'}>
                            <StyledBox>
                                <Stack spacing={1}>
                                    <StyledTitle>Listed On</StyledTitle>
                                    <StyledValue>{value?.listedOn}</StyledValue>
                                </Stack>
                            </StyledBox>
                        </Stack>
                    </SanctionDetailCard>
                    <SanctionDetailCard title="iii. Alias">
                        <StyledTitle>Individual Alias</StyledTitle>
                        <Grid container sx={{ paddingLeft: 0 }}>
                            {value?.aliases.map((v, i) => (
                                <Grid item xs={6} key={i} sx={{ paddingLeft: '0px' }}>
                                    <Box>
                                        <Grid container alignItems={'flex-start'} sx={{ paddingBottom: 2 }}>
                                            <Grid item sm={0.5}>{i + 1}.</Grid>
                                            <Grid item sm={11.5}>
                                                <Box>
                                                    <Grid container >
                                                        <Grid item sm={4}>Alias</Grid>
                                                        <Grid item sm={0.5}>:</Grid>
                                                        <Grid item sm={7.5}>{v}</Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </SanctionDetailCard>
                </Stack>
            </ContentWrapper>
        </MainLayout>
    )
}

export default MyIndividualDetailPage