import { Backdrop, CircularProgress } from "@mui/material"
import { useState } from "react"

interface Props {
    open: boolean
    onClose: () => void
}

const WaitingProgress = (props: Props) => {
    const { open, onClose } = props

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={() => onClose()}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default WaitingProgress