import MainLayout from "../../components/layouts/MainLayout";
import { Stack, Typography } from "@mui/material";
import TermAndConIcon from "../../components/icons/TermAndConIcon";
import { StyledContentPaper, StyledContentHeader, StyledContentDetail } from "./styles";
import ContentWrapper from "../../components/elements/ContentWrapper";

const TermAndConditionPage = () => {
    return (
        <MainLayout>
            <ContentWrapper>
                <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                    <TermAndConIcon sx={{ height: 30, width: 30 }} />
                    <Typography sx={{ lineHeight: '48px', fontSize: 32, fontWeight: 700 }}>Terms & Conditions</Typography>
                </Stack>
                <Stack py={4}>
                    <StyledContentPaper variant="outlined">
                        <StyledContentHeader>1. Duration and termination of the subscription</StyledContentHeader>
                        <StyledContentDetail>The subscriptions run from the date, as specified in the Formal Quote. The subscriptions can have monthly, quarterly, and yearly renewal term. At the end of the period, the
                            subscription will automatically renew for another period unless terminated by the Customer. The minimum subscription period for monthly and quarterly subscriptions is
                            six (6) months and 12 months for annual subscriptions.</StyledContentDetail>
                    </StyledContentPaper>
                    <StyledContentPaper variant="outlined">
                        <StyledContentHeader>2. Price and payment terms</StyledContentHeader>
                        <StyledContentDetail>Prices for each service item are listed on the Formal Quote. Queue-it guarantees that no price increases will be applied for the Services ordered at the time of commencement
                            of the subscription other than those due to increases in the Retail Price Index or increases applied by the cloud computing supplier, currently Amazon Web Services. The invoicing
                            periods runs according to the Formal Quote. The payment term is as specified on the Formal Quote. If the subscription is not paid at the due date, a first reminder will be sent to
                            the Customer. Access to the Services may be blocked until receipt of payment.</StyledContentDetail>
                    </StyledContentPaper>
                    <StyledContentPaper variant="outlined">
                        <StyledContentHeader>3. Scope and use of the subscription</StyledContentHeader>
                        <StyledContentDetail>Queue-it subscription grants the legal entity that has taken out the subscription (the “Customer”) the right to use Queue-it’s system and services (the “Services”). The subscription
                            may not be used by other people or organizations.</StyledContentDetail>
                    </StyledContentPaper>
                    <StyledContentPaper variant="outlined">
                        <StyledContentHeader>4. Acceptance of the subscription</StyledContentHeader>
                        <StyledContentDetail>Queue-it subscription terms are agreed by the Customer by signing the last page of these terms and conditions (the “Terms”) with the attached formal quote (the “Formal Quote”).
                            The signed Terms and the Formal Quote will represent the agreement (the “Agreement”) between the Customer and Queue-it (the “Parties”). The entire signed Agreement must be
                            sent by e-mail.</StyledContentDetail>
                    </StyledContentPaper>
                    <StyledContentPaper variant="outlined">
                        <StyledContentHeader>5. System changes and features updates</StyledContentHeader>
                        <StyledContentDetail>Queue-it informs its users on the Queue-it GO self-service platform about system changes, feature updates, and other technical news by e-mail. Therefore, all registered users on
                            the Queue-it GO self-service platform accept that their name and email used for the registration are added to the technical newsletter mailing list.</StyledContentDetail>
                    </StyledContentPaper>
                </Stack>
            </ContentWrapper>
        </MainLayout>
    )
}

export default TermAndConditionPage