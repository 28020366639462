import { useState } from "react"
import { Box, Stack } from "@mui/material"
import { Plan, ResultPage, TransactionHistory } from "../../../types"
import HistorySearchBar from "../../elements/HistorySearchBar"
import PaginationDetail from "../../elements/PaginationDetail"
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles"
import PaginationWithSearchLimit from "../../elements/SearchLimitAndPagination"
import DownloadReportButton from "../../elements/DownloadReportButton"

interface Props {
    plan: Plan
    children: any
    result: ResultPage<TransactionHistory> | undefined
    submitSearch: (keyword: string, page: number, limit: number) => void
    upgradePremiumPlan: () => void
}

const TransactionListAndSearch = (props: Props) => {
    const { plan, children, result, submitSearch, upgradePremiumPlan } = props

    const [keyword, setKeyword] = useState('')
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)

    const handleLimitChange = (limit: number) => {
        setLimit(limit)
    }

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    return (
        <Box mb={3}>
            <Stack spacing={2}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <HistorySearchBar
                        keyword={keyword}
                        placeholder={'Enter Transaction Details'}
                        onKeywordChange={(keyword) => setKeyword(keyword)}
                        onSubmit={() => submitSearch(keyword, page, limit)}
                    />
                    {(plan.name !== 'premium') && (
                        <StyledSubmitButton variant='contained' color='secondary' disableElevation sx={{ width: 360 }} onClick={upgradePremiumPlan}>
                            Upgrade Plan to Download Reports
                        </StyledSubmitButton>
                    )}
                    {(plan.name === 'premium') && (
                        // <StyledDownloadReportButton startIcon={<DownloadIcon />}>
                        //     Download Reports
                        // </StyledDownloadReportButton>
                        <DownloadReportButton />
                    )}

                </Stack>
                {(result !== undefined) && (
                    <>
                        <PaginationDetail result={result} />
                        <Box sx={{ minHeight: 355 }}>
                            {children}
                        </Box>
                        <PaginationWithSearchLimit
                            limit={limit} page={page} totalPage={result.totalPage}
                            onLimitChange={handleLimitChange}
                            onPageChange={handlePageChange}
                        />
                    </>
                )}
            </Stack>
        </Box>
    )
}

export default TransactionListAndSearch