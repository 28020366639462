import DownloadIcon from "../../icons/DownloadIcon";
import { useState } from "react";
import { StyledLoadingButton } from "./styles";

interface Props {
    onClick: () => Promise<any>
}

const DownloadButton = (props: Props) => {
    const { onClick } = props

    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        //onClick(year, month)
        setLoading(true)
        onClick()
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }

    return (
        <StyledLoadingButton
            loading={loading}
            onClick={handleClick}
            loadingPosition="start"
            variant="contained"
            startIcon={<DownloadIcon sx={{ path: { stroke: '#fff' } }} />}
        >
            Download
        </StyledLoadingButton>
    )
}

export default DownloadButton