import { Button } from "@mui/material"
import { Link as RouterLink } from "react-router-dom";

const ContactUsButton = () => {
    return (
        <Button component={RouterLink} to="/contact-us" variant="contained" color="secondary"
            disableElevation
            sx={{
                px: 4, py: 1,
                display: 'block',
                fontSize: '18px',
                fontWeight: '500',
                borderRadius: '25px'
            }}>
            Contact Us
        </Button>
    )
}

export default ContactUsButton