import { Box, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material"
import DialogLayout from "../DialogLayout"
import CloseIcon from "../../icons/CloseIcon"

interface Props {
    children: any,
    icon: any,
    title: string
    open: boolean,
    onClose: (success: boolean) => void
}

const DialogHeaderLayout = (props: Props) => {
    const {children, icon, title, open, onClose} = props

    return (
        <DialogLayout open={open} onClose={onClose} >
            <DialogTitle component={Box} sx={{ m: 0, p: 0 }}>
                <Stack direction={"row"} justifyContent={'space-between'} paddingBottom={1}>
                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                       {icon}
                        <Typography fontSize={32} fontWeight={700} color={'#333333'}>
                            {title}
                        </Typography>
                    </Stack>
                    <IconButton onClick={() => onClose(false)} sx={{ width: 50, height: 50 }}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ padding: 0, marginTop: 1 }}>
                {children}
            </DialogContent>
        </DialogLayout>
    )
}

export default DialogHeaderLayout