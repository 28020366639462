import React from "react";
import { Typography, styled } from "@mui/material";

const StyledHeader = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "48px"
}))

interface Props {
    children: any
}

const ContentHeader: React.FC<Props> = ({ children }: Props) => {
    return (
        <StyledHeader>{children}</StyledHeader>
    )
}

export default ContentHeader