import React from "react";
import { createSvgIcon } from "@mui/material";


const ArrowDownIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.191 9L7.81011 9C7.17102 9 6.78271 9.51453 7.13057 9.91077L11.321 14.7249C11.394 14.8091 11.4949 14.8786 11.6144 14.9267C11.734 14.9748 11.8682 15 12.0046 15C12.141 15 12.2753 14.9748 12.3948 14.9267C12.5143 14.8786 12.6152 14.8091 12.6882 14.7249L16.8706 9.91077C16.9495 9.82157 16.994 9.71845 16.9994 9.61229C17.0049 9.50613 16.971 9.40086 16.9014 9.3076C16.8318 9.21433 16.7291 9.13653 16.6041 9.08239C16.479 9.02825 16.3363 8.99979 16.191 9Z" fill="white" />
    </svg>,
    'ArrowDownIcon'
)

export default ArrowDownIcon;