import { Stack } from "@mui/material"
import CheckedCircleListItem from "../../elements/CheckCircleListItem"
import CheckedSquareListItem from "../../elements/CheckedSquarListItem"


const FreePlanContent = () => {
    return (
        <Stack direction={'column'}>
            <CheckedSquareListItem>It’s a free plan for now.</CheckedSquareListItem>
            <CheckedCircleListItem>Join us now to unlock more features!</CheckedCircleListItem>
            <CheckedCircleListItem>Click ‘Join Now’ to view plan details.</CheckedCircleListItem>
        </Stack>
    )
}

export default FreePlanContent