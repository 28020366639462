import React, { useState } from "react";
import { IconButton, ListItemText, Menu } from "@mui/material"
import { AdditionFilterIcon } from "../../contents/UnIndividualResultContent/styles"
import { StyledMenuItem } from "./styles";

interface Props {
    filterChange: (filter: string) => void
}

const IndividualDocumentFilter = (props: Props) => {
    const {filterChange} = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleFilterByAll = () => {
        filterChange('all')
        handleClose()
    }

    const handleFilterByPassword = () => {
        filterChange('passport')
        handleClose()
    }

    const handleFilterByNationalId = () => {
        filterChange('nationalid')
        handleClose()
    }

    return (
        <>
            <IconButton size="small" sx={{ padding: '2px' }} onClick={handleClick}>
                <AdditionFilterIcon />
            </IconButton>
            <Menu
                id="individual-document-filter"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: "296px",
                        borderRadius: "24px",
                        marginTop: "10px",
                        marginLeft: '-50px'
                    }
                }}
                MenuListProps={{
                    sx: {
                        py: 0
                    }
                }}
            >
                <StyledMenuItem onClick={handleFilterByAll}>
                    <ListItemText>All</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleFilterByPassword} >
                    <ListItemText>Password</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleFilterByNationalId}>
                    <ListItemText>National Identification</ListItemText>
                </StyledMenuItem>
            </Menu>
        </>
    )
}

export default IndividualDocumentFilter