import React from "react";
import { SvgIcon, createSvgIcon } from "@mui/material";

const AppLogo = createSvgIcon(
    <svg width="178" height="40" viewBox="0 0 178 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1030_1706)">
            <path d="M10.8694 21.1492C14.0055 10.1198 14.7792 0.664646 12.5974 0.0304928C10.4156 -0.60366 6.1046 7.82335 2.96849 18.8527C-0.167634 29.8821 -0.941282 39.3373 1.2405 39.9715C3.42227 40.6056 7.73328 32.1786 10.8694 21.1492Z" fill="#B3CDEF" />
            <path d="M55.0954 31.7489C53.7009 31.7489 53.8005 30.0369 53.8005 30.0369C53.8005 30.0369 53.7009 21.8731 53.8005 17.4421C53.9001 13.0111 49.7167 11.1917 49.7167 11.1917C49.7167 11.1917 43.435 8.97619 38.229 11.1917C33.023 13.4072 33.2687 18.3485 33.2687 18.3485L38.654 18.4492C38.654 18.4492 38.2357 17.2407 39.2516 16.3344C41.2437 14.2196 44.5373 14.3203 46.8282 15.0252C49.1191 15.7301 49.1191 17.8449 48.4219 19.4562C47.7246 21.0675 39.2516 21.2689 36.5623 22.276C33.873 23.283 31.7746 25.3978 32.3789 29.332C32.9765 33.2595 35.2674 35.9853 41.1507 35.8174C47.0341 35.6496 49.2254 32.7627 49.2254 32.7627C49.2254 32.7627 49.1257 34.374 50.7194 35.2803C52.8111 36.4888 56.5031 35.2803 56.5031 35.2803V31.7556C56.4898 31.7489 56.4898 31.7489 55.0954 31.7489ZM48.6144 27.714C48.6144 27.714 48.3156 30.6345 45.0287 31.4401C41.7417 32.2457 37.9501 31.9436 37.5517 28.419C37.5517 28.419 37.4521 24.995 42.6382 24.4915C47.8242 23.9879 48.6211 23.0145 48.6211 23.0145V27.714H48.6144Z" fill="white" />
            <path d="M57.5859 11.413H61.5701V3.9541H66.7562V11.413H71.6434V15.3405H66.7562V29.2512C66.7562 29.2512 66.557 31.7621 69.6447 31.8158C70.84 31.8158 71.6368 31.8158 71.6368 31.8158V35.8171C71.6368 35.8171 68.1506 36.5019 64.6114 35.4949C61.0721 34.4878 61.3709 28.6402 61.3709 28.6402L61.4705 15.3405H57.5859V11.413Z" fill="white" />
            <path d="M77.0213 11.3127V8.39222C77.0213 8.39222 76.6428 3.04815 81.2645 2.24251C83.2964 1.83969 87.1876 2.24251 87.1876 2.24251V6.68695C87.1876 6.68695 85.2951 6.06929 84.1995 6.2707C83.1038 6.47211 82.1011 6.87493 82.1011 8.38551C82.1078 9.89608 82.1011 11.306 82.1011 11.306H86.59V15.0387H82.5062V35.8175H77.0147V15.4416H73.1367V11.3127H77.0213Z" fill="white" />
            <path d="M25.1495 39.7261C27.3043 39.0036 26.1545 29.5875 22.5814 18.6947C19.0083 7.80181 14.3649 -0.442858 12.2101 0.279658C10.0554 1.00218 11.2052 10.4183 14.7783 21.3111C18.3514 32.204 22.9948 40.4486 25.1495 39.7261Z" fill="white" />
            <path d="M109.858 1.94727L99.6318 35.0053H100.84L111.193 1.94727H109.858Z" fill="white" />
            <path d="M125.774 8.19713C125.734 8.11657 125.695 7.97558 125.661 7.7876C125.349 8.11657 124.971 8.27769 124.526 8.27769C124.088 8.27769 123.736 8.15013 123.463 7.90173C123.191 7.65332 123.052 7.33778 123.052 6.96853C123.052 6.49857 123.224 6.14275 123.563 5.89434C123.908 5.64594 124.4 5.51838 125.037 5.51838H125.635V5.22969C125.635 5.00143 125.575 4.82016 125.449 4.68588C125.323 4.55161 125.13 4.48447 124.878 4.48447C124.659 4.48447 124.473 4.53818 124.333 4.65232C124.194 4.76645 124.121 4.90743 124.121 5.08199H123.145C123.145 4.8403 123.224 4.61875 123.384 4.41062C123.543 4.2025 123.756 4.03466 124.028 3.92053C124.3 3.79968 124.599 3.73926 124.938 3.73926C125.442 3.73926 125.847 3.86682 126.153 4.12865C126.452 4.38377 126.611 4.74631 126.618 5.21626V7.18337C126.618 7.57947 126.671 7.89501 126.784 8.12999V8.19713H125.774ZM124.705 7.48548C124.898 7.48548 125.077 7.43849 125.25 7.34449C125.422 7.2505 125.549 7.12294 125.635 6.96182V6.13604H125.11C124.752 6.13604 124.479 6.19646 124.3 6.32402C124.121 6.45158 124.028 6.63285 124.028 6.86111C124.028 7.04909 124.088 7.20351 124.214 7.31093C124.327 7.43177 124.499 7.48548 124.705 7.48548Z" fill="white" />
            <path d="M131.691 7.00967C131.691 6.83511 131.618 6.70084 131.479 6.60685C131.333 6.51285 131.1 6.43229 130.768 6.36515C130.436 6.29802 130.164 6.20403 129.938 6.09661C129.453 5.86163 129.208 5.51252 129.208 5.0627C129.208 4.68674 129.367 4.3712 129.679 4.11608C129.991 3.86096 130.396 3.7334 130.881 3.7334C131.399 3.7334 131.817 3.86096 132.136 4.12279C132.455 4.38462 132.614 4.72031 132.614 5.12984H131.638C131.638 4.94186 131.572 4.78073 131.432 4.65988C131.293 4.53232 131.107 4.4719 130.881 4.4719C130.668 4.4719 130.496 4.5189 130.356 4.6196C130.224 4.72031 130.157 4.85458 130.157 5.02242C130.157 5.17684 130.217 5.29097 130.343 5.37153C130.469 5.4521 130.722 5.53937 131.107 5.62665C131.492 5.71393 131.791 5.81463 132.003 5.92877C132.222 6.0429 132.382 6.18389 132.488 6.35173C132.594 6.51957 132.647 6.71426 132.647 6.94924C132.647 7.34535 132.488 7.66089 132.163 7.9093C131.837 8.15099 131.419 8.27855 130.894 8.27855C130.542 8.27855 130.224 8.21141 129.945 8.08385C129.666 7.95629 129.453 7.77502 129.294 7.54676C129.141 7.31849 129.062 7.0768 129.062 6.81497H130.004C130.018 7.04995 130.104 7.2245 130.263 7.35206C130.423 7.47962 130.635 7.54005 130.901 7.54005C131.16 7.54005 131.352 7.49305 131.485 7.39234C131.625 7.29164 131.691 7.16408 131.691 7.00967Z" fill="white" />
            <path d="M138.929 6.04961C138.929 6.72769 138.776 7.26478 138.471 7.67432C138.165 8.07714 137.76 8.27855 137.242 8.27855C136.764 8.27855 136.386 8.11742 136.1 7.80188V9.8764H135.131V3.81396H136.027L136.067 4.25706C136.353 3.90795 136.738 3.7334 137.229 3.7334C137.76 3.7334 138.172 3.93481 138.471 4.33091C138.77 4.72702 138.922 5.28425 138.922 5.98919V6.04961H138.929ZM137.96 5.96905C137.96 5.53266 137.873 5.18355 137.701 4.92843C137.528 4.67331 137.282 4.54575 136.964 4.54575C136.565 4.54575 136.28 4.71359 136.107 5.04256V6.98281C136.286 7.32521 136.572 7.49305 136.97 7.49305C137.282 7.49305 137.521 7.36549 137.694 7.11708C137.873 6.86196 137.96 6.47929 137.96 5.96905Z" fill="white" />
            <path d="M141.393 2.67894C141.393 2.52453 141.439 2.40368 141.532 2.30297C141.625 2.20227 141.764 2.15527 141.937 2.15527C142.116 2.15527 142.249 2.20227 142.342 2.30297C142.435 2.40368 142.488 2.53124 142.488 2.67894C142.488 2.82664 142.442 2.94749 142.342 3.04819C142.243 3.1489 142.11 3.19589 141.937 3.19589C141.758 3.19589 141.625 3.1489 141.532 3.04819C141.439 2.94749 141.393 2.82664 141.393 2.67894ZM142.428 8.19757H141.452V3.81355H142.428V8.19757Z" fill="white" />
            <path d="M147.395 4.71359C147.269 4.69345 147.136 4.68002 146.996 4.68002C146.551 4.68002 146.253 4.85458 146.093 5.19698V8.19127H145.117V3.81396H146.047L146.073 4.30406C146.306 3.92138 146.631 3.7334 147.05 3.7334C147.189 3.7334 147.302 3.75354 147.395 3.78711V4.71359Z" fill="white" />
            <path d="M151.559 8.27843C150.941 8.27843 150.443 8.08373 150.058 7.68762C149.673 7.29823 149.48 6.77456 149.48 6.11663V5.99578C149.48 5.55939 149.567 5.17 149.733 4.82089C149.899 4.47849 150.131 4.20995 150.437 4.01525C150.742 3.82055 151.074 3.72656 151.446 3.72656C152.037 3.72656 152.489 3.91455 152.814 4.29722C153.133 4.6799 153.299 5.217 153.299 5.91522V6.31132H150.47C150.496 6.67386 150.616 6.95583 150.828 7.17067C151.041 7.37879 151.3 7.48621 151.619 7.48621C152.064 7.48621 152.429 7.30494 152.708 6.94241L153.232 7.44593C153.06 7.70776 152.827 7.90917 152.535 8.05687C152.243 8.20458 151.917 8.27843 151.559 8.27843ZM151.446 4.52549C151.18 4.52549 150.961 4.61948 150.802 4.80746C150.636 4.99544 150.536 5.25728 150.49 5.59967H152.342V5.52582C152.323 5.19685 152.236 4.94845 152.083 4.78061C151.931 4.61277 151.712 4.52549 151.446 4.52549Z" fill="white" />
            <path d="M124.406 16.1133V17.1808H125.17V17.9125H124.406V20.3563C124.406 20.5242 124.44 20.645 124.506 20.7189C124.572 20.7927 124.685 20.833 124.858 20.833C124.971 20.833 125.084 20.8196 125.197 20.7927V21.5513C124.977 21.6118 124.758 21.6453 124.552 21.6453C123.802 21.6453 123.43 21.2291 123.43 20.3966V17.9125H122.72V17.1808H123.43V16.1133H124.406Z" fill="white" />
            <path d="M129.459 21.6388C128.842 21.6388 128.344 21.4441 127.959 21.048C127.573 20.6586 127.381 20.1349 127.381 19.477V19.3561C127.381 18.9197 127.467 18.5304 127.633 18.1812C127.799 17.8388 128.032 17.5703 128.337 17.3756C128.643 17.1809 128.975 17.0869 129.346 17.0869C129.937 17.0869 130.389 17.2749 130.714 17.6576C131.033 18.0403 131.199 18.5773 131.199 19.2756V19.6717H128.37C128.397 20.0342 128.516 20.3162 128.729 20.531C128.941 20.7391 129.2 20.8466 129.519 20.8466C129.964 20.8466 130.329 20.6653 130.608 20.3028L131.133 20.8063C130.96 21.0681 130.728 21.2695 130.435 21.4172C130.143 21.5649 129.818 21.6388 129.459 21.6388ZM129.346 17.8858C129.081 17.8858 128.862 17.9798 128.702 18.1678C128.536 18.3558 128.437 18.6176 128.39 18.96H130.243V18.8862C130.223 18.5572 130.137 18.3088 129.984 18.141C129.824 17.9664 129.612 17.8858 129.346 17.8858Z" fill="white" />
            <path d="M135.376 20.8525C135.622 20.8525 135.821 20.7787 135.98 20.6377C136.14 20.4967 136.226 20.3155 136.239 20.1073H137.155C137.142 20.3826 137.063 20.6377 136.903 20.8727C136.744 21.1077 136.531 21.2956 136.259 21.4366C135.987 21.5709 135.701 21.6448 135.389 21.6448C134.785 21.6448 134.3 21.4433 133.948 21.0472C133.596 20.6511 133.417 20.1006 133.417 19.4024V19.3017C133.417 18.637 133.596 18.0999 133.948 17.7038C134.3 17.301 134.778 17.0996 135.389 17.0996C135.907 17.0996 136.325 17.254 136.651 17.5561C136.976 17.8583 137.142 18.2611 137.162 18.7512H136.246C136.232 18.5028 136.153 18.2946 135.993 18.1335C135.834 17.9724 135.635 17.8918 135.389 17.8918C135.077 17.8918 134.831 18.006 134.659 18.2409C134.486 18.4692 134.4 18.825 134.4 19.295V19.4494C134.4 19.9261 134.486 20.2819 134.652 20.5101C134.805 20.7317 135.05 20.8525 135.376 20.8525Z" fill="white" />
            <path d="M140.482 17.6502C140.801 17.2742 141.199 17.093 141.691 17.093C142.614 17.093 143.085 17.6233 143.098 18.6908V21.5508H142.122V18.7311C142.122 18.429 142.056 18.2141 141.93 18.0866C141.804 17.959 141.611 17.8986 141.359 17.8986C140.967 17.8986 140.675 18.0732 140.489 18.4223V21.5508H139.513V15.334H140.489V17.6502H140.482Z" fill="white" />
            <path d="M146.625 17.1733L146.652 17.6769C146.97 17.2875 147.395 17.0928 147.913 17.0928C148.816 17.0928 149.274 17.6164 149.288 18.6571V21.5506H148.312V18.7108C148.312 18.4355 148.252 18.2274 148.132 18.0931C148.013 17.9588 147.82 17.8917 147.548 17.8917C147.156 17.8917 146.864 18.073 146.671 18.4288V21.5439H145.695V17.1599H146.625V17.1733Z" fill="white" />
            <path d="M151.738 19.3217C151.738 18.892 151.825 18.5026 151.991 18.1602C152.157 17.8178 152.396 17.5493 152.701 17.368C153.007 17.1868 153.359 17.0928 153.75 17.0928C154.335 17.0928 154.806 17.2808 155.171 17.6634C155.537 18.0461 155.736 18.5496 155.762 19.1807L155.769 19.409C155.769 19.8387 155.689 20.2281 155.523 20.5705C155.357 20.9128 155.125 21.1747 154.819 21.3627C154.514 21.5506 154.162 21.6446 153.764 21.6446C153.153 21.6446 152.661 21.4365 152.296 21.027C151.931 20.6174 151.745 20.0669 151.745 19.3821V19.3217H151.738ZM152.714 19.409C152.714 19.8588 152.807 20.2146 152.993 20.4697C153.179 20.7249 153.432 20.8524 153.764 20.8524C154.089 20.8524 154.348 20.7249 154.527 20.463C154.713 20.2012 154.799 19.8252 154.799 19.3284C154.799 18.8853 154.706 18.5362 154.514 18.2744C154.328 18.0125 154.069 17.885 153.75 17.885C153.432 17.885 153.179 18.0125 152.993 18.2677C152.807 18.5228 152.714 18.9055 152.714 19.409Z" fill="white" />
            <path d="M159.268 21.5577H158.292V15.3408H159.268V21.5577Z" fill="white" />
            <path d="M161.784 19.3217C161.784 18.892 161.871 18.5026 162.037 18.1602C162.203 17.8178 162.442 17.5493 162.747 17.368C163.052 17.1868 163.404 17.0928 163.796 17.0928C164.381 17.0928 164.852 17.2808 165.217 17.6634C165.582 18.0461 165.782 18.5496 165.808 19.1807L165.815 19.409C165.815 19.8387 165.735 20.2281 165.569 20.5705C165.403 20.9128 165.171 21.1747 164.865 21.3627C164.56 21.5506 164.208 21.6446 163.809 21.6446C163.199 21.6446 162.707 21.4365 162.342 21.027C161.977 20.6174 161.791 20.0669 161.791 19.3821V19.3217H161.784ZM162.754 19.409C162.754 19.8588 162.847 20.2146 163.033 20.4697C163.218 20.7249 163.471 20.8524 163.803 20.8524C164.128 20.8524 164.387 20.7249 164.566 20.463C164.752 20.2012 164.839 19.8252 164.839 19.3284C164.839 18.8853 164.746 18.5362 164.553 18.2744C164.367 18.0125 164.108 17.885 163.79 17.885C163.471 17.885 163.218 18.0125 163.033 18.2677C162.853 18.5228 162.754 18.9055 162.754 19.409Z" fill="white" />
            <path d="M168.106 19.3351C168.106 18.6571 168.266 18.1133 168.578 17.7037C168.897 17.2942 169.315 17.0928 169.84 17.0928C170.331 17.0928 170.723 17.2673 171.002 17.6164L171.048 17.1733H171.925V21.4231C171.925 22.0005 171.745 22.4503 171.393 22.786C171.041 23.1149 170.563 23.2828 169.959 23.2828C169.64 23.2828 169.328 23.2156 169.029 23.0814C168.724 22.9471 168.498 22.7725 168.339 22.5577L168.797 21.9669C169.096 22.3227 169.468 22.504 169.899 22.504C170.225 22.504 170.477 22.4167 170.663 22.2354C170.849 22.0609 170.942 21.7991 170.942 21.4567V21.1613C170.663 21.4768 170.284 21.6379 169.82 21.6379C169.315 21.6379 168.897 21.4365 168.578 21.027C168.266 20.6174 168.106 20.0535 168.106 19.3351ZM169.076 19.4157C169.076 19.8588 169.162 20.2012 169.342 20.4563C169.521 20.7114 169.767 20.8323 170.079 20.8323C170.47 20.8323 170.763 20.6644 170.955 20.322V18.3952C170.769 18.0663 170.484 17.8984 170.092 17.8984C169.773 17.8984 169.521 18.026 169.348 18.2811C169.162 18.5429 169.076 18.9189 169.076 19.4157Z" fill="white" />
            <path d="M176.081 20.148L176.964 17.1738H178L176.28 22.2158C176.014 22.9543 175.569 23.3235 174.932 23.3235C174.793 23.3235 174.633 23.2967 174.461 23.2497V22.4843L174.646 22.4978C174.892 22.4978 175.078 22.4508 175.204 22.3635C175.33 22.2762 175.423 22.1218 175.496 21.907L175.636 21.531L174.115 17.1738H175.164L176.081 20.148Z" fill="white" />
            <path d="M123.529 34.9183V31.266H122.872V30.5342H123.529V30.1314C123.529 29.648 123.662 29.2721 123.928 29.0035C124.193 28.735 124.565 28.6074 125.05 28.6074C125.223 28.6074 125.402 28.6343 125.595 28.6813L125.568 29.4533C125.462 29.4332 125.336 29.4198 125.196 29.4198C124.731 29.4198 124.499 29.6615 124.499 30.1448V30.5342H125.382V31.266H124.499V34.9183H123.529Z" fill="white" />
            <path d="M129.997 31.4332C129.871 31.4131 129.738 31.3996 129.599 31.3996C129.154 31.3996 128.855 31.5742 128.696 31.9166V34.9109H127.72V30.5269H128.649L128.676 31.0169C128.908 30.6343 129.234 30.4463 129.652 30.4463C129.792 30.4463 129.904 30.4664 129.997 30.5V31.4332Z" fill="white" />
            <path d="M131.93 32.6821C131.93 32.2524 132.016 31.863 132.182 31.5206C132.348 31.1782 132.587 30.9097 132.893 30.7284C133.198 30.5471 133.55 30.4531 133.942 30.4531C134.526 30.4531 134.998 30.6411 135.363 31.0238C135.728 31.4065 135.927 31.91 135.954 32.5411L135.96 32.7693C135.96 33.199 135.881 33.5884 135.715 33.9308C135.549 34.2732 135.316 34.535 135.011 34.723C134.705 34.911 134.353 35.005 133.955 35.005C133.344 35.005 132.853 34.7969 132.487 34.3873C132.122 33.9778 131.936 33.4273 131.936 32.7425V32.6821H131.93ZM132.899 32.7693C132.899 33.2192 132.992 33.575 133.178 33.8301C133.364 34.0852 133.616 34.2128 133.948 34.2128C134.274 34.2128 134.533 34.0852 134.712 33.8234C134.898 33.5616 134.984 33.1856 134.984 32.6888C134.984 32.2457 134.891 31.8966 134.699 31.6347C134.513 31.3729 134.254 31.2453 133.935 31.2453C133.616 31.2453 133.364 31.3729 133.178 31.628C132.999 31.8831 132.899 32.2591 132.899 32.7693Z" fill="white" />
            <path d="M139.327 30.5337L139.354 31.0372C139.672 30.6478 140.097 30.4531 140.615 30.4531C141.518 30.4531 141.977 30.9768 141.99 32.0174V34.911H141.014V32.0711C141.014 31.7959 140.954 31.5877 140.834 31.4535C140.715 31.3192 140.522 31.252 140.25 31.252C139.858 31.252 139.566 31.4333 139.374 31.7891V34.9043H138.397V30.5203H139.327V30.5337Z" fill="white" />
            <path d="M145.841 29.4668V30.5343H146.605V31.2661H145.841V33.7098C145.841 33.8777 145.874 33.9985 145.941 34.0724C146.007 34.1462 146.12 34.1865 146.292 34.1865C146.405 34.1865 146.518 34.1731 146.631 34.1462V34.9049C146.412 34.9653 146.193 34.9989 145.987 34.9989C145.237 34.9989 144.865 34.5826 144.865 33.7501V31.2661H144.154V30.5343H144.865V29.4668H145.841Z" fill="white" />
            <path d="M148.995 29.3996C148.995 29.2452 149.042 29.1244 149.135 29.0237C149.228 28.923 149.367 28.876 149.54 28.876C149.719 28.876 149.852 28.923 149.945 29.0237C150.038 29.1244 150.091 29.2519 150.091 29.3996C150.091 29.5473 150.044 29.6682 149.945 29.7689C149.845 29.8696 149.712 29.9166 149.54 29.9166C149.36 29.9166 149.228 29.8696 149.135 29.7689C149.042 29.6682 148.995 29.5473 148.995 29.3996ZM150.024 34.9183H149.048V30.5342H150.024V34.9183Z" fill="white" />
            <path d="M154.633 34.9982C154.016 34.9982 153.518 34.8035 153.132 34.4074C152.747 34.018 152.555 33.4943 152.555 32.8364V32.7155C152.555 32.2791 152.641 31.8897 152.807 31.5406C152.973 31.1982 153.205 30.9297 153.511 30.735C153.816 30.5403 154.148 30.4463 154.52 30.4463C155.111 30.4463 155.563 30.6343 155.888 31.017C156.207 31.3996 156.373 31.9367 156.373 32.6349V33.0311H153.544C153.571 33.3936 153.69 33.6756 153.903 33.8904C154.115 34.0985 154.374 34.2059 154.693 34.2059C155.138 34.2059 155.503 34.0247 155.782 33.6621L156.306 34.1657C156.134 34.4275 155.901 34.6289 155.609 34.7766C155.317 34.9243 154.992 34.9982 154.633 34.9982ZM154.52 31.2452C154.255 31.2452 154.035 31.3392 153.876 31.5272C153.71 31.7152 153.611 31.977 153.564 32.3194H155.417V32.2456C155.397 31.9166 155.31 31.6682 155.158 31.5003C155.005 31.3325 154.786 31.2452 154.52 31.2452Z" fill="white" />
            <path d="M161.034 31.4332C160.908 31.4131 160.775 31.3996 160.636 31.3996C160.191 31.3996 159.892 31.5742 159.733 31.9166V34.9109H158.757V30.5269H159.686L159.713 31.0169C159.945 30.6343 160.271 30.4463 160.689 30.4463C160.829 30.4463 160.941 30.4664 161.034 30.5V31.4332Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_1030_1706">
                <rect width="178" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>,
    'AppLogo'
)


export default AppLogo;