import { Button, Typography, darken, styled } from "@mui/material"

export const StyledTitleText = styled(Typography)(({ theme }) => ({
    fontSize: 32,
    fontWeight: 700
}))

export const StyledSubTitleText = styled(Typography)(({ theme }) => ({
    fontSize: 14,
}))

export const StyledValueText = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '27px',
}))

export const StyledPrimaryButton = styled(Button)(({ theme }) => ({
    color: '#0C4992',
    fontSize: '18px',
    fontWeight: '500',
    width: '127px',
    height: '50px',
    borderRadius: '32px',
    backgroundColor: '#FACB57',
    '&:hover': {
        backgroundColor: darken('#FACB57', 0.1),
    },
}))

export const StyledDiableButton = styled(Button)(({ theme }) => ({
    color: '#333333',
    fontSize: '18px',
    fontWeight: '500',
    width: '127px',
    height: '50px',
    borderRadius: '32px',
    backgroundColor: '#f1f1f1',
    '&:hover': {
        backgroundColor: darken('#f1f1f1', 0.1),
    },
}))
