import { BorderColor } from "@mui/icons-material";
import { Button, darken, styled } from "@mui/material";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";


export const StyledNoButton = styled(StyledSubmitButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: darken('#FFF', 0.1),
    },
}))

export const StyledYesButton = styled(StyledSubmitButton)(({ theme }) => ({

}))