import axios, { AxiosResponse } from "axios";

const baseUrlContact = `${process.env.REACT_APP_API_BASE_URL}/v1/contacts`

const postContactUs = (firstName: string, lastName: string, email: string, phoneNo: string, inquirtyType: string, message: string): Promise<AxiosResponse> => {
    return axios.post(baseUrlContact, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phoneNo,
        type: inquirtyType,
        message: message,
    })
}

export {
    postContactUs
}