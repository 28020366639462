import { useEffect, useRef, useState } from "react"
import { MyEntity, ResultPage } from "../../types"
import { getEntitySanctions, getMyEntitySanctions } from "../../api/sanctions"
import SanctionSearchLayout from "../../components/layouts/SanctionSearchLayout"
import { useNavigate, useSearchParams } from "react-router-dom"
import MyIndividualResultContent from "../../components/contents/MyIndividualResultContent"
import MyEntityResultContent from "../../components/contents/MyEntityResultContent"

const MyEntitySeaarchPage = () => {
    const source = 'malaysia'
    const type = 'entities'

    const navigate = useNavigate()
    const [seachParam] = useSearchParams()

    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const [result, setResult] = useState<ResultPage<MyEntity>>()

    const rendered = useRef({ updateUrl: false })

    useEffect(() => {
        const pageParam = seachParam.get('page')
        const limitParam = seachParam.get('limit')
        const keywordParam = seachParam.get('keyword')

        if (pageParam != null && limitParam != null && keywordParam !== null) {
            const page = pageParam == null ? 1 : + pageParam
            const limit = limitParam == null ? 10 : +limitParam
            const keyword = limitParam == null ? '' : keywordParam

            getMyEntitySanctions(page, limit, keyword, 'good')
                .then((res) => {
                    console.log(res.data)
                    setResult(res.data.data)
                })
        }
    }, [seachParam])

    useEffect(() => {
        if (rendered.current['updateUrl']) {
            handleUpdateUrl()
        }
        rendered.current['updateUrl'] = true
    }, [limit, page])

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    const handleLimitChange = (limit: number) => {
        setLimit(limit)
        setPage(1)
    }

    const handleKeywordChange = (keyword: string) => {
        setKeyword(keyword)
    }

    const handleSubmitSearch = () => {
        console.log('update url')
        handleUpdateUrl()
    }

    const handleUpdateUrl = () => {
        navigate(`/sanctions/${source}/${type}?keyword=${keyword}&page=${page}&limit=${limit}`)
    }

    return (
        <SanctionSearchLayout
            source={source}
            type={type}
            page={page}
            firstElement={result?.firstElement}
            lastElement={result?.lastElement}
            totalElement={result?.totalElement}
            totalPage={result?.totalPage}
            result={result}
            pageChange={handlePageChange}
            limitChange={handleLimitChange}
            keywordChange={handleKeywordChange}
            submitSearch={handleSubmitSearch}
        //onSearch={handleSearch}
        >
            {(result !== undefined) && (
                <MyEntityResultContent result={result} />
            )}
        </SanctionSearchLayout>
    )
}

export default MyEntitySeaarchPage