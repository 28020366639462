import { Stack, Typography } from "@mui/material";
import CheckIcon from "../../icons/CheckIcon";
import CheckSquareIcon from "../../icons/CheckSquareIcon";

interface CheckedListItemProps {
    children?: React.ReactNode;
}

const CheckedSquareListItem = (props: CheckedListItemProps) => {
    const { children } = props
    return (
        <Stack direction={'row'} spacing={1} pt={1} sx={{ alignItems: 'center' }}>
            <CheckSquareIcon sx={{height: 24, width: 24}} />
            <Typography fontSize={20} fontWeight={500} color={'#333333'}>{children}</Typography>
        </Stack>
    )
}

export default CheckedSquareListItem