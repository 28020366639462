import { Button, InputBase, Paper, Tab, Tabs, Typography, darken, styled } from "@mui/material";

export const StyledTitleText = styled(Typography)(({ theme }) => ({
    fontSize: 32,
    fontWeight: 700
}))

export const StyledValueText = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '27px',
}))

export const StyledSubTitleText = styled(Typography)(({ theme }) => ({
    fontSize: 14,
}))

export const StyledRedeepButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
    fontSize: '18px',
    fontWeight: '500',
    width: '127px',
    height: '50px',
    borderRadius: '32px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    border: '1px solid #fff',
    '&:hover': {
        color: '#0C4992',
        backgroundColor: darken('#fff', 0.1),
    },
}))

export const StyledPrimaryButton = styled(Button)(({ theme }) => ({
    color: '#0C4992',
    fontSize: '18px',
    fontWeight: '500',
    width: '127px',
    height: '50px',
    borderRadius: '32px',
    backgroundColor: '#FACB57',
    '&:hover': {
        backgroundColor: darken('#FACB57', 0.1),
    },
}))

export const StyledDiableButton = styled(Button)(({ theme }) => ({
    color: '#333333',
    fontSize: '18px',
    fontWeight: '500',
    width: '127px',
    height: '50px',
    borderRadius: '32px',
    backgroundColor: '#f1f1f1',
    '&:hover': {
        backgroundColor: darken('#f1f1f1', 0.1),
    },
}))

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        height: '4px',
        backgroundColor: '#0C4992',
        borderRadius: '16px',
        transform: 'translateY(-100%)',
    }
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: 20,
    fontWeight: 500,
    marginBottom: '5px',
    borderBottom: '1px solid #617082',
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 549,
    borderRadius: "32px",
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#D9D9D9',
    backgroundColor: '#fff'
}))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    flex: 1,
    height: '50px',
    //borderRadius: "32px 0 0 32px",
    // backgroundColor: '#fff'
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    height: '50px',
    color: '#ffffff',
    backgroundColor: '#0C4992',
    borderRadius: '0  32px 32px 0',
    '&:hover': {
        backgroundColor: darken('#0C4992', 0.1),
    },
}))
