import { useEffect, useState } from "react"
import { Box, Stack } from "@mui/material"
import ContentWrapper from "../../components/elements/ContentWrapper"
import MainLayout from "../../components/layouts/MainLayout"
import { StyledPaperReport, StyledReportLabel, StyledTab, StyledTabs } from "./styles"
import TabPanel from "../../components/elements/TabPanel"
import DownloadAllButton from "../../components/elements/DownloadAllButton"
import DownloadYearlySelection from "../../components/elements/DownloadYearlySelection"
import DownloadDocIcon from "../../components/icons/DownloadDocIcon"
import DownloadButton from "../../components/elements/DownloadButton"
import { getSanctionHistoricalReport, getSanctionHitsReport, postSanctionHistoricalMonthlyReport, postSanctionHistoricalYearlyReport, postSanctionHitsMonthlyReport, postSanctionHitsYearlyReport } from "../../api/subscription"
import { SummaryResult } from "../../types"
import LoadingPlaceHolder from "../../components/elements/LoadingPlaceHolder"

const ReportPage = () => {

    const [tabValue, setTabValue] = useState(0)
    const [year, setYear] = useState('2024')
    const [years, setYears] = useState<string[]>([])
    const [reports, setReport] = useState(['September', 'August', 'July', 'June', 'May'])

    const [sanctionHitsReports, setSanctionHitsReports] = useState()
    const [sanctionHistoricalReports, setSanctionHistoricalReports] = useState()

    const [hitsReports, setHitsReports] = useState<SummaryResult[]>([])
    const [historicalReports, setHistoricalReport] = useState<SummaryResult[]>([])

    useEffect(() => {
        getSanctionHitsReport()
            .then((res) => {
                const data = res.data.data
                const dataYear = Object.keys(data).sort((a, b) => (+b) - (+a))
                console.log(dataYear)
                setYear(dataYear[0])
                setYears(dataYear)
                setSanctionHitsReports(res.data.data)
            })
    }, [])

    useEffect(() => {
        getSanctionHistoricalReport()
            .then((res) => {
                setSanctionHistoricalReports(res.data.data)
            })
    }, [])

    useEffect(() => {
        if (sanctionHitsReports !== undefined) {
            setHitsReports(sanctionHitsReports[year])
        }
    }, [sanctionHitsReports, year])

    useEffect(() => {
        if (sanctionHistoricalReports !== undefined) {
            setHistoricalReport(sanctionHistoricalReports[year])
        }
    }, [sanctionHistoricalReports, year])

    const handleTabChange = (e: React.SyntheticEvent, value: number) => {
        setTabValue(value)
    }

    const handleSetYear = (year: string) => {
        setYear(year)
    }

    const handleDownloadHitsMonthlyReport = (year: string, month: string) => {
        return postSanctionHitsMonthlyReport(year, month)
            .then((res) => {
                let data = new Blob([res.data], { type: res.headers['content-type'] })
                let url = window.URL.createObjectURL(data)
                // window.open(url)
                let link = document.createElement('a')
                link.href = url
                link.download = "Report_Sanction_Hits_" + year + month + ".pdf"
                link.click()

            })
    }

    const handleDownloadHistoricalMonthlyReport = (year: string, month: string) => {
        return postSanctionHistoricalMonthlyReport(year, month)
            .then((res) => {
                let data = new Blob([res.data], { type: res.headers['content-type'] })
                let url = window.URL.createObjectURL(data)
                let link = document.createElement('a')
                link.href = url
                link.download = "Report_Sanction_Historical_" + year + month + ".pdf"
                link.click()
            })
    }

    const handleDownloadYearlyReport = () => {
        if (tabValue === 0) {
            return postSanctionHitsYearlyReport(year)
                .then((res) => {
                    let data = new Blob([res.data], { type: res.headers['content-type'] })
                    let url = window.URL.createObjectURL(data)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = "Report_Sanction_Hits_" + year + ".zip"
                    link.click()
                })
        } else {
            return postSanctionHistoricalYearlyReport(year)
                .then((res) => {
                    let data = new Blob([res.data], { type: res.headers['content-type'] })
                    let url = window.URL.createObjectURL(data)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = "Report_Sanction_Historical_" + year + ".zip"
                    link.click()
                })
        }
    }

    if (sanctionHitsReports === undefined || sanctionHistoricalReports === undefined) {
        return (
            <MainLayout>
                <LoadingPlaceHolder />
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <ContentWrapper>
                <Box mt={3}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <StyledTabs value={tabValue} onChange={handleTabChange}>
                            <StyledTab value={0} label="Sanction Hits Reports" sx={{ width: '250px' }} />
                            <StyledTab value={1} label="Historical Processed Transactions" sx={{ width: '350px' }} />
                        </StyledTabs>
                        <Stack direction={'row'} spacing={2}>
                            <DownloadAllButton onClick={handleDownloadYearlyReport} />
                            <DownloadYearlySelection year={year} years={years} setYear={handleSetYear} />
                        </Stack>
                    </Stack>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Stack spacing={2}>
                        {hitsReports.map((value, _index) => (
                            <StyledPaperReport variant='outlined'>
                                <Stack direction={'row'} py={4} px={5} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack direction={'row'} spacing={1}>
                                        <DownloadDocIcon />
                                        <StyledReportLabel>{value.label}</StyledReportLabel>
                                    </Stack>
                                    <DownloadButton onClick={() => handleDownloadHitsMonthlyReport(value.year, value.month)} />
                                </Stack>
                            </StyledPaperReport>
                        ))}
                    </Stack>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Stack spacing={2}>
                        {historicalReports.map((value, _index) => (
                            <StyledPaperReport variant='outlined'>
                                <Stack direction={'row'} py={4} px={5} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack direction={'row'} spacing={1}>
                                        <DownloadDocIcon />
                                        <StyledReportLabel>{value.label}</StyledReportLabel>
                                    </Stack>
                                    <DownloadButton onClick={() => handleDownloadHistoricalMonthlyReport(value.year, value.month)} />
                                </Stack>
                            </StyledPaperReport>
                        ))}
                    </Stack>
                </TabPanel>
            </ContentWrapper>
        </MainLayout>
    )
}

export default ReportPage