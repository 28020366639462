import { AppBar, Box, Button, Container, Link, Toolbar } from "@mui/material";
import AppLogo from "../AppLogo";
import UserProfileMenu from "../UserProfileMenu";
import SourceSelectMenu from "../SourceSelectMenu";
import { Link as RouterLink } from "react-router-dom";
import { StyledButton } from "./styles";
import ContactUsButton from "../ContactUsButton";

const AppHeader = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" component="nav" elevation={0} sx={{ borderRadius: '0 0 0 72px' }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters variant="dense" sx={{ minHeight: 110 }}>
                        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                            <Link component={RouterLink} to="/">
                                <AppLogo sx={{ width: "178px", height: "40px" }} />
                            </Link>
                        </Box>
                        <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                            <Box sx={{ display: "flex" }}>
                                <SourceSelectMenu source="united-nation">
                                    United Nation
                                </SourceSelectMenu>
                                <SourceSelectMenu source="malaysia">
                                    Local Malaysia
                                </SourceSelectMenu>
                                <StyledButton component={RouterLink} to="/pricing">
                                    Pricing
                                </StyledButton>
                                <StyledButton component={RouterLink} to="/api/specification">
                                    API Specification
                                </StyledButton>
                                <Box sx={{ my: 2, mx: 2 }}>
                                    <ContactUsButton />
                                </Box>
                            </Box>
                            <Box>
                                <UserProfileMenu />
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box >
    )
}

export default AppHeader;