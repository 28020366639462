import { Button, ListItemIcon, ListItemText, Menu, MenuItem, styled } from "@mui/material";
import React from "react";
import ArrowDownIcon from "../../icons/ArrowDownIcon";
import IndividualIcon from "../../icons/IndividualIcon";
import EntityIcon from "../../icons/EntityIcon";
import { Link as RouterLink } from "react-router-dom";
import { StyledMenuItem } from "./styles";

interface Props {
    source: string
    children: any
}

const SourceSelectMenu: React.FC<Props> = (props: Props) => {
    const { source, children } = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <Button
                sx={{ my: 2, mx: 2, color: 'white', display: 'flex', fontSize: '20px' }}
                endIcon={<ArrowDownIcon />}
                onClick={handleClick}>
                {children}
            </Button>
            <Menu
                id="App Menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: "407px",
                        borderRadius: "32px",
                        marginTop: "10px",
                    }
                }}
                MenuListProps={{
                    sx: {
                        py: 0
                    }
                }}
            >
                <StyledMenuItem component={RouterLink} to={`/sanctions/${source}/individuals`} onClick={handleClose}>
                    <ListItemIcon><IndividualIcon /></ListItemIcon>
                    <ListItemText>Individual</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem component={RouterLink} to={`/sanctions/${source}/entities`} onClick={handleClose}>
                    <ListItemIcon><EntityIcon /></ListItemIcon>
                    <ListItemText>Entity</ListItemText>
                </StyledMenuItem>
            </Menu>
        </>
    )
}

export default SourceSelectMenu