import { ChangeEvent, useEffect, useRef, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import ContentWrapper from "../../components/elements/ContentWrapper";
import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import MyProfileIcon from "../../components/icons/MyProfileIcon";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { StyledPaper, VisuallyHiddenInput } from "./styles";
import UserTextInputWithLabel from "../../components/elements/UserTextInputWithLabel";
import PasswordTextInput from "../../components/elements/PasswordTextInput";
import UserSubmitButton from "../../components/elements/UserSubmitButton";

import UserProfilePlaceholder from '../user_profile_placeholder.svg';
import { userDeleteProfileImage, userGetMyInfo, userGetUserProfileImage, userPatchPassword, userPatchProfileImage, userPostForgetPassword } from "../../api/users";
import SendOtpNotifyDialog from "../../components/dialogs/SendOtpNotifyDialog";
import { useNavigate } from "react-router-dom";
import WaitingProgress from "../../components/elements/WaitingProgress";



const MyProfilePage = () => {
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [profileImage, setProfileIamge] = useState<any>(UserProfilePlaceholder)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [passwordError, setPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    const [waiting, setWaiting] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const hiddleFileInput = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        userGetMyInfo()
            .then((res) => {
                const userInfo = res.data.data
                setEmail(userInfo.email)

                const profileImage = userInfo.profileImageUrl
                if (profileImage !== null) {
                    setProfileIamge(profileImage)
                }
                return res
            })
    }, [])

    const handleEditButtonClick = () => {
        console.log(hiddleFileInput.current?.value)
        hiddleFileInput.current?.click();
    }

    const handleDeleteButtonClick = () => {
        userDeleteProfileImage()
            .then((_res) => {
                console.log('delete profile image success')
                setProfileIamge(UserProfilePlaceholder)
            })
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        console.log('handle on change')

        if (files !== null && files.length > 0) {
            console.log(files[0])
            const formData = new FormData()
            formData.append('file', files[0])
            console.log(files[0])
            userPatchProfileImage(formData)
                .then((res) => {
                    setProfileIamge(res.data.data.profileImageUrl)
                    if (hiddleFileInput.current !== null) {
                        hiddleFileInput.current.value = ''
                    }
                })
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setWaiting(true)
        userPostForgetPassword(email)
            .then((_res) => {
                setWaiting(false)
                setOpenDialog(true)
            })
    }

    const hanldeCloseDialog = () => {
        setOpenDialog(false)
        navigate(`/reset-password?email=${email}`)
    }

    return (
        <MainLayout>
            <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
            <ContentWrapper>
                <Stack spacing={2}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <MyProfileIcon sx={{ width: '30px', height: '30px' }} />
                        <Typography fontSize={32} fontWeight={700}>My Profile</Typography>
                    </Stack>
                    <Stack direction={'row'} alignContent={'space-between'}>
                        <Box pr={8}>
                            <Stack sx={{ width: 136 }} spacing={1} alignItems={'center'}>
                                {/* <img width={136} height={136} src={profileImage} /> */}
                                <Avatar
                                    alt="profile image"
                                    src={profileImage}
                                    sx={{ width: 136, height: 136 }}
                                />
                                <Stack direction={'row'} spacing={1}>
                                    <IconButton onClick={handleEditButtonClick}>
                                        <EditIcon />
                                        <VisuallyHiddenInput type="file" ref={hiddleFileInput} onChange={handleChange} />
                                    </IconButton>
                                    <IconButton onClick={handleDeleteButtonClick}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Box>
                        <Stack component={'form'} spacing={5} sx={{ width: '100%' }} onSubmit={handleSubmit}>
                            <StyledPaper variant='outlined'>
                                <Box width={500}>
                                    <UserTextInputWithLabel
                                        id={'username-email'}
                                        name={'username-email'}
                                        placeholder="john.doe@gmail.com"
                                        label="Email"
                                        value={email}
                                        error={''}
                                        setValue={setEmail}
                                        disable={true}
                                    />
                                </Box>
                            </StyledPaper>
                            <Box pt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Box sx={{ width: '219px' }}>
                                    <UserSubmitButton>Change Password</UserSubmitButton>
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </ContentWrapper>
            <SendOtpNotifyDialog open={openDialog} onClose={hanldeCloseDialog} />
        </MainLayout>
    )
}

export default MyProfilePage