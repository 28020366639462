import { Paper, Tab, Tabs, Typography, styled } from "@mui/material"

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        height: '4px',
        backgroundColor: '#0C4992',
        borderRadius: '16px',
        transform: 'translateY(-100%)',
    }
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: 20,
    fontWeight: 500,
    marginBottom: '5px',
    borderBottom: '1px solid #617082',
}))

export const StyledPaperReport = styled(Paper)(({ theme }) => ({
    borderRadius: '32px',
    borderColor: '#F1F1F1',
}))

export const StyledReportLabel = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 700,
}))