import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import SearchLimitSelection from "../../elements/SearchLimitSelection/indext";
import EntityIcon from "../../icons/EntityIcon";
import IndividualIcon from "../../icons/IndividualIcon";
import { StyledButton, StyledInputBase, StyledPaper, StyledSearchPaper, StyledTabLabel } from "./styles";
import ContentWrapper from "../../elements/ContentWrapper";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../icons/SearchIcon";
import { StyledResultLabel } from "../../contents/UnIndividualResultContent/styles";
import SearchPlaceholder from "../../elements/SearchPlaceholder";
import { StyledPagination } from "../../contents/_common/styles";
import CelebrateSanctionNotFound from "../../elements/CelebrateSanctionNotFound";
import { LoadingButton } from "@mui/lab";
import LoadingPlaceHolder from "../../elements/LoadingPlaceHolder";

interface Props {
    source: string
    type: string
    page: number
    firstElement: number | undefined
    lastElement: number | undefined
    totalElement: number | undefined
    totalPage: number | undefined
    result: any
    children: any
    pageChange: (page: number) => void
    limitChange: (limt: number) => void
    keywordChange: (keyword: string) => void
    submitSearch: () => void
}

const SanctionSearchLayout: React.FC<Props> = (props: Props) => {
    const { source, type, page: currentPage, firstElement, lastElement, totalElement, totalPage, result, children,
        pageChange, limitChange, keywordChange, submitSearch } = props

    const navigate = useNavigate()

    const [keyword, setKeyword] = useState('')
    const [limit, setLimit] = useState(10)

    const [tabIndex, setTabIndex] = useState(0)
    const [placeholder, setPlaceholder] = useState('')
    const [sourceLabel, setSourceLabel] = useState('')

    const [searchFlage, setSearchFlag] = useState(false)

    const individualPlaceholder = 'Enter Individual Details'
    const entityPlaceholder = 'Enter Entity Details'

    useEffect(() => {
        if (source === 'united-nation') {
            setSourceLabel("United Nation")
        } else if (source === 'malaysia') {
            setSourceLabel("Local Malaysia")
        }

    }, [source])

    useEffect(() => {
        if (type === 'individuals') {
            setTabIndex(0)
            setPlaceholder(individualPlaceholder)
        } else if (type === 'entities') {
            setTabIndex(1)
            setPlaceholder(entityPlaceholder)
        }
    }, [type])

    const handleTabChange = (e: React.SyntheticEvent, value: number) => {
        setTabIndex(value);
        if (value === 0) {
            navigate(`/sanctions/${source}/individuals`)
        } else if (value === 1) {
            navigate(`/sanctions/${source}/entities`)
        }
    }

    const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
        keywordChange(e.target.value)
    }

    const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
        pageChange(page)
    }

    const handleLimitChange = (limit: number) => {
        setLimit(limit)
        limitChange(limit)
    }

    const handleSearchClick = () => {
        setSearchFlag(true)
        submitSearch()
    }

    return (
        <MainLayout>
            <ContentWrapper>
                <Box sx={{ mb: 2 }}>
                    <StyledSearchPaper elevation={0}>
                        <Box sx={{ mx: "auto", width: 825 }}>
                            <Box>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="primary tabs example"
                                    centered
                                >
                                    <Tab value={0}
                                        label={
                                            <StyledTabLabel>
                                                {`${sourceLabel} Individual`}
                                            </StyledTabLabel>
                                        }
                                        icon={<IndividualIcon />}
                                        iconPosition="start"
                                    />
                                    <Tab value={1}
                                        label={
                                            <StyledTabLabel>
                                                {`${sourceLabel} Entity`}
                                            </StyledTabLabel>
                                        }
                                        icon={<EntityIcon />}
                                        iconPosition="start"
                                    />
                                </Tabs>
                                <Box sx={{ pt: 3 }}>
                                    <Box sx={{ py: 5 }} display={"flex"}>
                                        <StyledPaper elevation={0}>
                                            <StyledInputBase placeholder={placeholder}
                                                value={keyword}
                                                onChange={handleSearchValueChange} />
                                            <StyledButton aria-label="directions" disableElevation
                                                onClick={handleSearchClick}
                                            >
                                                <SearchIcon />
                                            </StyledButton>
                                        </StyledPaper>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                marginLeft: 2,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography>Show</Typography>
                                            <SearchLimitSelection value={limit} setValue={handleLimitChange} />
                                            <Typography>Entries</Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </StyledSearchPaper>
                </Box>
                <Box>
                    <Box>
                        {(firstElement !== undefined) &&
                            (<Box my={2}>
                                <StyledResultLabel>Showing {firstElement} to {lastElement} of {totalElement} entries</StyledResultLabel>
                            </Box>)}
                        {(result === undefined && !searchFlage) && (<SearchPlaceholder />)}
                        {(result === undefined && searchFlage) && (<LoadingPlaceHolder />)}
                        {(result !== undefined && result.results.length > 0) && (
                            <>
                                {children}
                                <Box my={5} sx={{ justifyContent: "center", justifyItems: 'center' }}>
                                    <StyledPagination count={totalPage} page={currentPage} boundaryCount={2} size="small" color="primary" onChange={handlePageChange} />
                                </Box>
                            </>
                        )}
                        {(result !== undefined && result.results.length == 0) && (
                            <>
                                <CelebrateSanctionNotFound />
                            </>
                        )

                        }
                        {/* {(totalPage != undefined && result.results.length > 0) &&
                            (<Box my={5} sx={{ justifyContent: "center", justifyItems: 'center' }}>
                                <StyledPagination count={totalPage} page={currentPage} boundaryCount={2} size="small" color="primary" onChange={handlePageChange} />
                            </Box>)} */}
                    </Box>
                </Box>
            </ContentWrapper >
        </MainLayout >
    )
}

export default SanctionSearchLayout