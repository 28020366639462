import { Box, Stack, Typography } from "@mui/material"
import SearchLimitSelection from "../SearchLimitSelection/indext"
import { ResultPage } from "../../../types"

interface Props {
    result: ResultPage<any>
}

const PaginationDetail = (props: Props) => {
    const {result} = props
    
    return (
        <Box>Showing {result.firstElement} to {result.lastElement} of {result.totalElement} entries</Box>
    )
}

export default PaginationDetail