import React from "react";
import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";
import { DialogProps } from "../_common";

const SendOtpNotifyDialog = (props: DialogProps) => {
    const { open, onClose } = props

    const handleOkeyClick = () => {
        onClose(true)
    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5}>
                <Box sx={{ width: '100%', overflowY: 'scroll', boxSizing: 'content-box' }}>
                    <DialogContent sx={{ m: 0, p: 0 }}>
                        <Stack spacing={2}>
                            <Typography fontSize={32} fontWeight={700}>
                                Check Your Email!
                            </Typography>
                            <Typography fontSize={18} fontWeight={500}>
                                We have sent OTP code to your email.
                            </Typography>
                            <Box py={1}></Box>
                            <StyledSubmitButton variant="contained" color="secondary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleOkeyClick}
                            >
                                Okey
                            </StyledSubmitButton>
                        </Stack>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )
}

export default SendOtpNotifyDialog