import { Box, FormHelperText, Stack, Typography } from "@mui/material"
import ErrorAlertIcon from "../../icons/ErrorAlertIcon"

interface Props {
    error: string
}

const FormValidateError = (props: Props) => {
    const { error } = props

    return (
        <FormHelperText id="component-error-text" sx={{ height: 20 }}>
            <Box sx={{ display: `${error !== '' ? 'block' : 'none'}` }}>
                <Stack direction={'row'} spacing={1} >
                    <ErrorAlertIcon />
                    <Typography fontSize={'14'} fontWeight={'400'} color={'#FF0000'}>
                        {error}
                    </Typography>
                </Stack>
            </Box>
        </FormHelperText>
    )
}

export default FormValidateError