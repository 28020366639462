import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, ListItemIcon, ListItemText, Menu } from "@mui/material";
import ArrowDownIcon from "../../icons/ArrowDownIcon";
import MyProfileIcon from "../../icons/MyProfileIcon";
import MyPlanIcon from "../../icons/MyPlanIcon";
import ViewReportIcon from "../../icons/ViewReportIcon";
import TermAndConIcon from "../../icons/TermAndConIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import { AvatarBadge, StyledChip, StyledMenuItem, StyledMenuLink } from "./styles";
import SanctionAlertIcon from "../../icons/SanctionAlertIcon";
import UpgradeButton from "../UpgradeButton";
import { Cookies } from "react-cookie";
import SanctionAlertDialog from "../../dialogs/SanctionsAlertDialog";
import FaqIcon from "../../icons/FqaIcon";
import { userGetMyInfo } from "../../../api/users";
import UserProfilePlaceholder from "../../images/UserProfilePlaceholder";
import { getSubsription } from "../../../api/subscription";

const cookies = new Cookies("secure_token", { path: '/' })

const UserProfileMenu = () => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openSanctionAlert, setOpenSanctionAlert] = useState(false);

    const [profileImage, setProfileIamge] = useState<any>(UserProfilePlaceholder)

    const open = Boolean(anchorEl)

    const [authenticated, setAuthenticated] = useState(false)
    const [enablePremiumFeature, setEnablePremiumFeature] = useState(false);

    useEffect(() => {
        const secureTokenExist = cookies.get("secure_token") !== undefined
        setAuthenticated(secureTokenExist)
        if (secureTokenExist) {
            userGetMyInfo()
                .then((res) => {
                    const profileImage = res.data.data.profileImageUrl
                    console.log(profileImage)
                    if (profileImage !== null) {
                        setProfileIamge(profileImage)
                    }
                })
            getSubsription()
                .then((res) => {
                    console.log(res)
                    const data = res.data.data
                    setEnablePremiumFeature("premium" === data.plan.name)
                })
        }
    }, [])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSanctionAlertClose = () => {
        setOpenSanctionAlert(false)
    }

    const handleSanctionAlertClick = () => {
        handleClose()
        setOpenSanctionAlert(true)
    }

    return (
        <Box>
            <SanctionAlertDialog open={openSanctionAlert} onClose={handleSanctionAlertClose} />
            <Button
                sx={{ p: 0, my: 2, mx: 2, display: 'flex' }}
                endIcon={<ArrowDownIcon />}
                onClick={handleClick}>
                <AvatarBadge overlap="circular" variant="dot" >
                    <Avatar src={profileImage} sx={{ width: '50px', height: '50px' }} />
                </AvatarBadge >
            </Button>
            <Menu
                id="user menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: "407px",
                        borderRadius: "32px",
                        marginTop: "10px"
                    }
                }}
                MenuListProps={{ sx: { py: 0 } }}
            >
                <StyledMenuLink to="/my-profile">
                    <StyledMenuItem onClick={handleClose}>
                        <ListItemIcon><MyProfileIcon /></ListItemIcon>
                        <ListItemText>My Profile</ListItemText>
                    </StyledMenuItem>
                </StyledMenuLink>
                <StyledMenuLink to="/my-plan">
                    <StyledMenuItem onClick={handleClose}>
                        <ListItemIcon><MyPlanIcon /></ListItemIcon>
                        <ListItemText>My Plan</ListItemText>
                    </StyledMenuItem>
                </StyledMenuLink>
                {(!enablePremiumFeature) &&
                    <StyledMenuItem onClick={handleClose} disabled>
                        <ListItemIcon><SanctionAlertIcon /></ListItemIcon>
                        <ListItemText>Sanction Alerts</ListItemText>
                        {authenticated &&
                            <UpgradeButton />
                        }
                    </StyledMenuItem>
                }
                {(enablePremiumFeature) &&
                    <StyledMenuItem onClick={handleSanctionAlertClick}>
                        <ListItemIcon><SanctionAlertIcon /></ListItemIcon>
                        <ListItemText>Sanction Alerts</ListItemText>
                        <StyledChip label="New" />
                    </StyledMenuItem>
                }
                {(!enablePremiumFeature) &&
                    <StyledMenuItem onClick={handleClose} disabled>
                        <ListItemIcon><ViewReportIcon /></ListItemIcon>
                        <ListItemText>View Reports</ListItemText>
                        {authenticated &&
                            <UpgradeButton />
                        }
                    </StyledMenuItem>
                }
                {(enablePremiumFeature) &&
                    <StyledMenuLink to="/reports">
                        <StyledMenuItem onClick={handleClose}>
                            <ListItemIcon><ViewReportIcon /></ListItemIcon>
                            <ListItemText>View Reports</ListItemText>
                            <StyledChip label="New" />
                        </StyledMenuItem>
                    </StyledMenuLink>
                }
                <StyledMenuLink to="/terms-and-conditions">
                    <StyledMenuItem onClick={handleClose} >
                        <ListItemIcon><TermAndConIcon /></ListItemIcon>
                        <ListItemText>Terms & Conditions</ListItemText>
                    </StyledMenuItem>
                </StyledMenuLink>
                <StyledMenuLink to="/faq">
                    <StyledMenuItem onClick={handleClose}>
                        <ListItemIcon><FaqIcon /></ListItemIcon>
                        <ListItemText>FAQ</ListItemText>
                    </StyledMenuItem>
                </StyledMenuLink>
                {(authenticated) &&
                    <StyledMenuLink to="/logout" >
                        <StyledMenuItem onClick={handleClose}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </StyledMenuItem>
                    </StyledMenuLink>
                }
                {(!authenticated) &&
                    <StyledMenuLink to="/login" >
                        <StyledMenuItem onClick={handleClose}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText>Login</ListItemText>
                        </StyledMenuItem>
                    </StyledMenuLink>
                }
            </Menu>
        </Box>
    )
}

export default UserProfileMenu