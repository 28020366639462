import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";


const UpgradeButton = () => {
    const navigate = useNavigate();

    const handleUpgrade = () => {
        navigate("/my-plan")
    }

    return (
        <Button size="small"
            variant="contained"
            color="secondary"
            sx={{
                borderRadius: 32,
                fontSize: 12,
                padding: '1px 10px'
            }}
            disableElevation
            onClick={handleUpgrade}
        >
            Upgrade
        </Button>
    )
}

export default UpgradeButton