import { useEffect, useState } from "react"
import { Box, Stack } from "@mui/material"
import { Invoice, ResultPage } from "../../../types"
import HistorySearchBar from "../../elements/HistorySearchBar"
import PaginationDetail from "../../elements/PaginationDetail"
import PaginationWithSearchLimit from "../../elements/SearchLimitAndPagination"

interface Props {
    children: any
    result: ResultPage<Invoice> | undefined
    submitSearch: (keyword: string, page: number, limit: number) => void
}

const InvoiceListAndSearch = (props: Props) => {
    const { children, result, submitSearch } = props

    const [keyword, setKeyword] = useState('')
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    useEffect(() => {
        submitSearch(keyword, page, limit);
    }, [limit, page])

    const handleLimitChange = (limit: number) => {
        setLimit(limit)
    }

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    return (
        <Box mb={3}>
            <Stack spacing={2}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <HistorySearchBar
                        keyword={keyword}
                        placeholder={'Enter Invoice Details'}
                        onKeywordChange={(keyword) => setKeyword(keyword)}
                        onSubmit={() => submitSearch(keyword, page, limit)}
                    />

                </Stack>
                {(result !== undefined) && (
                    <>
                        <PaginationDetail result={result} />
                        <Box sx={{ minHeight: 355 }}>
                            {children}
                        </Box>
                        <PaginationWithSearchLimit
                            limit={limit} page={page} totalPage={result.totalPage}
                            onLimitChange={handleLimitChange}
                            onPageChange={handlePageChange}
                        />
                    </>
                )}
            </Stack>
        </Box>
    )
}

export default InvoiceListAndSearch