import { useEffect } from "react"
import { Cookies } from "react-cookie"
import { useNavigate } from "react-router-dom"


const cookies = new Cookies("secure_token", { path: '/' })

const LogoutPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        cookies.remove('secure_token')
        navigate("/")
    }, [])

    return (
        <>Logging out</>
    )
}

export default LogoutPage