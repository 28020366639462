import { Stack } from "@mui/material"
import RedCrossIcon from "../../icons/RedCrossIcon"
import { StyledWraningText } from "./styles"

interface Props {
    children: any
}

const WraningItem = ({ children }: Props) => {
    return (
        <Stack direction={'row'} spacing={2}>
            <RedCrossIcon />
            <StyledWraningText>{children}</StyledWraningText>
        </Stack>
    )
}

export default WraningItem