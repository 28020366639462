import { useState } from "react"
import WaitingProgress from "../../elements/WaitingProgress"
import DialogHeaderLayout from "../../layouts/DialogHeaderLayout"
import { DialogProps } from "../_common"
import PlusCircleIcon from "../../icons/PlusCircleIcon"
import PremiumPlanDetailCard from "../../cards/PremiumPlanDetailCard"
import { Stack, Typography } from "@mui/material"
import JoinNowButton from "../../elements/JoinNowButton"
import { Link } from "react-router-dom"
import TermAndConditionLink from "../../elements/TermAndConditionLink"

const PremiumPlanDialog = (props: DialogProps) => {
    const { open, onClose } = props

    const [waiting, setWaiting] = useState(false)
    const [premiumType, setPremiumType] = useState('monthly')

    const handleJoinNowClick = (plan: string) => {
        onClose(true)
    }

    return (
        <DialogHeaderLayout icon={<PlusCircleIcon />} title="Premium" open={open} onClose={onClose}>
            <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
            <PremiumPlanDetailCard value={premiumType} setValue={setPremiumType} />
            <Stack mt={3} py={2} spacing={1} textAlign={'center'}>
                <JoinNowButton onClick={() => handleJoinNowClick('premium')} />
                <TermAndConditionLink />
            </Stack>
        </DialogHeaderLayout>
    )
}

export default PremiumPlanDialog