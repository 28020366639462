import { Box, Grid, Paper, Stack, Typography } from "@mui/material"
import CheckedCircleListItem from "../../elements/CheckCircleListItem"

const BasicPlanContent = () => {
    return (
        <Box>
            <Stack direction={'column'}>
                <CheckedCircleListItem>For Company & Individual</CheckedCircleListItem>
                <CheckedCircleListItem>Complimentary with 1000 API requests</CheckedCircleListItem>
            </Stack>
            <Paper elevation={0} sx={{ backgroundColor: '#F9F9F9', borderRadius: '25px', marginTop: 1 }}>
                <Stack p={3}>
                    <Typography fontSize={18} fontWeight={500}>For more than 1000 API requests usage</Typography>
                    <Grid container spacing={2} py={2}>
                        <Grid item xs={4}>
                            <Typography fontSize={14}>+</Typography>
                            <Typography fontSize={16} fontWeight={700}>USD 0.05/request</Typography>
                            <Typography fontSize={14}>UN & MY </Typography>
                            <Typography fontSize={14}>Sanction Check</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography fontSize={14}>+</Typography>
                            <Typography fontSize={16} fontWeight={700}>USD 0.03/request</Typography>
                            <Typography fontSize={14}>UN Sanction Check</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography fontSize={14}>+</Typography>
                            <Typography fontSize={16} fontWeight={700}>USD 0.03/request</Typography>
                            <Typography fontSize={14}>MY Sanction Check</Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Paper>
        </Box>
    )
}

export default BasicPlanContent