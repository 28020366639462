import { useEffect, useRef, useState } from "react"
import { ResultPage, UnEntity } from "../../types"
import { getEntitySanctions, getUnEntitySanctions } from "../../api/sanctions"
import UnEntityResultContent from "../../components/contents/UnEntityResultContent"
import { useNavigate, useSearchParams } from "react-router-dom"
import SanctionSearchLayout from "../../components/layouts/SanctionSearchLayout"

const UnEntitySearchPage = () => {
    const source = 'united-nation'
    const type = 'entities'

    const navigate = useNavigate()
    const [seachParam] = useSearchParams()

    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [filterQuality, setFilterQuality] = useState('all')

    const [result, setResult] = useState<ResultPage<UnEntity>>()

    const rendered = useRef({ updateUrl: false })

    useEffect(() => {
        const pageParam = seachParam.get('page')
        const limitParam = seachParam.get('limit')
        const keywordParam = seachParam.get('keyword')

        if (pageParam !== null && limitParam != null && keywordParam !== null) {
            const page = pageParam == null ? 1 : +pageParam;
            const limit = limitParam == null ? 10 : +limitParam;
            const keyword = keywordParam == null ? '' : keywordParam;

            getUnEntitySanctions(page, limit, keyword, 'good')
                .then((res) => {
                    console.log(res.data)
                    setResult(res.data.data)
                })
        }
    }, [seachParam])

    useEffect(() => {
        if (rendered.current['updateUrl']) {
            handleUpdateUrl()
        }
        rendered.current['updateUrl'] = true
    }, [limit, page, filterQuality])

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    const handleLimitChange = (limit: number) => {
        setLimit(limit)
        setPage(1)
    }

    const handleFilterByQuality = (quality: string) => {
        setFilterQuality(quality)
        setPage(1)
    }

    const handleKeywordChange = (keyword: string) => {
        setKeyword(keyword)
    }

    const handleSubmitSearch = () => {
        handleUpdateUrl()
    }

    const handleUpdateUrl = () => {
        navigate(`/sanctions/${source}/${type}?keyword=${keyword}&page=${page}&limit=${limit}&quality=${filterQuality}`)
    }

    return (
        <SanctionSearchLayout
            source={source}
            type={type}
            page={page}
            firstElement={result?.firstElement}
            lastElement={result?.lastElement}
            totalElement={result?.totalElement}
            totalPage={result?.totalPage}
            result={result}
            pageChange={handlePageChange}
            limitChange={handleLimitChange}
            keywordChange={handleKeywordChange}
            submitSearch={handleSubmitSearch}
        >
            {(result !== undefined) && (
                <UnEntityResultContent result={result} />
            )}
        </SanctionSearchLayout>
    )
}

export default UnEntitySearchPage