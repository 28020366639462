import { createSvgIcon } from "@mui/material";
import React from "react";

const MailIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5431 4.8H4.45737C3.69995 4.8 3.08594 5.41401 3.08594 6.17143V17.8286C3.08594 18.586 3.69995 19.2 4.45737 19.2H19.5431C20.3005 19.2 20.9145 18.586 20.9145 17.8286V6.17143C20.9145 5.41401 20.3005 4.8 19.5431 4.8Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.08594 6.51407L11.1225 13.3712C11.3689 13.5765 11.6795 13.6889 12.0002 13.6889C12.3209 13.6889 12.6315 13.5765 12.8779 13.3712L20.9145 6.51407" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    , 'MailIcon'
)

export default MailIcon