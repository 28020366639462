import { Typography } from "@mui/material"
import BasicPlanContent from "../BasicPlanContent"

const BasicPlanWithPriceContent = () => {
    return(
        <>
            <Typography fontSize={18} fontWeight={700} color={'#0C4992'}>USD 999 Joining Fee</Typography>
            <BasicPlanContent />
        </>
    )
}

export default BasicPlanWithPriceContent