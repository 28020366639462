import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";
import PasswordTextInput from "../../elements/PasswordTextInput";
import WaitingProgress from "../../elements/WaitingProgress";
import { postCustomerPassword } from "../../../api/customer";
import { oauth2GetTokenByPassword } from "../../../api/oauth2";
import { userPatchPassword, userPostForgetPassword } from "../../../api/users";


interface Props {
    sessionId: string
    open: boolean
    onClose: (success: boolean) => void;
}

const SetPasswordDialog = (props: Props) => {
    const { sessionId, open, onClose } = props
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [originError, setOriginError] = useState('')
    const [confirmError, setConfirmError] = useState('')

    const [waiting, setWaiting] = useState(false)

    const delayOnError = (error: string) => {
        setTimeout(() => {
            setWaiting(false)
            setOriginError(error)
        }, 100);
    }

    const handleSetConfirmClick = () => {
        setOriginError('')
        setConfirmError('')
        if (password === '') {
            setOriginError('This field is required.')
        }
        else if (confirmPassword === '') {
            setConfirmError('This field is required.')
        }
        else if (password !== confirmPassword) {
            setConfirmError('Password does not match.')
        }
        else {
            setWaiting(true)
            userPatchPassword(password)
                .then((res) => {
                    if (res.data.status === 200) {
                        console.log('Set password success')
                        setWaiting(false)
                        onClose(true)
                    }
                })
                .catch((err) => {
                    console.error(err.response.data.message)
                    delayOnError(err.response.data.message)
                })
        }

    }

    return (
        <Dialog open={open}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5}>
                <Box sx={{ width: '100%', overflowY: 'scroll', boxSizing: 'content-box' }}>
                    <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
                    <DialogContent sx={{ m: 0, p: 0 }}>
                        <Stack spacing={2}>
                            <Typography fontSize={32} fontWeight={700}>
                                Set your password
                            </Typography>
                            <Typography fontSize={18} fontWeight={500}>
                                Your email has been verified successfully! Set your password now.
                            </Typography>
                            <Box py={1}>
                                <Stack spacing={2}>
                                    <Box>
                                        <PasswordTextInput
                                            label="Password"
                                            id="origin-password-input"
                                            name="origin-password"
                                            placeholder="Password"
                                            error={originError}
                                            clearError={() => setOriginError('')}
                                            value={password}
                                            setValue={setPassword}
                                        />
                                    </Box>
                                    <Box>
                                        <PasswordTextInput
                                            label="Confirmed Password"
                                            id="confirm-password-input"
                                            name="confirm-password"
                                            placeholder="Password"
                                            error={confirmError}
                                            clearError={() => setConfirmError('')}
                                            value={confirmPassword}
                                            setValue={setConfirmPassword}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                            <StyledSubmitButton variant="contained" color="secondary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleSetConfirmClick}
                            >
                                Set Confirm
                            </StyledSubmitButton>
                        </Stack>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )

}

export default SetPasswordDialog