import { useState } from "react"
import { Box, IconButton, Paper, Stack } from "@mui/material"
import { StyledPrimaryButton, StyledSubTitleText, StyledTitleText, StyledValueText } from "./styles"
import RefreshIcon from "../../icons/RefreshIcon"
import GenerateKeyDialog from "../../dialogs/GenerateKeyDialog"
import RedeemDialog from "../../dialogs/RedeemDialog"
import TopUpCreditDialog from "../../dialogs/TopUpCreditDialog"
import RedeemSuccessDialog from "../../dialogs/RedeemSuccessDialog"
import { getSubsription, redeemToken, topupCredit, } from "../../../api/subscription"
import { Subscription } from "../../../types"

interface Props {
    subscription: Subscription
    setSubscription: (subscription: Subscription) => void
}

const MyCreditCard = (props: Props) => {
    const { subscription, setSubscription } = props

    // const [credit, setCredit] = useState<Credit>()

    const [openGenerateKeyDialog, setOpenGenerateKeyDialog] = useState(false)
    const [openRedeemDialog, setOpenRedeemDialog] = useState(false)
    const [openRedeemSuccessDialog, setOpenRedeemSuccessDialog] = useState(false)
    const [openTopUpCreditDialog, setOpenTopUpCreditDialog] = useState(false)

    // useEffect(() => {
    //     getCredits(subscription.id)
    //         .then((res) => {
    //             setCredit(res.data.data)
    //         })
    // }, [subscription])

    // useEffect(() => {
    //     getApiKey(subscription.id)
    //         .then((res) => {
    //             setApiKey(res.data.data.key)
    //         })
    // }, [subscription])

    const handleGenerateKeyDialogClose = (success: boolean) => {
        setOpenGenerateKeyDialog(false)
        if (success) {
            getSubsription()
                .then((res) => {
                    setSubscription(res.data.data)
                })
        }
    }

    const handleSubmitGenerateKey = () => {
        setOpenGenerateKeyDialog(false)
    }

    const handleRedeemDialogClose = (success: boolean) => {
        setOpenRedeemDialog(false)
        if (success) {
            setOpenRedeemSuccessDialog(true)
        }
    }

    const handleTopUpCreditDialogClose = (success: boolean) => {
        setOpenTopUpCreditDialog(false)
    }

    const handleTopUpCredit = (amount: number): Promise<any> => {
        return topupCredit(subscription.id, amount)
            .then((res) => {
                window.location.replace(res.data.data.paymentUrl)
                //setSubscription(res.data.data)
            })
    }

    const handleRedeemSubmit = (code: string): Promise<any> => {
        return redeemToken(subscription.id, code)
            .then((res) => {
                setSubscription(res.data.data)
            })
    }

    return (
        <>
            <Paper elevation={0} sx={{ height: 350, borderRadius: '32px', backgroundImage: `url(/img/my-credit-bg.png)` }} variant="elevation">
                <Box p={5} sx={{ height: '270px' }}>
                    <Stack height='100%' direction={'column'} justifyContent={'space-between'} sx={{ color: '#ffffff' }}>
                        <Box>
                            <StyledTitleText>My Credit</StyledTitleText>
                            <StyledValueText>USD {subscription.creditBalance.toFixed(2)}</StyledValueText>
                        </Box>
                        <Stack direction={'column'} spacing={0.2} pt={1}>
                            <StyledSubTitleText>API Key</StyledSubTitleText>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <StyledValueText>{subscription.token}</StyledValueText>
                                <IconButton onClick={() => setOpenGenerateKeyDialog(true)}>
                                    <RefreshIcon sx={{ height: 16, width: 16 }} />
                                </IconButton>
                            </Stack>
                            <Box pt={2}>
                                <Stack direction={'row'} justifyContent={'space-between'} >
                                    <Stack spacing={0.2}>
                                        <StyledSubTitleText>Tokens Left</StyledSubTitleText>
                                        <StyledValueText>{subscription.tokenRemain}</StyledValueText>
                                    </Stack>
                                    <Stack direction={'row'} spacing={2}>
                                        {/* <StyledRedeepButton
                                            variant="outlined"
                                            disableElevation
                                            onClick={() => setOpenRedeemDialog(true)}>
                                            Redeem
                                        </StyledRedeepButton> */}
                                        <StyledPrimaryButton
                                            variant="contained"
                                            disableElevation
                                            onClick={() => setOpenTopUpCreditDialog(true)}>
                                            Top Up
                                        </StyledPrimaryButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
            <GenerateKeyDialog subscription={subscription} open={openGenerateKeyDialog} onClose={handleGenerateKeyDialogClose} />
            <RedeemDialog onSubmit={handleRedeemSubmit} open={openRedeemDialog} onClose={handleRedeemDialogClose} />
            <RedeemSuccessDialog open={openRedeemSuccessDialog} onClose={() => setOpenRedeemSuccessDialog(false)} />
            <TopUpCreditDialog onTopUp={handleTopUpCredit} open={openTopUpCreditDialog} onClose={handleTopUpCreditDialogClose} />
        </>

    )
}

export default MyCreditCard