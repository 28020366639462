import React from "react";

export const dataOf = (value: string): string => {
    return (value !== null && value !== '') ? value : '-'
}

export const rowClassOf = (index: number, rowCount: number, rowNumber: number) => {
    let className = '';
    if (rowCount > 1) {
        if (index === 0) {
            className = 'first-sub-row'
        } else if (index === rowCount - 1) {
            className = 'last-sub-row'
        } else {
            className = 'middle-sub-row'
        }
    }
    if (rowNumber % 2 == 0) {
        className += ' even'
    } else {
        className += ' odd'
    }
    return className
}