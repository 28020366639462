import { Box, Paper, Stack, Typography } from "@mui/material"

interface Props {
    title: string
    children: any
}

const SanctionDetailCard = (props: Props) => {
    const { title, children } = props

    return (
        <Paper variant='outlined' sx={{ borderRadius: '32px' }}>
            <Box p={4} sx={{ backgroundColor: '#F1F1F1', borderRadius: '32px 32px 0 0' }}>
                <Typography fontSize={20} fontWeight={700}>{title}</Typography>
            </Box>
            <Box p={4}>
                <Stack spacing={3}>
                    {children}
                </Stack>
            </Box>
        </Paper>
    )
}

export default SanctionDetailCard