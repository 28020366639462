import { Paper, Stack, Typography } from "@mui/material"


const UpgradeYourAccountCard = () => {
    return (
        <Paper elevation={0} sx={{ height: 350, border: 'none', borderRadius: '32px', backgroundImage: `url(/img/upgrade-your-account-bg.png)` }} variant="outlined" >
            <Stack p={5} sx={{ color: '#ffffff' }}>
                <Typography sx={{ fontSize: '48px', fontWeight: 700 }}>Upgrade Your Account</Typography>
                <Stack direction={'row'} spacing={1} pt={1} sx={{ alignItems: 'baseline' }}>
                    <Typography sx={{ fontSize: '20px' }}>Only For </Typography>
                    <Typography sx={{ fontSize: '32px' }}>USD 999</Typography>
                </Stack>
            </Stack>
        </Paper >
    )
}

export default UpgradeYourAccountCard