import { createSvgIcon } from "@mui/material";

const StackIcon = createSvgIcon(
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7.5C5 6.83696 5.26339 6.20107 5.73223 5.73223C6.20107 5.26339 6.83696 5 7.5 5H22.5C23.163 5 23.7989 5.26339 24.2678 5.73223C24.7366 6.20107 25 6.83696 25 7.5V10C25 10.663 24.7366 11.2989 24.2678 11.7678C23.7989 12.2366 23.163 12.5 22.5 12.5H7.5C6.83696 12.5 6.20107 12.2366 5.73223 11.7678C5.26339 11.2989 5 10.663 5 10V7.5Z" stroke="#333333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 20C5 19.337 5.26339 18.7011 5.73223 18.2322C6.20107 17.7634 6.83696 17.5 7.5 17.5H22.5C23.163 17.5 23.7989 17.7634 24.2678 18.2322C24.7366 18.7011 25 19.337 25 20V22.5C25 23.163 24.7366 23.7989 24.2678 24.2678C23.7989 24.7366 23.163 25 22.5 25H7.5C6.83696 25 6.20107 24.7366 5.73223 24.2678C5.26339 23.7989 5 23.163 5 22.5V20Z" stroke="#333333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    , 'StackIcon'
)

export default StackIcon