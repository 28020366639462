import { ResultPage, TransactionHistory } from "../../../types"
import { Table, TableBody, TableHead } from "@mui/material"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "../_common/styles"

interface Props {
    result: ResultPage<TransactionHistory> | undefined
}

const TransactionResultContent = (props: Props) => {
    const { result } = props

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell width={'5%'} align="center">No</StyledTableCell>
                        <StyledTableCell width={'12%'}>Data ID</StyledTableCell>
                        <StyledTableCell width={'12%'}>Date</StyledTableCell>
                        <StyledTableCell >Name</StyledTableCell>
                        <StyledTableCell width={'10%'}>API Types</StyledTableCell>
                        <StyledTableCell width={'15%'}>Sanction Type</StyledTableCell>
                        <StyledTableCell width={'15%'}>Sanction Hits</StyledTableCell>
                        <StyledTableCell width={'10%'}>Status</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {(result?.results.map((item, index) => (
                        <StyledTableRow>
                            <StyledTableCell align="center">{index + result?.firstElement}</StyledTableCell>
                            <StyledTableCell>{item.dataId}</StyledTableCell>
                            <StyledTableCell>{item.date}</StyledTableCell>
                            <StyledTableCell>{item.name}</StyledTableCell>
                            <StyledTableCell>{item.apiType}</StyledTableCell>
                            <StyledTableCell>{item.sanctionType}</StyledTableCell>
                            <StyledTableCell>{item.sanctionHit ? 'True' : 'False'}</StyledTableCell>
                            <StyledTableCell>{item.status}</StyledTableCell>
                        </StyledTableRow>
                    )))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default TransactionResultContent 