import React from "react";
import { Button, InputBase, OutlinedInput, Paper, Select, darken, styled } from "@mui/material";

export const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 135,
    borderRadius: "32px",
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#D9D9D9',
}))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    flex: 1,
    height: '50px',
    backgroundColor: '#0000',
}))

export const StyledOutlinedInput = styled(OutlinedInput)(({theme}) => ({
    paddingLeft: theme.spacing(3),
    flex: 1,
    height: '50px',
    backgroundColor: '#0000',
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    height: '50px',
    color: '#ffffff',
    backgroundColor: '#0C4992',
    borderRadius: '0  32px 32px 0',
    '&:hover': {
        backgroundColor: darken('#0C4992', 0.1),
    },
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: '132px',
    backgroundColor: '#0000',
    '&.MuiInputBase-root': {
        border: 'none',
        borderRadius: '32px',
    },
    '&.MuiMenu-paper': {
        minWidth: '135px'
    }
}))