import { Box, Grid } from "@mui/material"

const SearchPlaceholder = () => {
    return (
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            paddingY={2}
        >
            <Box
                component="img"
                src="/img/result-placeholder.png"
            />
        </Grid>
    )
}

export default SearchPlaceholder