import { Link, Paper, Typography, darken, styled } from "@mui/material";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";
import CheckFlowerIcon from "../../icons/CheckFlowerIcon";

export const StyledCheckFlowerIcon = styled(CheckFlowerIcon)(({theme}) => ({
    height: 30,
    width: 30,
}))

export const StyledTitle = styled(Typography)(({theme}) => ({
    fontSize: 18,
    fontWeight: 700,
}))

export const StyledText = styled(Typography)(({theme}) => ({
    fontsize: 18,
    fontWeight: 500,
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: 32,
    borderColor: '#F1F1F1'
}))

export const StyledWraningText = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '27px',
}))

export const StyledOkeyButton = styled(StyledSubmitButton)(({ theme }) => ({

}))

export const StyledNoButton = styled(StyledSubmitButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: darken('#FFF', 0.1),
    },
}))