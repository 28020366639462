import { Table, TableBody, TableHead } from "@mui/material"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "../_common/styles"
import { MyEntity, ResultPage } from "../../../types"
import { Link } from "react-router-dom"
import { rowClassOf } from "../_common"

interface Props {
    result: ResultPage<MyEntity> | undefined
}

interface ContentRowProps {
    item: MyEntity
    rowNumber: number
    firstElement: number
}

const ContentRow = (props: ContentRowProps) => {
    const { item, rowNumber, firstElement } = props

    const rowCount = item.aliases.length

    const rowNumberOf = (index: number, number: number) => {
        return (index === 0) ? number + firstElement : ''
    }

    const referenceOf = (index: number, item: MyEntity) => {
        //return (item.aliases.length > index) ? item.aliases[index] : ''
        return (index === 0) ? item.reference : ''
    }

    const nameOf = (index: number, item: MyEntity) => {
        return (index === 0) ? item.name : ''
    }

    const aliasOf = (index: number, item: MyEntity) => {
        return (item.aliases.length > index) ? item.aliases[index] : ''
    }

    const viewOf = (index: number, item: MyEntity) => {
        return (index === 0) ? <Link to={`/sanctions/malaysia/entities/${item.id}`}>View</Link> : ''
    }

    return (
        <>{[...Array(rowCount)].map((_x, i) => (
            <StyledTableRow className={rowClassOf(i, rowCount, rowNumber)}>
                <StyledTableCell align="center">{rowNumberOf(i, rowNumber)}</StyledTableCell>
                <StyledTableCell>{referenceOf(i, item)}</StyledTableCell>
                <StyledTableCell>{nameOf(i, item)}</StyledTableCell>
                {(item.aliases !== null && item.aliases.length > 0) && (
                    <StyledTableCell>{aliasOf(i, item)}</StyledTableCell>
                )}
                <StyledTableCell align="center">{viewOf(i, item)}</StyledTableCell>
            </StyledTableRow>
        ))}
        </>
    )
}

const MyEntityResultContent = (props: Props) => {
    const { result } = props


    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell width={'10%'} align="center">No</StyledTableCell>
                        <StyledTableCell width={'15%'}>Reference</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell width={'30%'}>Alias</StyledTableCell>
                        <StyledTableCell width={'10%'}></StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {(result?.results.map((item, index) => (
                        <ContentRow firstElement={result?.firstElement} item={item} rowNumber={index} />
                    )))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default MyEntityResultContent