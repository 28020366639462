import { Backdrop, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import UserSubmitButton from "../../elements/UserSubmitButton";
import GoogleSignInButton from "../../elements/GoogleSignInButton";
import UserTextInput from "../../elements/UserTextInput";
import { StyledSubmitButton } from "../../elements/UserSubmitButton/styles";
import { userPostCreateUser } from "../../../api/users";
import WaitingProgress from "../../elements/WaitingProgress";
import { postCustomerResiter } from "../../../api/customer";

interface Props {
    email: string
    setEmail: (value: string) => void
    setUserId: (value: string) => void
    open: boolean
    onClose: (success: boolean) => void
}

const SignUpDialog = (props: Props) => {
    const { email, setEmail, setUserId, open, onClose } = props

    const [error, setError] = useState('')
    const [waiting, setWaiting] = useState(false)

    const resetError = () => {
        setError('')
    }

    const delayOnError = (error: string) => {
        setTimeout(() => {
            setWaiting(false)
            setError(error)
        }, 100);
    }

    const handleEmailConfirmClick = () => {
        resetError()
        setWaiting(true)
        if (email !== '') {
            userPostCreateUser(email)
                .then((res) => {
                    console.log(res.data.status)
                    if (res.data.status === 200) {
                        console.log(res.data)
                        console.log('Registerd customer success')
                        //setUserId(res.data.data.uuid)
                        setWaiting(false)
                        onClose(true)
                    }
                })
                .catch((err) => {
                    console.error(err.response.data.message)
                    delayOnError(err.response.data.message)
                })
        } else {
            delayOnError('This field is required.')
        }
    }

    // 19706568-583d-4726-b6d3-1595c9f9aaac

    const handleSetValue = (valuse: string) => {
        resetError()
        setEmail(valuse)
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)}
            PaperProps={{
                sx: {
                    width: '666px',
                    maxWidth: '666px',
                    borderRadius: '32px',
                    boxShadow: '0px 0px 15px 0px #00000040',
                    overflow: 'hidden',
                }
            }}>
            <Box p={5}>
                <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll', boxSizing: 'content-box' }}>
                    <WaitingProgress open={waiting} onClose={() => setWaiting(false)} />
                    <DialogContent sx={{ m: 0, p: 0 }}>
                        <Stack spacing={2}>
                            <Typography fontSize={32} fontWeight={700}>
                                We need your email
                            </Typography>
                            <Typography fontSize={18} fontWeight={500}>
                                To proceed payment, we need to verify your email.
                            </Typography>
                            <Box pb={1}>
                                <UserTextInput
                                    id="user-email-input"
                                    name="email"
                                    placeholder="Email"
                                    error={error}
                                    value={email}
                                    setValue={handleSetValue} />
                            </Box>
                            <StyledSubmitButton variant="contained" color="secondary"
                                disableElevation fullWidth
                                type="submit"
                                onClick={handleEmailConfirmClick}
                            >
                                Confirm
                            </StyledSubmitButton>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography fontWeight={500} color={'#BDBDBD'}>Or</Typography>
                            </Box>
                            <GoogleSignInButton />
                        </Stack>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    )

}

export default SignUpDialog