import { TextField, Typography, styled } from "@mui/material"

export const StyledTextField = styled(TextField)(({ theme }) => ({
    width: "60px",
    paddingTop: theme.spacing(1),
    borderColor: theme.palette.background.paper,
    maxLength: 1,
    ".MuiInputBase-root": {
        borderRadius: '20px',
        backgroundColor: '#ffffff',
    },
    ".MuiInputBase-input": {
        textAlign: 'center',

    }
}))

export const StyledContent = styled(Typography)(() => ({
    fontWeight: 500,
    fontSize: "20px"
}))